import React, {useEffect}  from 'react'
import apiLink from '../../config.js'
import TitreRapport from './titre_rapport'
import GraphRACRapport from './graphique_resteAcharge';
import RobustesseScale from '../OAD/Graphiques/robustess_scale';
import MascotteRouge from '../../assets/Mascotte/Effrayé-YC.png'
import MascotteOrange from '../../assets/Mascotte/Peur-YC.png'
import MascotteJaune from '../../assets/Mascotte/Neutre-YC.png'
import MascotteVertPale from '../../assets/Mascotte/Content-YC.png'
import MascotteVertFlash from '../../assets/Mascotte/Très-Heureux-YC.png'

function PageRistance2({idExploit}) {
  const [PBTot, setPBTot]= React.useState(0);
  const [PertesCourant, setPCO]= React.useState(0);
  const [PertesExceptionnel, setPE]= React.useState(0);
  const [PertesCatastrophe, setPCA]= React.useState(0); 
  const outilsFinanciers = Math.round(PBTot* (0.03 + 0.02 +0.05)/ 1000) * 1000 +1000

  const [Ass20_7, setAss20_7]= React.useState(0);
  const [Ass25_7, setAss25_7]= React.useState(0);
  const [AssFS_7, setAssFS_7]= React.useState(0);

  const [Ass20_20, setAss20_20]= React.useState(0);
  const [Ass25_20, setAss25_20]= React.useState(0);
  const [AssFS_20, setAssFS_20]= React.useState(0);
  const [Ass20_50, setAss20_50]= React.useState(0);
  const [Ass25_50, setAss25_50]= React.useState(0);
  const [AssFS_50, setAssFS_50]= React.useState(0);

  const [dataScale25, seTDataScale25] = React.useState([{value:0.56, name:'Robustesse MRC 25%'}]);
  const [dataScale20, seTDataScale20] = React.useState([{value:0.51, name:'Robustesse MRC 20%'}]);

  useEffect ( () => {
    fetch(apiLink + 'page/exploitation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setPBTot(response.PBTotal)
      setPBTot(prev => {
          return prev;
      })
    }) 

    fetch(apiLink + 'page/resistance/'  + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
      setPCO(response[0].val.indemnisationmoyenne_7)
      setPCO(PBTot => {
          return PBTot;
      })
      setPE(response[0].val.indemnisationmoyenne_20)
      setPE(PBTot => {
          return PBTot;
      })
      setPCA(response[0].val.indemnisationmoyenne_50)
      setPCA(PBTot => {
          return PBTot;
      })
      setAss20_7(response[20].val.indemnisationmoyenne_7)
      setAss20_7(PBTot => {
          return PBTot;
      })
      setAss20_20(response[20].val.indemnisationmoyenne_20)
      setAss20_20(PBTot => {
          return PBTot;
      })
      setAss25_7(response[25].val.indemnisationmoyenne_7)
      setAss25_7(PBTot => {
          return PBTot;
      })
      setAss25_20(response[25].val.indemnisationmoyenne_20)
      setAss25_20(PBTot => {
          return PBTot;
      })
      setAssFS_7(response[50].val.indemnisationmoyenne_7)
      setAssFS_7(PBTot => {
          return PBTot;
      })
      setAssFS_20(response[50].val.indemnisationmoyenne_20)
      setAssFS_20(PBTot => {
          return PBTot;
      })
      setAss20_50(response[20].val.indemnisationmoyenne_50)
      setAss20_50(prev => {
          return prev;
      })
      setAss25_50(response[25].val.indemnisationmoyenne_50)
      setAss25_50(prev => {
          return prev;
      })
      setAssFS_50(response[50].val.indemnisationmoyenne_50)
      setAssFS_50(prev => {
          return prev;
      })
      seTDataScale25(response['robustesse']['25'])
      seTDataScale25(prev => {
          return prev;
      })
      seTDataScale20(response['robustesse']['20'])
      seTDataScale20(prev => {
          return prev;
      })
      
    }) 
  },  [idExploit])

  const maximum = PertesCatastrophe

  const dataCE=[{x:0,y:0}, {x:1,y:Math.round(PBTot*0.03/ 1000) * 1000}, {x:2,y:Math.round(PBTot*0.03/ 1000) * 1000}, {x:3,y:Math.round(PBTot*0.03/ 1000) * 1000}, {x:4,y:Math.round(PBTot*0.03/ 1000) * 1000}, {x:5,y:0}]
  const dataEPri=[{x:0,y:0}, {x:1,y:1000}, {x:2,y:1000}, {x:3,y:1000}, {x:4,y:1000}, {x:5,y:0}]
  const dataEPro=[{x:0,y:0}, {x:1,y:Math.round(PBTot*0.02/ 1000) * 1000}, {x:2,y:Math.round(PBTot*0.02/ 1000) * 1000}, {x:3,y:Math.round(PBTot*0.02/ 1000) * 1000}, {x:4,y:Math.round(PBTot*0.02/ 1000) * 1000}, {x:5,y:0}]
  const dataEC=[{x:0,y:0}, {x:1,y:Math.round(PBTot*0.05/ 1000) * 1000}, {x:2,y:Math.round(PBTot*0.05/ 1000) * 1000}, {x:3,y:Math.round(PBTot*0.05/ 1000) * 1000}, {x:4,y:Math.round(PBTot*0.05/ 1000) * 1000}, {x:5,y:0}]

  const dataPertes_50= [{x:0,y:0}, {x:1,y:0}, {x:2,y:0}, {x:3,y:0}, {x:4,y:0}, {x:5,y:PertesCatastrophe}]
  const dataRC_50=[{x:0,y:0}, {x:1,y:PertesCatastrophe-(4*2000)-Ass20_50}, {x:2,y:PertesCatastrophe-(4*2000)-Ass25_50}, {x:3,y:PertesCatastrophe-(4*2000)-AssFS_50}, {x:4,y:PertesCatastrophe-(4*2000)}, {x:5,y:0}]
  const dataAss_50=[{x:0,y:0}, {x:1,y:Ass20_50}, {x:2,y:Ass25_50}, {x:3,y:AssFS_50}, {x:4,y:0}, {x:5,y:0}]
  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Résistance de votre exploitation'} Page={'6'}/>

      <div className='row_prix_rapport' style={{marginBottom:'-20px', justifyContent:'center',width:'1075px'}}> 
        <div className='graphique_titre_prix_rapport buttonRep buttonRep50Default rep'>Catastrophique </div>
      </div>  
      <div className='row_prix_rapport' style={{marginBottom:'20px',width:'1075px'}}>
        <div className='graph_prix_rapport'>
          <div style={{display:'flex', flexDirection:'column' }}>
            <div className='paragraphe' style={{textAlign:'right'}}>Produit brut</div>
            <div className='rotation'>
              <GraphRACRapport dataPertes={dataPertes_50} dataRC={dataRC_50} dataCE={dataCE} dataEC={dataEC}  dataEPri={dataEPri}  dataEPro={dataEPro}  dataAss={dataAss_50}  PB={PBTot} maximum={maximum} />
              </div>
            <div className='paragraphe' style={{textAlign:'right', marginTop:'-20px'}}>Pertes</div>
          </div>
        </div>
      </div>

      <div  className='tableau_prix_rapport'>
            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi' style={{borderTop:'white', borderLeft:'white'}}> </div>
                <div className='titre_ligne_tableau_resi'> Sans assurance </div>
                <div className='titre_ligne_tableau_resi'> Fond de solidarité </div>
                <div className='titre_ligne_tableau_resi'> MRC franchise 25% </div>
                <div className='titre_ligne_tableau_resi'> MRC franchise 20% </div>
            </div>

            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(255, 57, 57)'}}> Pertes </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCatastrophe).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCatastrophe).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCatastrophe).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCatastrophe).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold', backgroundColor:'rgb(255, 117, 117)'}}> Reste à charge </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCatastrophe-outilsFinanciers).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCatastrophe-outilsFinanciers-AssFS_50).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCatastrophe-outilsFinanciers-Ass25_50).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCatastrophe-outilsFinanciers-Ass20_50).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport multicolor' style={{fontWeight:'bold'}}> Outils financiers </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(177, 225, 124)'}}> Assurance </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > 0 € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(AssFS_50).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass25_50).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass20_50).toLocaleString()} € </div>
            </div>
        </div> 
      {/* <div className='rowResiScalePrincipal'>
          <div className='row rowresiscale RRS25'>
            <img src={dataScale25[0]['value'] < 0.2 ? MascotteRouge : 
              0.2<=dataScale25[0]['value'] && dataScale25[0]['value']<0.4 ? MascotteOrange : 
              0.4<= dataScale25[0]['value'] && dataScale25[0]['value']<0.6 ?  MascotteJaune : 
              0.6 <= dataScale25[0]['value'] && dataScale25[0]['value'] <0.8 ? MascotteVertPale : 
              MascotteVertFlash} 
              alt="" style={{width:'20%'}}/>
            <RobustesseScale myData={dataScale25} />
          </div>
          <div className='row rowresiscale RRS20'>
          <img src={dataScale20[0]['value'] < 0.2 ? MascotteRouge : 
              0.2<=dataScale20[0]['value'] && dataScale20[0]['value']<0.4 ? MascotteOrange : 
              0.4<= dataScale20[0]['value'] && dataScale20[0]['value']<0.6 ?  MascotteJaune : 
              0.6 <= dataScale20[0]['value'] && dataScale20[0]['value'] <0.8 ? MascotteVertPale : 
              MascotteVertFlash} 
              alt="" style={{width:'20%'}}/>
            <RobustesseScale myData={dataScale20} />
          </div>
        </div> */}

      <div className='commentaireRapport'>
        <div style={{fontWeight:'bold', marginBottom:'5px'}}>Commentaire : </div>
        <div>Les outils financiers représentent dans cette simulation un total {outilsFinanciers.toLocaleString()}€. Ce montant correspond à la somme des économies de charge, de l'épargne professionnel, de la capacité d'emprunt et de l'épargne privée défini par défaut pour la simulation. </div>
        <div style={{marginTop:'20px'}}> Vos pertes pour un risque courant s'élèvent à {Math.round(PertesCourant).toLocaleString()}€. Avec les outils financiers mis en place plus une assurance MultiRisques Climatique franchise 25% votre reste à charge s'élève à {Math.round(PertesCourant-outilsFinanciers-Ass25_7).toLocaleString()}€, réciproquement avec une franchise 20%, il sera de {Math.round(PertesCourant-outilsFinanciers-Ass20_7).toLocaleString()}€. </div>
        <div> Vos pertes pour un risque exceptionnel s'élèvent à {Math.round(PertesExceptionnel).toLocaleString()}€. Avec les outils financiers mis en place plus une assurance MultiRisques Climatique franchise 25% votre reste à charge s'élève à {Math.round(PertesExceptionnel-outilsFinanciers-Ass25_20).toLocaleString()}€, réciproquement avec une franchise 20%, il sera de {Math.round(PertesExceptionnel-outilsFinanciers-Ass20_20).toLocaleString()}€. </div>
        <div> Vos pertes pour un risque catastrophique s'élèvent à {Math.round(PertesCatastrophe).toLocaleString()}€. Avec les outils financiers mis en place plus une assurance MultiRisques Climatique franchise 25% votre reste à charge s'élève à {Math.round(PertesCatastrophe-outilsFinanciers-Ass25_50).toLocaleString()}€, réciproquement avec une franchise 20%, il sera de {Math.round(PertesCatastrophe-outilsFinanciers-Ass20_50).toLocaleString()}€. </div>
        <div style={{marginTop:'20px'}}> En cas de reste à charge non nul, vous devez envisager comment y faire face. </div>
      </div>
      
    </div>
  )
}

export default PageRistance2