import React, { useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom'

import apiLink from '../../config.js'
import ButtonQuestions from '../MEP/button question.js';
import Entete from '../MEP/entete'
import Assolement from '../Compte/Assolement/assolement';
import MesSimulations from '../Compte/Simulations/page_simulations';

function LocalisationIns() {
  const [CP, setCP]= React.useState('');
  const [PBCp, setPBCp]= React.useState(false);
  const [city, setCity]= React.useState("");
  const [ListVille, setList]= React.useState();
  const [ShowAssol, setShowAssol] = useState(false);
  const idExploitationPrincipale = JSON.parse(localStorage.getItem('idExploitationPrinicpale'));
  const [goToHome, setGoToHome] = React.useState(false)

  useEffect(() => {
      if (idExploitationPrincipale === undefined) { // test si le compte a été réinitialisé
        setGoToHome(true)
      }
      
    }, [idExploitationPrincipale]);


  function handleSubmit(event) {
    event.preventDefault()
      setShowAssol(true)
  }

  function handleChange(event) {
    var CP = event.target.value
    setCP(CP)   
    fetch(apiLink + 'getCommunes/'+ CP)
      .then( (response) => {
        return response.json()
      })
      .then(response =>{
        setList(response.data)
        setList(prev => {
          prev = prev || []
          if (prev.length === 0 && CP.length>4){
            setPBCp(true)
          }
          else {
            setPBCp(false)
          }
          return prev;
        })
      }) 
  }

 function handleScrollSelect(event){
  var city = event.target.value
  setCity(city)
 }

  if(goToHome) {
    return <Navigate push to={`/`} />
  }
 const contenue = (
  <form onSubmit={handleSubmit}>
    <div className='titre'> Saisissez votre code postal <span style={{color:'red', fontWeight:'normal'}}>{PBCp ? 'Ce code postal est incorrecte' : ''}</span></div>
    <input 
    className='input'
    maxLength="5"
    value={CP}
    onChange={handleChange}
    placeholder='Code Postal'
    type='text'
    required/>
    <div className='titre'> Sélectionnez votre ville </div>
    <select value={city} onChange={handleScrollSelect} required>
      <option value="">Choisissez</option>
      { Array.isArray(ListVille) ? ListVille.map((item)=>( <option key={item.key} value={item.key} > {item.libelle} </option> ) ) : ListVille===undefined ?  <option value={'pas de ville'}>  </option>  : <option value={ListVille.libelle}> {ListVille.libelle} </option> }
    </select>
    <div className='row'>
      <button type='submit' className='validation'> Valider </button>
    </div>
  </form>
 )

  return (
    <div>
      <Entete titre="Localisation"/>
      <div className='container'>
      {idExploitationPrincipale===null ? contenue : <MesSimulations />}
      </div>
      {ShowAssol ? <Assolement city={city}/> : ""}
      <ButtonQuestions/>
    </div>
  );
}

export default LocalisationIns;