import React, {useEffect} from 'react'
import apiLink from '../../config.js'
import TitreRapport from './titre_rapport'
import legend from '../../assets/legendes_cartes_rapport.png'

import CRCata from './Cartes/CR_Cata';
import CRCour from './Cartes/CR_Cour';
import CRExce from './Cartes/CR_Exce';

function PageRiskRDTCarte({idExploit}) {
  const [loadingReg, setLoadingReg] = React.useState(true);
  const [loadingDept, setLoadingDept] = React.useState(true);
  const [loadingRA, setLoadingRA] = React.useState(true);
  const [loadingPRA, setLoadingPRA] = React.useState(true);

  const [dataReg, setDataReg] = React.useState({})
  const [dataDEPT, setDataDEPT] = React.useState({})
  const [dataRA, setDataRA] = React.useState()
  const [dataPRA, setDataPRA] = React.useState()
  
  const [dataGeoExploit, setDataGE] = React.useState(0)

  useEffect ( () => {
    fetch(apiLink + 'getLocalisation/' + idExploit)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
        let idReg = response.idRegion
        fetch(apiLink + 'page/production-rapport/' + idExploit)
          .then( (response) => {
            return response.json()
          })
          .then(response =>{
            let data = response
            var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
              fetch(apiLink + 'getGeoRegion/' + idReg +"/2")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataReg(response.data)
                setDataReg(prev => {
                  setLoadingReg(false);
                  return prev;
                })
              })
            }
            else {
              fetch(apiLink + 'getGeoRegion/' + idReg +"/1")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataReg(response.data)
                setDataReg(prev => {
                  setLoadingReg(false);
                  return prev;
                })
              })
            }
          })
  })

  fetch(apiLink + 'getLocalisation/' + idExploit)
  .then( (response) => {
      return response.json()
  })
  .then(response =>{
      let idDept = response.departement
      fetch(apiLink + 'page/production-rapport/' + idExploit)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          let data = response
          var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
            fetch(apiLink + 'getGeoDepartement/' + idDept +"/2")
            .then( (response) => {
              return response.json()
            })
            .then(response =>{
              setDataDEPT(response.data)
              setDataDEPT(prev => {
                setLoadingDept(false);
                return prev;
              })
            })
          }
          else {
            fetch(apiLink + 'getGeoDepartement/' + idDept +"/1")
            .then( (response) => {
              return response.json()
            })
            .then(response =>{
              setDataDEPT(response.data)
              setDataDEPT(prev => {
                setLoadingDept(false);
                return prev;
              })
            })
          }
        })
  })

  fetch(apiLink + 'getLocalisation/' + idExploit)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
        let idRA = response.idRegionAgricole
        fetch(apiLink + 'page/production-rapport/' + idExploit)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          let data = response
          var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
            fetch(apiLink + 'getGeoRegionAgricole/' + idRA +"/2")
            .then( (response) => {
              return response.json()
            })
            .then(response =>{
              setDataRA(response.data)
              setDataRA(prev => {
                setLoadingRA(false);
                return prev;
              })
            })
          }
          else {
            fetch(apiLink + 'getGeoRegionAgricole/' + idRA +"/1")
            .then( (response) => {
              return response.json()
            })
            .then(response =>{
              setDataRA(response.data)
              setDataRA(prev => {
                setLoadingRA(false);
                return prev;
              })
            })
          }
        })
      })
  
      fetch(apiLink + 'getLocalisation/' + idExploit)
      .then( (response) => {
          return response.json()
      })
      .then(response =>{
          let idPRA = response.idPetiteRegionAgricole
          fetch(apiLink + 'page/production-rapport/' + idExploit)
          .then( (response) => {
            return response.json()
          })
          .then(response =>{
            let data = response
            var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
              fetch(apiLink + 'getGeoPetiteRegionAgricole/' + idPRA +"/2")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataPRA(response.data)
                setDataPRA(prev => {
                  setLoadingPRA(false);
                  return prev;
                })
              })
            }
            else {
              fetch(apiLink + 'getGeoPetiteRegionAgricole/' + idPRA +"/1")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataPRA(response.data)
                setDataPRA(prev => {
                  setLoadingPRA(false);
                  return prev;
                })
              })
            }
          })
        })

        fetch(apiLink + 'getGeoLocaExploitation/' + idExploit )
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataGE(response.data)
          setDataGE(prev => {
            return prev;
          })
        })
  }, [idExploit])
  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Vulnérabilité '} Sous_Titre={'Risques liés au rendement - Cartes'} Page={'3'}/>
      <div className='tableau_prix_rapport tableau_carte_rapport'> 
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_prix_rapport abscisse_tableau_prix_rapport carte_rapport lignetableauprint' style={{backgroundColor:'#FFC000', height:'35px'}}> Courant </div>
          <div className='data_tableau_prix_rapport abscisse_tableau_prix_rapport carte_rapport lignetableauprint' style={{backgroundColor:'#0027CC', height:'35px'}}> Exceptionnelle </div>
          <div className='data_tableau_prix_rapport abscisse_tableau_prix_rapport carte_rapport lignetableauprint'style={{backgroundColor:'#DB04B9', height:'35px'}}> Catastrophique </div>
        </div>

        <div className='row_tableau_prix_rapport'>
          <div className='ordonnée_tableau_prix_rapport carte_rapport_titre lignetableauprint' style={{fontWeight:'bold', height:'35px'}}> Départements </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> <CRCour data={dataDEPT} loading={loadingDept} dataGE={dataGeoExploit}/>  </div>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> <CRExce data={dataDEPT} loading={loadingDept} dataGE={dataGeoExploit}/> </div>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> <CRCata data={dataDEPT} loading={loadingDept} dataGE={dataGeoExploit}/> </div>
        </div>

        <div className='row_tableau_prix_rapport'>
          <div className='ordonnée_tableau_prix_rapport carte_rapport_titre lignetableauprint' style={{fontWeight:'bold', height:'35px'}}> Régions agricoles </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> {!loadingRA ? <CRCour data={dataRA} loading={loadingRA} dataGE={dataGeoExploit}/> :"" } </div>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> {!loadingRA ? <CRExce data={dataRA} loading={loadingRA} dataGE={dataGeoExploit}/> :"" } </div>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> {!loadingRA ? <CRCata data={dataRA} loading={loadingRA} dataGE={dataGeoExploit}/> :"" } </div>
        </div>

        <div className='row_tableau_prix_rapport'>
          <div className='ordonnée_tableau_prix_rapport carte_rapport_titre lignetableauprint' style={{fontWeight:'bold', height:'35px'}}> Petites régions agricoles </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> {!loadingPRA ? <CRCour data={dataPRA} loading={loadingPRA} dataGE={dataGeoExploit}/> :"" } </div>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> {!loadingPRA ? <CRExce data={dataPRA} loading={loadingPRA} dataGE={dataGeoExploit}/> :"" } </div>
          <div className='data_tableau_prix_rapport carte_rapport lignetableauprint'> {!loadingPRA ? <CRCata data={dataPRA} loading={loadingPRA} dataGE={dataGeoExploit}/> :"" } </div>
        </div>
      </div>
      <img src={legend} alt="legend_carte" className="legendCarte_RiskRDT_rapport"/>
      
    </div>
  )
}

export default PageRiskRDTCarte