import React, {useState, useEffect} from 'react'

import ButtonQuestions from '../../MEP/button question.js'
import apiLink from '../../../config.js'
import Entete from '../../MEP/entete'
import Simul from './simulation'
import {Navigate} from 'react-router-dom'
import LocalisationIns from '../../Loc_et_assol/localisation_inscription'

function MesSimulations() {

  const [data, setData] = useState({"3603": {"idExploitation": "3603","nom": "Libelle", "date":{"date":"2023-01-17 16:24:24.000000","timezone_type":3,"timezone":"Europe\/Paris"}, "productions": ["Blé tendre"]}}) //eslint-disable-line
  const [goToComp, setGoToComp] = useState(false)
  const [Sim1, setSim1] = useState()
  const [Sim2, setSim2] = useState()
  const [idExpType, setidExpType] = useState()
  const [ListCheck, setListCheck] = useState("")
  const [goToHome, setGoToHome] = React.useState(false)
  const idExploitationPrincipale = JSON.parse(localStorage.getItem('idExploitationPrinicpale'));
  const idAgent = JSON.parse(localStorage.getItem('idAgent'))
  
  useEffect(() => {
    fetch(apiLink + 'getExploitations/' + idAgent)
        .then((response) => {
            return response.json();
        })
        .then(response => {
            if (Object.keys(response).length > 0) {
                setData(response);
                setData(prev => {
                    var list = {};
                    Object.keys(prev).map(key => (
                        list[key] = "unchecked"
                    ));
                    setListCheck(list);
                    return prev;
                });
            } else {
                localStorage.clear();
                setGoToHome(true);
            }
        });

    fetch(apiLink + 'getLocalisation/' + idExploitationPrincipale)
        .then((response) => {
            return response.json();
        })
        .then(response => {
            let city;
            city = response.idCommune;
            fetch(apiLink + 'page/production-rapport/' + idExploitationPrincipale)
                .then((response) => {
                    return response.json();
                })
                .then(response => {
                    let data = response;
                    var vitiPres = false;
                    for (var cle in data) {
                        if (cle.includes("Vignes")) {
                            vitiPres = true;
                        }
                    }
                    if (vitiPres) {
                        fetch(apiLink + 'getExploitationType/' + city + '/2')
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                setidExpType(response);
                                setidExpType(prev => {
                                    return prev.idExploitation;
                                });
                            });
                    } else {
                        fetch(apiLink + 'getExploitationType/' + city)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                setidExpType(response);
                                setidExpType(prev => {
                                    return prev.idExploitation;
                                });
                            });
                    }
                });
        });
}, [idAgent, idExploitationPrincipale]);


    function simulAcomparer(key,simulationChecked) {
      var newData = {...ListCheck};
      newData[key] = simulationChecked
      setListCheck(newData)
      setListCheck(prev => {
        return prev;
      }) 
    }
  
  const table_simul=  Object.keys(data).map(key => (
    <Simul 
    keys={key}
    data={data}
    idExploit={key}
    libelle={data[key]["nom"]}
    date={new Date(data[key]["date"]["date"]).toLocaleDateString("fr")}
    assol={data[key]["productions"].join(' / ')}
    simulAcomparer={simulAcomparer}
    ListCheck={ListCheck}
    admin={false}
    />
  ))

  function NbChecked(ListCheck){
    var nbsimul = 0
    Object.keys(ListCheck).map(function(key) {
      if(ListCheck[key] === 'checked'){
        nbsimul+=1
      }
      else{
        nbsimul+=0
      }
    })
    return nbsimul
  }
  function WhatChecked(ListCheck, idExpType){
    var Sim1 = 0
    var Sim2 = 0
    var nbsimul = NbChecked(ListCheck)
    if(nbsimul ===1 ||  nbsimul ===2){
      Object.keys(ListCheck).map(function(key) {
        if(ListCheck[key] === 'checked' && Sim1 === 0){
          Sim1 = key
        }
        else if(ListCheck[key] === 'checked' && Sim1 !== 0) {
          Sim2 = key
        }
      } )
    }
    if (Sim2 === 0) {
      Sim2 = idExpType
    }
    var result=[Sim1,Sim2]
    return (result)
  }
  if(goToComp) {
    return <Navigate push to={`/comparaisonsimulation`} state={{Sim1, Sim2}} />
  }
  function Click2(){
    var nbsimul = NbChecked(ListCheck)
    if(nbsimul === 2){
      setSim1(WhatChecked(ListCheck, idExpType)[0])
      setSim2(WhatChecked(ListCheck, idExpType)[1])
      setGoToComp(true)
    }
    else {
      alert("Vous devez sélectionner 2 simulations")
    }
  }
  function ClickExpType(){
    var nbsimul = NbChecked(ListCheck)
    if (idExpType !== undefined) {
      if(nbsimul === 1){
        setSim1(WhatChecked(ListCheck, idExpType)[0])
        setSim2(WhatChecked(ListCheck, idExpType)[1])
        setGoToComp(true)
      }
      else {
        alert("Vous devez sélectionner 1 seule simulation")
      }
    }
    else {
      alert("Vous ne pouvez pas comparer votre exploitation à l'exploitation type car votre région agricole n'en possède pas.")
    }
    
  }

  const button_page_simulation = (
    <div className='row rowCompSimul'> 
      <button onClick={Click2} className='validation validationCompSimul'> Comparer 2 simulations </button>
      <button onClick={ClickExpType} className='validation validationCompSimul'> Comparer à l'exploitation moyenne </button>
    </div>
  )
  if(goToHome) {
    return <Navigate push to={`/`} />
  }

  return (
    <div>
      <Entete titre="Mes simulations"/>
      <div className="container" >
        {idExploitationPrincipale!==null ? button_page_simulation : ""}
        {idExploitationPrincipale!==null ? table_simul : <LocalisationIns />}
      </div>
      <ButtonQuestions/>
    </div>
  )
}

export default MesSimulations