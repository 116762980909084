import React from 'react';
import Nav from '../MEP/SWNav';
import EnteteSW from '../MEP/enteteSW';
import FooterSW from '../MEP/footer_SW';
import dev_front from'../../assets/rejoindre-diagorisk-developpeur-front.jpg'
import dev_back from'../../assets/rejoindre-diagorisk-developpeur-back.jpg'
import Pdf_backend from '../../assets/PDF/2022-11-CDI Developpeur_back-end.pdf'
import Pdf_frontend from '../../assets/PDF/2022-11-CDI Developpeur_front-end.pdf'

function NousRejoindre() {
  return (
    <div>
      <Nav/>
      <EnteteSW titre="Nous rejoindre"/>
      <div className="containerSW" >
        <h1 style={{textAlign:'center'}}>Consultez nos offres d'emploi</h1>
        <div className='principal cardEmplois'> 
          <div className='top ' style={{justifyContent:'flex-start'}}>
            <div className='titreMoinsMoins titrOffreEmplois'> Développeur Back-End CDI</div>
            <div className='paragraphe'style={{fontWeight:'bold'}}> Vous contribuez à : </div>
            <div className='paragraphe'>
              <ul>
                <li> <div className='lila'>Prendre en charge les développements en coordination étroite avec les autres développeurs,</div> </li>
                <li> <div className='lila'>Prendre une part active dans la structuration des process et des réalisations,</div> </li>
                <li> <div className='lila'>Développer de nouvelles API et maintenir celles existantes,</div> </li>
                <li> <div className='lila'>Maintient et mise à jour du moteur de calculs de DiagoRisk suivant les données et formules de calculs fournies,</div> </li>
                <li> <div className='lila'>Gestion de la base de données,</div> </li>
                <li> <div className='lila'>Maintient des mises à jours et gestion de l’hébergement des plateformes.</div> </li>
              </ul>  
            </div>
            <div className='paragraphe'style={{fontWeight:'bold'}}> Compétences et aptitudes recherchées : </div>
            <div className='paragraphe'>
              <ul>
                <li> <div className='lila'>Ingénieur ou Master, spécialisation informatique ou expérience équivalente</div> </li>
                <li> <div className='lila'>Manipulation de base de données importante et de fonctionnalités de calcul,</div> </li>
                <li> <div className='lila'>Curiosité et capacité de travail en équipe,</div> </li>
                <li> <div className='lila'>Souhait de s’engager dans une start-up engagée sur les enjeux climatiques.</div> </li>
              </ul>  
            </div>
            <div className='paragraphe'style={{fontWeight:'bold'}}> Les plus appréciés sans être indispensables : </div>
            <div className='paragraphe'>
              <ul>
                <li> <div className='lila'>Connaissances du monde agricole,</div> </li>
                <li> <div className='lila'>Notions de formules d’assurances.</div> </li>
              </ul>  
            </div>
            <div className='row'>
              <a href = {Pdf_backend} className='paragraphe' style={{color:'#92D050', fontWeight:'bold'}}>Consulter l'offre</a>
            </div>
          </div>
          <div className='top ' style={{justifyContent:'flex-start'}}>
            <img src={dev_back} alt="" className="imageCardEmplois"/>
          </div>
        </div>
        <div className='principal cardEmplois'> 
          <div className='top ' style={{justifyContent:'flex-start'}}>
            <img src={dev_front} alt="" className="imageCardEmplois"/>
          </div>
          <div className='top ' style={{justifyContent:'flex-start'}}>
            <div className='titreMoinsMoins titrOffreEmplois'> Développeur Front-End CDI/Freelance</div>
            <div className='paragraphe'style={{fontWeight:'bold'}}> Vous contribuez à : </div>
            <div className='paragraphe'>
              <ul>
                <li> <div className='lila'>Travailler en étroite collaboration avec un développeur back-end qui produisent les API afin de développer les interfaces utilisateurs, </div> </li>
                <li> <div className='lila'>Accompagner les équipes back-end sur les évolutions d’API nécessaires, </div> </li>
                <li> <div className='lila'>Être force de proposition dans l’élaboration de nouvelles fonctionnalités.</div> </li>

              </ul>  
            </div>
            <div className='paragraphe'style={{fontWeight:'bold'}}> Compétences et aptitudes recherchées : </div>
            <div className='paragraphe'>
              <ul>
                <li> <div className='lila'>Ingénieur ou Master, spécialisation informatique ou expérience équivalente,</div> </li>
                <li> <div className='lila'>Affinité pour UX/UI,</div> </li>
                <li> <div className='lila'>Curiosité et capacité de travail en équipe,</div> </li>
                <li> <div className='lila'>Souhait de s’engager dans une start-up engagée sur les enjeux climatiques.</div> </li>
              </ul>  
            </div>
            <div className='paragraphe'style={{fontWeight:'bold'}}> Les plus appréciés sans être indispensables : </div>
            <div className='paragraphe'>
              <ul>
                <li> <div className='lila'>Connaissances du monde agricole,</div> </li>
                <li> <div className='lila'>Notions de formules d’assurances.</div> </li>
              </ul>  
            </div>
            <div className='row'>
              <a href = {Pdf_frontend} className='paragraphe' style={{color:'#92D050', fontWeight:'bold'}}>Consulter l'offre</a>
            </div>
          </div>
        </div>
      </div>
    <FooterSW/>
    </div>
  );
}

export default NousRejoindre;

