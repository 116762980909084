import React, {useEffect}  from 'react'
import apiLink from '../../config.js'
import TitreRapport from './titre_rapport'
import GraphRACRapport from './graphique_resteAcharge';

function PageResistance1({idExploit}) {
  const [PBTot, setPBTot]= React.useState(0);
  const [PertesCourant, setPCO]= React.useState(0);
  const [PertesExceptionnel, setPE]= React.useState(0);
  const [PertesCatastrophe, setPCA]= React.useState(0); 
  const outilsFinanciers = Math.round(PBTot* (0.03 + 0.02 +0.05)/ 1000) * 1000 + 1000

  const [Ass20_7, setAss20_7]= React.useState(0);
  const [Ass25_7, setAss25_7]= React.useState(0);
  const [AssFS_7, setAssFS_7]= React.useState(0);

  const [Ass20_20, setAss20_20]= React.useState(0);
  const [Ass25_20, setAss25_20]= React.useState(0);
  const [AssFS_20, setAssFS_20]= React.useState(0);


  useEffect ( () => {
    fetch(apiLink + 'page/exploitation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setPBTot(response.PBTotal)
      setPBTot(PBTot => {
          return PBTot;
      })
    }) 

    fetch(apiLink + 'page/resistance/'  + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
      setPCO(response[0].val.indemnisationmoyenne_7)
      setPCO(PBTot => {
          return PBTot;
      })
      setPE(response[0].val.indemnisationmoyenne_20)
      setPE(PBTot => {
          return PBTot;
      })
      setPCA(response[0].val.indemnisationmoyenne_50)
      setPCA(PBTot => {
          return PBTot;
      })
      setAss20_7(response[20].val.indemnisationmoyenne_7)
      setAss20_7(PBTot => {
          return PBTot;
      })
      setAss20_20(response[20].val.indemnisationmoyenne_20)
      setAss20_20(PBTot => {
          return PBTot;
      })
      setAss25_7(response[25].val.indemnisationmoyenne_7)
      setAss25_7(PBTot => {
          return PBTot;
      })
      setAss25_20(response[25].val.indemnisationmoyenne_20)
      setAss25_20(PBTot => {
          return PBTot;
      })
      setAssFS_7(response[50].val.indemnisationmoyenne_7)
      setAssFS_7(PBTot => {
          return PBTot;
      })
      setAssFS_20(response[50].val.indemnisationmoyenne_20)
      setAssFS_20(PBTot => {
          return PBTot;
      })
    }) 
  },  [idExploit])

  const maximum = PertesCatastrophe

  const dataCE=[{x:0,y:0}, {x:1,y:Math.round(PBTot*0.03/ 1000) * 1000}, {x:2,y:Math.round(PBTot*0.03/ 1000) * 1000}, {x:3,y:Math.round(PBTot*0.03/ 1000) * 1000}, {x:4,y:Math.round(PBTot*0.03/ 1000) * 1000}, {x:5,y:0}]
  const dataEPri=[{x:0,y:0}, {x:1,y:1000}, {x:2,y:1000}, {x:3,y:1000}, {x:4,y:1000}, {x:5,y:0}]
  const dataEPro=[{x:0,y:0}, {x:1,y:Math.round(PBTot*0.02/ 1000) * 1000}, {x:2,y:Math.round(PBTot*0.02/ 1000) * 1000}, {x:3,y:Math.round(PBTot*0.02/ 1000) * 1000}, {x:4,y:Math.round(PBTot*0.02/ 1000) * 1000}, {x:5,y:0}]
  const dataEC=[{x:0,y:0}, {x:1,y:Math.round(PBTot*0.05/ 1000) * 1000}, {x:2,y:Math.round(PBTot*0.05/ 1000) * 1000}, {x:3,y:Math.round(PBTot*0.05/ 1000) * 1000}, {x:4,y:Math.round(PBTot*0.05/ 1000) * 1000}, {x:5,y:0}]

  const dataPertes_7= [{x:0,y:0}, {x:1,y:0}, {x:2,y:0}, {x:3,y:0}, {x:4,y:0}, {x:5,y:PertesCourant}]
  const dataRC_7=[{x:0,y:0}, {x:1,y:PertesCourant-(4*2000)-Ass20_7}, {x:2,y:PertesCourant-(4*2000)-Ass25_7}, {x:3,y:PertesCourant-(4*2000)-AssFS_7}, {x:4,y:PertesCourant-(4*2000)}, {x:5,y:0}]
  const dataAss_7=[{x:0,y:0}, {x:1,y:Ass20_7}, {x:2,y:Ass25_7}, {x:3,y:AssFS_7}, {x:4,y:0}, {x:5,y:0}]

  const dataPertes_20= [{x:0,y:0}, {x:1,y:0}, {x:2,y:0}, {x:3,y:0}, {x:4,y:0}, {x:5,y:PertesExceptionnel}]
  const dataRC_20=[{x:0,y:0}, {x:1,y:PertesExceptionnel-(4*2000)-Ass20_20}, {x:2,y:PertesExceptionnel-(4*2000)-Ass25_20}, {x:3,y:PertesExceptionnel-(4*2000)-AssFS_20}, {x:4,y:PertesExceptionnel-(4*2000)}, {x:5,y:0}]
  const dataAss_20=[{x:0,y:0}, {x:1,y:Ass20_20}, {x:2,y:Ass25_20}, {x:3,y:AssFS_20}, {x:4,y:0}, {x:5,y:0}]

  

  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Résistance de votre exploitation'} Page={'5'}/>
      <div style={{fontSize:"20px", marginBottom:'20px'}}> Evaluation de votre reste à charge en fonction des différents types d’assurances les plus utilisées et du niveau de risques. Les outils financiers regroupent : économie de charges, épargne professionnelle, capacité d'emprunt, épargne privée. Ici les outils financiers sont fixésà 2000€ chacun.</div>

      <div className='row_prix_rapport' style={{marginBottom:'-20px', justifyContent:'center',width:'1075px'}}> 
        <div className='graphique_titre_prix_rapport buttonRep buttonRep7Default rep'>Courant </div>
      </div>  
      <div className='row_prix_rapport' style={{marginBottom:'20px',width:'1075px'}}>
        <div className='graph_prix_rapport'>
          <div style={{display:'flex', flexDirection:'column' }}>
            <div className='paragraphe' style={{textAlign:'right'}}>Produit brut</div>
            <div className='rotation'>
              <GraphRACRapport dataPertes={dataPertes_7} dataRC={dataRC_7} dataCE={dataCE} dataEC={dataEC}  dataEPri={dataEPri}  dataEPro={dataEPro}  dataAss={dataAss_7}  PB={PBTot} maximum={maximum} />
            </div>
            <div className='paragraphe' style={{textAlign:'right', marginTop:'-20px'}}>Pertes</div>
          </div>
        </div>
      </div>

      <div  className='tableau_prix_rapport'>
            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi' style={{borderTop:'white', borderLeft:'white'}}> </div>
            <div className='titre_ligne_tableau_resi'> Sans assurance </div>
            <div className='titre_ligne_tableau_resi'> Fond de solidarité </div>
            <div className='titre_ligne_tableau_resi'> MRC franchise 25% </div>
            <div className='titre_ligne_tableau_resi'> MRC franchise 20% </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(255, 57, 57)'}}> Pertes </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCourant).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCourant).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCourant).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCourant).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold', backgroundColor:'rgb(255, 117, 117)'}}> Reste à charge </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCourant-outilsFinanciers).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCourant-outilsFinanciers-AssFS_7).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCourant-outilsFinanciers-Ass25_7).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCourant-outilsFinanciers-Ass20_7).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport multicolor' style={{fontWeight:'bold'}}> Outils financiers </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(177, 225, 124)'}}> Assurance </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > 0 € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(AssFS_7).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass25_7).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass20_7).toLocaleString()} € </div>
            </div>
        </div> 

      <div className='row_prix_rapport' style={{marginBottom:'-20px', justifyContent:'center',width:'1075px'}}> 
        <div className='graphique_titre_prix_rapport buttonRep buttonRep20Default rep'>Exceptionnel </div>
      </div>  
      <div className='row_prix_rapport' style={{marginBottom:'20px',width:'1075px'}}>
        <div className='graph_prix_rapport'>
          <div style={{display:'flex', flexDirection:'column' }}>
            <div className='paragraphe' style={{textAlign:'right'}}>Produit brut</div>
            <div className='rotation'>
              <GraphRACRapport dataPertes={dataPertes_20} dataRC={dataRC_20} dataCE={dataCE} dataEC={dataEC}  dataEPri={dataEPri}  dataEPro={dataEPro}  dataAss={dataAss_20}  PB={PBTot} maximum={maximum} />
              </div>
            <div className='paragraphe' style={{textAlign:'right', marginTop:'-20px'}}>Pertes</div>
          </div>
        </div>
      </div>

      <div  className='tableau_prix_rapport'>
            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi' style={{borderTop:'white', borderLeft:'white'}}> </div>
            <div className='titre_ligne_tableau_resi'> Sans assurance </div>
            <div className='titre_ligne_tableau_resi'> Fond de solidarité </div>
            <div className='titre_ligne_tableau_resi'> MRC franchise 25% </div>
            <div className='titre_ligne_tableau_resi'> MRC franchise 20% </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(255, 57, 57)'}}> Pertes </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesExceptionnel).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesExceptionnel).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesExceptionnel).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesExceptionnel).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold', backgroundColor:'rgb(255, 117, 117)'}}> Reste à charge </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesExceptionnel-outilsFinanciers).toLocaleString()}€ </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesExceptionnel-outilsFinanciers-AssFS_20).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesExceptionnel-outilsFinanciers-Ass25_20).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesExceptionnel-outilsFinanciers-Ass20_20).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport multicolor' style={{fontWeight:'bold'}}> Outils financiers </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(177, 225, 124)'}}> Assurance </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > 0 € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(AssFS_20).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass25_20).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass20_20).toLocaleString()} € </div>
            </div>
        </div> 

    </div>
  )
}

export default PageResistance1