import React from 'react'
import apiLink from '../../../config.js'
import lienPaiement from '../../../config_payer.js'
import axios from "axios";
import {Navigate} from 'react-router-dom'
import Pdf_CGU from '../../../assets/PDF/2023-07-10_CGU.pdf'
import Pdf_CGV from '../../../assets/PDF/2023-07-10_CGV.pdf'

function Consentement(props) {
    const [goToVal, setgoToVal]= React.useState(false);
    const mesDonnees = new FormData(); 
    const consent1 = "Les simulations sont basées sur un risque passé et connu et qu’il peut y avoir des écarts par rapport au risque futur."
    const consent2 = "Les informations stockées sont utilisées pour permettre de réaliser ces simulations, nous stockons donc :"
    const consent3 = "Les informations renseignées lors de votre inscriptions (nom, prénom, société, numéro de téléphone, email)"
    const consent4 = "Votre localisation (code postal + commune)"
    const consent5 = "Vos assolements"
    const consent6 = "L'utilisation a lu et accepté les conditions générales de vente et les conditions générales d'utilisation"
    const cgv = "J'ai lu et j'accepte les conditions générales de vente"
    const cgu = "J'ai lu et j'accepte les conditions générales d'utilisation"
    const constentement = consent1 + ' '+ consent2 + ' '+ consent3 + ' / '+ consent4 + ' / '+ consent5 + '/' + consent6 + '/' + cgu + '/' + cgv

    function handleSubmit(event) {
        event.preventDefault()
        if (document.getElementById("consentementCGV").checked===true && document.getElementById("consentementCGU").checked===true) {
            mesDonnees.set("nom", props.Nom);
            mesDonnees.set("prenom", props.Prenom);
            mesDonnees.set("societe", props.Societe);
            mesDonnees.set("telephone", props.Tel);
            mesDonnees.set("email", props.Email);
            mesDonnees.set("password", props.MDP);
            mesDonnees.set("codeActivation", props.CA);
            mesDonnees.set("consentement", constentement);
            mesDonnees.set("offre", props.offre);

            axios({
                method: 'post',
                url: apiLink + 'inscription',
                data: mesDonnees
            })
            .then(function (reponse) {
                if(reponse.status === 200){
                    if(props.admin) {
                        if (reponse.data.status === 0){
                            alert('Cet email est déjà utilisé pour un compte DiagoRisk')
                        } else {
                            setgoToVal(true)
                        }
                    }
                    else {
                        if (reponse.data.status === 0){
                            alert('Cet email est déjà utilisé pour un compte DiagoRisk')
                        } else {
                            const idAgent = reponse.data.idAgent
                            alert('Vous allez être redirigé vers la page de paiement DiagoRisk')
                            const urlExterne = lienPaiement + idAgent + "/" + props.offre;
                            window.location.href = urlExterne
                        }
                    }
                    
                }
                else {
                    alert("Une erreur c'est produite, votre compte n'as pas pu être créé")
                }
            })
            .catch(function (erreur) {
                //On traite ici les erreurs éventuellement survenues
                console.log(erreur);
            });
        }
        else {
            alert("Veuillez accepter les conditions d'utilisations de DiagoRisk")
        }
    }
    
    if(goToVal) {
        return <Navigate push to={`/inscriptionvalidation`} /> 
    }
    return ( props.trigger) ? (
        <div className='popupLegend popupCons'>
            <div className='popup-inner-MDP'style={{padding:'50px'}}>
                <button className='close-btn close-btn-profil' onClick={() => props.setTrigger(false)}>X</button>
                <div className='paragraphe'> {consent1}  </div>
                <div className='paragraphe'>
                    {consent2}
                    <ul>
                        <li> <div className='lila'> {consent3} </div></li>
                        <li> <div className='lila'> {consent4} </div></li>
                        <li> <div className='lila'> {consent5} </div></li>
                    </ul>
                </div>

                Vous devez les lire les <a href = {Pdf_CGU} className='paragraphe' style={{color:'#92D050', fontWeight:'bold'}}target='_blank'>conditions génrales d'utilisation</a> et les <a href = {Pdf_CGV} className='paragraphe' style={{color:'#92D050', fontWeight:'bold'}}target='_blank'>conditions générales de vente</a>

                <form onSubmit={handleSubmit} name="consentement">
                    <div style={{display:'flex'}}>
                        <input type="checkbox" id="consentementCGV" value="ok" className='checkInscription' style={{marginBottom:'0px'}} required />
                        <div className='textInscription' style={{marginTop:'10px'}}> {cgv} </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <input type="checkbox" id="consentementCGU" value="ok" className='checkInscription' style={{marginBottom:'0px'}} required />
                        <div className='textInscription' style={{marginTop:'10px'}}> {cgu} </div>
                    </div>
                    <div className='row'>
                        <button className='validation' > {props.admin? "Valider et créer mon compte" : "Valider et payer" }</button>
                    </div>
                </form>
                
                {props.children}
            </div>
    
        </div>
      ) : "" ;
}

export default Consentement