import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'


import Accueil from './components/SiteWeb/accueil'
import NotFound from './notfound';

import Abonnement from './components/SiteWeb/abonnements'
import NousRejoindre from './components/SiteWeb/nousRejoindre'
import QuiSommesNous from './components/SiteWeb/quiSommesNous'
import Solutions from './components/SiteWeb/solutions'
import Blog from './components/SiteWeb/blog';
import MentionsLegales from './components/SiteWeb/mentionslégales';
import ProtectionsDesDonnes from './components/SiteWeb/protectionsdesdonnées';
import RecapPrePaiement from './components/SiteWeb/recapitulatifPrePaiement';
import NousContacter from './components/SiteWeb/NousContacter';

import LocalisationDec from './components/Loc_et_assol/localisation_decouverte'
import LocalisationIns from './components/Loc_et_assol/localisation_inscription'

import Exploitation from './components/OAD/exploitation'
import RisquesRdt from './components/OAD/risquesRDT'
import RisquesPrix from './components/OAD/risquesPRIX'
import Resistance from './components/OAD/resistance'
import Optimisation from './components/OAD/optimisation'
import Optimisation2 from './components/OAD/optimisation2';
import OptimisationAssolement from './components/OAD/optimisation_assol';

import Connexion from './components/Compte/Gestion_Compte/connexion';
import Inscription from './components/Compte/Gestion_Compte/inscription';
import InscriptionValidation from './components/Compte/Gestion_Compte/inscription_validation';
import Questions from './components/Compte/questions';
import ManuelDecouverte from './components/Compte/manuelDecouv';
import Profil from './components/Compte/Gestion_Compte/profil';
import PageParams from './components/OAD2050/pageParams';
import Resultats2050Assolement from './components/OAD2050/resultats2050Assolement';
import Resultats2050Culture from './components/OAD2050/resultats2050Culture';


import AdminGestionUtilisateur from './components/Compte/Gestion_Compte/Administrateur/gestion_utilisateur';
import SimuationsAgent from './components/Compte/Gestion_Compte/Administrateur/page_simulations_agent';
import MDPAgent from './components/Compte/Gestion_Compte/Administrateur/PageMDPAgent';
import ModifierAgent from './components/Compte/Gestion_Compte/Administrateur/PageModifierAgent';

import MesSimulations from './components/Compte/Simulations/page_simulations';
import CompSimul from './components/Compte/Simulations/comparaisonSimulations';
import RapportPDF from './components/PDF/0_rapport_pdf';

import NewSimulation from './components/Compte/Assolement/new_simul';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='*' element={<NotFound/>} /> 
      <Route exact path='/' element={<Accueil/>} /> 
       
      <Route exact path='/exploitation' element={<Exploitation/>} /> 
      <Route exact path='/risquesrendement' element={<RisquesRdt/>} /> 
      <Route exact path='/risquesprix' element={<RisquesPrix/>} /> 
      <Route exact path='/resistance' element={<Resistance/>} /> 
      <Route exact path='/optimisation' element={<Optimisation/>} /> 
      <Route exact path='/optimisationfinanciere' element={<Optimisation2/>} /> 
      <Route exact path='/compassolement' element={<OptimisationAssolement/>} /> 

      <Route exact path='/ma_localisation' element={<LocalisationDec />} />
      <Route exact path='/localisation' element={<LocalisationIns />} />

      <Route exact path='/connexion' element={<Connexion/>} /> 
      <Route exact path='/inscription' element={<Inscription/>} /> 
      <Route exact path='/inscriptionvalidation' element={<InscriptionValidation/>} /> 
      <Route exact path='/manueldecouverte' element={<ManuelDecouverte/>} /> 
      <Route exact path='/questions' element={<Questions/>} /> 
      <Route exact path='/monprofil' element={<Profil/>} /> 
      {/* <Route exact path='/params2050' element={<PageParams/>} /> 
      <Route exact path='/resultats2050assolement' element={<Resultats2050Assolement/>} /> 
      <Route exact path='/resultats2050culture' element={<Resultats2050Culture/>} />  */}

      {/* <Route exact path='/gestionutilisateur' element={<AdminGestionUtilisateur/>} />  */}
      <Route exact path='/agentsimulation' element={<SimuationsAgent/>} /> 
      <Route exact path='/mdpagent' element={<MDPAgent/>} /> 
      <Route exact path='/modifieragent' element={<ModifierAgent/>} /> 
      
      <Route exact path='/previsualisationrapport' element={<RapportPDF/>} />
      <Route exact path='/simulations' element={<MesSimulations/>} /> 
      <Route exact path='/nouvellesimulation' element={<NewSimulation/>} /> 
      <Route exact path='/comparaisonsimulation' element={<CompSimul/>} /> 

      <Route exact path='/nossolutions' element={<Solutions/>} /> 
      <Route exact path='/abonnements' element={<Abonnement/>} /> 
      <Route exact path='/recapitulatif-pre-paiement' element={<RecapPrePaiement/>} /> 
      <Route exact path='/quisommesnous' element={<QuiSommesNous/>} /> 
      <Route exact path='/nousrejoindre' element={<NousRejoindre/>} /> 
      <Route exact path='/nouscontacter' element={<NousContacter/>} /> 
      <Route exact path='/blog' element={<Blog/>} /> 
      <Route exact path='/mentionslegales' element={<MentionsLegales/>} /> 
      <Route exact path='/protectiondesdonnees' element={<ProtectionsDesDonnes/>} /> 
    </Routes>
  </BrowserRouter>
  );
}

export default App;
