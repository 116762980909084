import React from "react";
import Modifier from "../../../../../assets/Gestion_utilisateur_modifier.png"

class BtnModifier extends React.Component {
    constructor(props) {
      super(props);
      this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
     this.props.clicked(this.props.value);
    }
    render() {
      return (
        <button className='ButtonGestionUtilisateur BGUGrey' onClick={this.btnClickedHandler}> <img src={Modifier} className="IconHome" alt='' style={{marginLeft:'-10px'}}/> </button>
      )
    }
  }
export default BtnModifier