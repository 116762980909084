import React, {useState} from 'react'
import {Navigate} from 'react-router-dom'
import expl from '../../assets/navig_exploit_ac.png'
import rdt from '../../assets/navig_riskRDT_ac.png'
import prix from '../../assets/navig_riskPRIX_ac.png'
import resis from '../../assets/navig_robustesse_ac.png'
import opti from '../../assets/navig_opti_ac.png'
import optiAss from '../../assets/navig_assol_ac.png'
import explDefaut from '../../assets/navig_exploit_inac.png'
import rdtDefaut from '../../assets/navig_riskRDT_inac.png'
import prixDefaut from '../../assets/navig_riskPRIX_inac.png'
import resisDefaut from '../../assets/navig_robustesse_inac.png'
import optiDefaut from '../../assets/navig_opti_inac.png'
import optiAssDefaut from '../../assets/navig_assol_inac.png'

function  OADMenu ({idExploit}) {
    const role = JSON.parse(localStorage.getItem('role')); 
    const formule = JSON.parse(localStorage.getItem('formule')); 
    
    const [goToExploit, setGoToExploit] = useState(false)
    const [goToRdt, setGoToRdt] = useState(false)
    const [goToPrix, setGoToPrix] = useState(false)
    const [goToResis, setGoToResis] = useState(false)
    const [goToOpti, setGoToOpti] = useState(false)
    const [goToOptiAss, setGoToOptiAss] = useState(false)

    const url = window.location.href

    function handleClickE() {
        setGoToExploit(true)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(false)
    }

    function handleClickRen() {
        setGoToExploit(false)
        setGoToRdt(true)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(false)
    }

    function handleClickP() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(true)
        setGoToResis(false)
        setGoToOpti(false)
    }

    function handleClickRes() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(true)
        setGoToOpti(false)
        setGoToOptiAss(false)
    }

    function handleClickO() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(true)
        setGoToOptiAss(false)
    }
    function handleClickOA() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(true)
    }

    if(goToExploit) {
        return <Navigate push to={'/exploitation'} state={{idExploit}} />
    }

    if(goToRdt) {
        return <Navigate push to={'/risquesrendement'} state={{idExploit}}/>
    }

    if(goToPrix) {
        return <Navigate push to={'/risquesprix'} state={{idExploit}}/>
    }

    if(goToResis) {
        return <Navigate push to={'/resistance'} state={{idExploit}}/>
    }

    if(goToOpti) {
        return <Navigate push to={'/optimisationfinanciere'} state={{idExploit}}/>
    }
    if(goToOptiAss) {
        return <Navigate push to={'/compassolement'} state={{idExploit}}/>
    }


    return (
        <div className={role==="ROLE_ADMINISTRATEUR" ?'row menuOad backgroundAdmin' : formule==="Flash"  ? 'row menuOad backgroundFlash' :'row menuOad backgroundExploitant'}>
            <button className=' ButtonMenuOAD' disabled={url.indexOf("exploitation") > -1 ? true : false} onClick={handleClickE}> 
                <img src={url.indexOf("exploitation") > -1 ? expl : explDefaut} alt='' className='IconMenuOAD'/> 
                <div className={url.indexOf("exploitation") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}> Exploitation </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("risquesrendement") > -1 ? true : false} onClick={handleClickRen}> 
                <img src={url.indexOf("risquesrendement") > -1 ? rdt : rdtDefaut} alt='' className='IconMenuOAD'/> 
                <div className={url.indexOf("risquesrendement") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD  nomMenuOADDefault'}>  Rendement </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("resistance") > -1 ? true : false} onClick={handleClickRes}> 
                <img src={url.indexOf("resistance") > -1 ?  resis : resisDefaut} alt='' className='IconMenuOAD'/> 
                <div className={url.indexOf("resistance") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD  nomMenuOADDefault'}>  Resistance </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("optimisation") > -1 ? true : false} onClick={handleClickO}> 
                <img src={url.indexOf("optimisation") > -1 ? opti : optiDefaut} alt='' className='IconMenuOAD '/> 
                <div className={url.indexOf("optimisation") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}>  Optimisation </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("compassolement") > -1 ? true : false} onClick={handleClickOA}> 
                <img src={url.indexOf("compassolement") > -1 ? optiAss : optiAssDefaut} alt='' className='IconMenuOAD'/> 
                <div className={url.indexOf("compassolement") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}>  Assolement </div>
            </button>
            {/* <button className='ButtonMenuOAD' disabled={url.indexOf("risquesprix") > -1 ? true : false} onClick={handleClickP}> 
                <img src={url.indexOf("risquesprix") > -1 ? prix :prixDefaut} alt='' className='IconMenuOAD'/> 
                <div className={url.indexOf("risquesprix") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD  nomMenuOADDefault'}>  Prix </div>
            </button> */}
        </div>
    )
    
  }
  
  
  export default OADMenu;