import React, {useEffect, useState} from 'react';
import apiLink from '../../../../config.js'
import {useLocation, Navigate} from 'react-router-dom'
import axios from "axios";

import oeilShow from '../../../../assets/oeilShow.png'
import oeilHide from '../../../../assets/oeilHide.png'
import Entete from '../../../MEP/entete';

function MDPAgent({}) {
  const { state } = useLocation();
  const { idAgentAModifier } = state;
  const [MDP, setMDP]= React.useState('');
  const [MDP2, setMDP2]= React.useState('');
  const [Show, setShow]= React.useState(false)
  const [goToUtilisateur, setGoToUtilisateur] = React.useState(false)
  const mesDonnees = new FormData(); 

  function handleChangeMDP(event) {
    var MDP = event.target.value
    setMDP(MDP)   
  }
  function handleChangeMDP2(event) {
      var MDP = event.target.value
      setMDP2(MDP)   
  }
  function ShowHide () {
      setShow(!Show)
  }

  function handleSubmit(event) {
    if(MDP===MDP2  && (MDP!=="" || MDP2!=="")) {
      event.preventDefault()
      mesDonnees.set("password", MDP);

      axios({
          method: 'post',
          url: apiLink + 'updatePassword/' + idAgentAModifier, 
          data: mesDonnees
      })
      .then(function (reponse) {
          if(reponse.status === 200){
          }
          else {
          alert("Une erreur c'est produite, votre mot de passe n'as pas pu être changé")
          }
      })
      .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
      });
    }
    else {
      alert("Les deux mots de passe sont différents")
    }
    setGoToUtilisateur(true)
  }
  if(goToUtilisateur){
    return <Navigate push to={`/gestionutilisateur`} />
  }
  return (
    <div>
      <Entete titre="Modifier le mot de passe"/>
      <div className='container'> 
        <form onSubmit={handleSubmit} name="MAJ_MDP" style={{width:'100%'}}>
          <div className='titreMoins' > Nouveau mot de passe : </div>
          <div className='PasseWord'>
              <input 
              className='input'
              value={MDP}
              onChange={handleChangeMDP}
              placeholder='Mot de passe'
              type={Show? 'text' : 'password'}
              style={{marginBottom:'10px', }}
              required/>
              <button className='ButtonShowHide ButtonShowHide3' onClick={ShowHide}> <img src={Show? oeilShow : oeilHide} className="IconHome" alt=''/> </button>
          </div>
          <div className='titreMoins' > Nouveau mot de passe (validation) : </div>
          <div className='PasseWord'>
              <input 
              className='input'
              value={MDP2}
              onChange={handleChangeMDP2}
              placeholder='Mot de passe'
              type={Show? 'text' : 'password'}
              style={{marginBottom:'10px', }}
              required/>
              
          </div>
          <div className='textInscription' style={{color:MDP===MDP2  && (MDP!=="" || MDP2!=="")? 'green' :'red'}}>{MDP===MDP2 && (MDP!=="" || MDP2!=="")? 'Mot de passe confirmé' : 'Mot de passe incorrect et/ou différent'}</div>
          <div className='row'>
              <button type='submit' className='validation'> Enregistrer </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MDPAgent