import React from 'react';
import {TileLayer, GeoJSON, MapContainer, Circle } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

function CarteRisquesREGCour({data, loading, dataGE}) {
  const bbox = require('geojson-bbox');
  const extent = bbox(data); 

  function getColor(d) {
      return 	d > 65 ? '#A00003' :
              d > 62 ? '#B72C1A' :
              d > 59 ? '#CF5732' :
              d > 56 ? '#E38249' :
              d > 53 ? '#FDAE61' :
              d > 50 ? '#FEC279' :
              d > 47 ? '#FED791' :
              d > 44 ? '#FDEAA7' :
              d > 41 ? '#FFFFC0' :
              d > 38 ? '#E7F4A9' :
              d > 35 ? '#D3EC95' :
              d > 32 ? '#BCE380' :
              d > 29 ? '#A6D96A' :
              d > 26 ? '#7DB956' :
              d > 23 ? '#539942' :
              d > 20 ? '#2A782E' :
              d > 0 ? '#004E17' :
              '#ffffff'; //le dernier on ne met pas son nom car le reste
      }
      
    function style(feature) {
        return {
          fillColor: getColor(feature.properties.risques7),
          weight: 0.5,
          opacity: 1,
          color: '#000000',
          dashArray: '3',
          fillOpacity: 0.7,
        };
      }

    const [selected, setSelected] = React.useState({});

    function highlightFeature(e) {
      var layer = e.target;
      const { risques7, libelle} = e.target.feature.properties;
      setSelected({
          risques:risques7,
          libelle:libelle,
      });
      layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
      });
    }
    function resetHighlight(e) {
      setSelected({});
      e.target.setStyle(style(e.target.feature));
    }
    function onEachFeature(feature, layer) {
      layer.on({
        click: highlightFeature,
        mouseout: resetHighlight
      });
    }
    return (
      <div className="totalCarte">
      {!loading && (
          <div className="">
          {!selected.risques && (
          <div className='paragraphe infos_carte'>
              Cliquez sur une zone pour avoir plus de détails
          </div>
          )}
          {selected.risques && (
              <div className='paragraphe infos_carte' >
                  {selected.libelle} : {selected.risques} €/ha
              </div>
          )}

          <MapContainer
            zoom={10}
            className='HeightMapRiskRdt'
            scrollWheelZoom={false}
            maxZoom={14}
            center={[48.832,2.623]}
            whenReady={e => {
              e.target.flyToBounds([
                [extent[1],extent[0]],
                [extent[3], extent[2]]
                  
                ]);
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {data && !loading && (
              <div>
                <GeoJSON data={data} 
                style={style} 
                onEachFeature={onEachFeature} />
                <Circle
                center={[dataGE['features'][0]['geometry']['coordinates'][1],dataGE['features'][0]['geometry']['coordinates'][0]]}
                pathOptions={{ color: 'black', fillColor: 'black', fillOpacity: 1  }}
                radius={1500}/>
              </div>
            )}
          </MapContainer>
        </div>
      )}
      </div>
    );
  }

export default CarteRisquesREGCour;
