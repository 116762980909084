import React, {useEffect} from 'react';
import {Navigate} from 'react-router-dom'
import apiLink from '../../config';

import Entete from '../MEP/entete'
import OngletNewAssol from './ongletNouvelAssol';

import data2050 from './datas/ParCulture/data2050_2degre'
import data2050_4degre from './datas/ParCulture/data2050_4degre'
import dataExpType from './datas/ExpTypes/dataExpType_2degre'
import dataExpType_4degre from './datas/ExpTypes/dataExpType_4degre';
import expType from './datas/expType_Assol_et_PB'
import dataCommune from './datas/commune'

import SH_ble1120 from '../../assets/SH_ble_11_20.JPG'
import SH_ble4150 from '../../assets/SH_ble_41_50.JPG'
import SH_mais1120 from '../../assets/SH_Mais_11_20.JPG'
import SH_mais4150 from '../../assets/SH_Mais_41_50.JPG'
import fleche_verte from '../../assets/Fleche_ouverte_verte.png'
import fleche_grise from '../../assets/Fleche_fermee_grise.png'



function PageParams() {
    const [GoToResultatsAssol, setGoToResultatsAssol] = React.useState(false)
    const [GoToResultatsCult, setGoToResultatsCult] = React.useState(false)
    const [PB, setPB] = React.useState()
    const [surf, setSurf] = React.useState()
    const [City, setCity] = React.useState("")
    const [CityName, setCityName] = React.useState("")
    const [culture, setCulture] = React.useState("")
    const [Precocite, setPrecocite] = React.useState("")
    const [semis, setSemis] = React.useState("")
    const [dataSurf, setDataSurf] = React.useState([{'name': 'Blé tendre de printemps : \n 29.79ha', 'value' :29.7896261120408, 'itemStyle':{'color': '#FFD705'}},{'name': 'Orge hiver : \n 34.82ha', 'value' :34.8215339119257, 'itemStyle':{'color': '#FFFF00'}},{'name': 'Maïs : \n 48.53ha', 'value' :48.5320390715106, 'itemStyle':{'color': '#92D050'}}])
    const [dataPB, setDataPb] = React.useState()
    const [R2020_2degre, setR2020_2degre] = React.useState()
    const [R2030_2degre, setR2030_2degre] = React.useState()
    const [R2040_2degre, setR2040_2degre] = React.useState()
    const [R2050_2degre, setR2050_2degre] = React.useState()
    const [Moy_2degre, setRMoy_2degre] = React.useState()
    const [R2020_4degre, setR2020_4degre] = React.useState()
    const [R2030_4degre, setR2030_4degre] = React.useState()
    const [R2040_4degre, setR2040_4degre] = React.useState()
    const [R2050_4degre, setR2050_4degre] = React.useState()
    const [Moy_4degre, setRMoy_4degre] = React.useState()
    const [dataListeSemis, setDataListeSemis] = React.useState([])
    const [libellPRA, setLibellePRA] = React.useState("")

    const [BSimul, setBSimul] = React.useState(true)
    const [BExpType, setBExpType] = React.useState(true)
    const [BCult, setBCult] = React.useState(true)
    const [BReprendre, setBReprendre] = React.useState(true)
    const [BNewSimul, setBNewSimul] = React.useState(false)

    const [Simulation, setSimulation]= React.useState([{"idExploitation":1,"idCommune":"72001","Nom":"Sim1","Exploitation":[{"Culture":"Bletendre_de_printemps","semis":"1/03","surface":45.57},{"Culture":"Orge_de_hiver","semis":"15/11","surface":15.85},{"Culture":"Mais","semis":"01/04","surface":15.85}]}])    
    const [SelectedSimulation, setSelectedSimulation]= React.useState()    

    useEffect ( () => {
        fetch('https://api2050.diagorisk.com/exploitationscree/')
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
            setSimulation(response)
            setSimulation(prev => {
              return prev;
            })
          
        })
      }, [])

    function shapeDataSimul(data){
      var { Nom, idCommune, Exploitation } = data;
      var culturesString = Exploitation.map(({ Culture, semis }) => `${Culture}(${semis})`).join('/');
      return `${Nom} - ${idCommune} - ${culturesString}`;
    }

    function handleChangeCity(event){
        var city = event.target.value
        setCity(city)
        searchPRA(city, expType)
        setCityName(event.target.options[event.target.selectedIndex].getAttribute("data-label"))
        fetch(apiLink + 'getCommune/' + city )
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setLibellePRA(response.libellePetiteRegionAgricole)
          setLibellePRA(prev => {
            return prev;
          })
          var cp = response.codePostal
          var libellecity = response.libelleCommune
          setCityName(cp + ' - ' + libellecity)
          setCityName(prev => {
            return prev;
          })
        })

    }
    function handleChangeSimulation(event){
      var simul = event.target.value
      setSelectedSimulation(simul)

    }

    function showhideSimul(){
      if (BSimul){
        setBSimul(false)
      }
      else{
        setBSimul(true)
      }
    }
    function showhideExpType(){
      if (BExpType){
        setBExpType(false)
      }
      else{
        setBExpType(true)
      }
    }
    function showhideCult(){
      if (BCult){
        setBCult(false)
      }
      else{
        setBCult(true)
      }
    }
    function handleReprendre(){
      if (BReprendre){
        setBReprendre(false)
        setBNewSimul(true)
      }
      else{
        setBReprendre(true)
        setBNewSimul(false)
      }
    }
    function handleNewAssol(){
      if (BNewSimul){
        setBNewSimul(false)
        setBReprendre(true)
      }
      else{
        setBNewSimul(true)
        setBReprendre(false)
      }
    }

    function handleChangeCUlture(event){
        var cult = event.target.value
        setCulture(cult)
        if (cult === "Bledur_de_hiver" || cult === "Bletendre_de_hiver" || cult === "Orge_de_hiver") {
          setDataListeSemis([{'value': "25/10", "libelle": "25 octobre"}, {'value': "30/10", "libelle": "30 octobre"},{'value': "05/11", "libelle": "5 novembre"}, {'value': "10/11", "libelle": "10 novembre"}, {'value': "15/11", "libelle": "15 novembre"}, {'value': "20/11", "libelle": "20 novembre"},  {'value': "25/11", "libelle": "25 novembre"}])
        }
        else if (cult === "Bledur_de_printemps" || cult === "Bletendre_de_printemps" || cult === "Orge_de_printemps") {
          setDataListeSemis([{'value': "15/02", "libelle": "15 février"}, {'value': "20/02", "libelle": "20 février"}, {'value': "25/02", "libelle": "25 février"},{'value': "05/03", "libelle": "5 mars"}, {'value': "10/03", "libelle": "10 mars"},{'value': "15/03", "libelle": "15 mars"} ])
        }
        else if (cult === "Mais") {
          setDataListeSemis([{'value': "15/03", "libelle": "15 mars"}, {'value': "20/03", "libelle": "20 mars"}, {'value': "25/03", "libelle": "25 mars"}, {'value': "30/03", "libelle": "30 mars"}, {'value': "05/04", "libelle": "5 avril"}, {'value': "10/04", "libelle": "10 avril"}, {'value': "15/04", "libelle": "15 avril"}, {'value': "20/04", "libelle": "20 avril"}, {'value': "25/04", "libelle": "25 avril"}, {'value': "30/04", "libelle": "30 avril"}, {'value': "05/05", "libelle": "5 mai"}, {'value': "10/05", "libelle": "10 mai"}, {'value': "15/05", "libelle": "15 mai"}, {'value': "20/05", "libelle": "20 mai"}, {'value': "25/05", "libelle": "25 mai"}, {'value': "30/05", "libelle": "30 mai"}])
        }
    }
    function handleChangePrecocite(event){
        var precocite = event.target.value
        setPrecocite(precocite)
    }
    function handleChangeSemis(event){
        var semis = event.target.value
        setSemis(semis)
    }

    function searchPRA(idCommune, expType){
      for (const key in expType) {
        const entry = expType[key];
        if (entry.idCommune === idCommune) {
          setLibellePRA(entry.libelle_PRA)
        }
      }
    }

    const findObject = (idCommune, culture, dataSemis, data) => {
        for (const key in data) {
          const entry = data[key];
          if (
            entry.idCommune === idCommune &&
            entry.Culture === culture &&
            entry.DataSemis === dataSemis
          ) {
            return entry;
          }
        }
        return null; // Retourne null si aucun objet correspondant n'est trouvé
      };


    function processData(inputData){
        var color 
        var PB 
        if (inputData.Culture === "Bletendre_de_printemps" || inputData.Culture === "Bletendre_de_hiver") {
            color = '#FFD705'
            PB = 107260 
        }
        else if (inputData.Culture === "Bledur_de_printemps" || inputData.Culture === "Bledur_de_hiver"){
            color = '#F8BE3E'
            PB = 151956 
        }
        else if (inputData.Culture === "Orge_de_printemps"){
            color = '#FFF385'
            PB = 62118
        }
        else if (inputData.Culture === "Orge_de_hiver"){
            color = '#FFFF00'
            PB = 95540
        }/* 
        else if (inputData.Culture === "Colza"){
            color = '#D7A657'
            PB = 121879
        } */
        else if (inputData.Culture === "Mais"){
            color = '#92D050'
            PB = 74060
        }
        else {
            color = 'white'
            PB=1
        }

        setPB(PB)
        setSurf(100)

        setDataSurf([{
          "name": `${inputData.Culture}: 100ha\n(${inputData.DataSemis})`,
          "value": 100,
          "itemStyle": { color: color }
        }])
      
        setDataPb( [{
          "name": `${inputData.Culture}: ${PB.toLocaleString()}€\n(${inputData.DataSemis})`,
          "value": PB,
          "itemStyle": { color: color }
        }])
      
        setR2020_2degre([inputData.TCo20, inputData.Co20, inputData.Exc20, inputData.Ca20])
        setR2030_2degre([inputData.TCo30, inputData.Co30, inputData.Exc30, inputData.Ca30])
        setR2040_2degre([inputData.TCo40, inputData.Co40, inputData.Exc40, inputData.Ca40])
        setR2050_2degre([inputData.TCo50, inputData.Co50, inputData.Exc50, inputData.Ca50])
        setRMoy_2degre([inputData.Moy20, inputData.Moy30, inputData.Moy40, inputData.Moy50])
      };

      function processData4d(inputData){      
        setR2020_4degre([inputData.TCo20, inputData.Co20, inputData.Exc20, inputData.Ca20])
        setR2030_4degre([inputData.TCo30, inputData.Co30, inputData.Exc30, inputData.Ca30])
        setR2040_4degre([inputData.TCo40, inputData.Co40, inputData.Exc40, inputData.Ca40])
        setR2050_4degre([inputData.TCo50, inputData.Co50, inputData.Exc50, inputData.Ca50])
        setRMoy_4degre([inputData.Moy20, inputData.Moy30, inputData.Moy40, inputData.Moy50])
      };

      
      const findExpTypeData = (idCommune, data2d, data4d) => {
        for (const key in data2d) {
          const entry = data2d[key];
          if (
            entry.idCommune === idCommune 
          ) {
            setR2020_2degre([entry.TCo20, entry.Co20, entry.Exc20, entry.Ca20])
            setR2030_2degre([entry.TCo30, entry.Co30, entry.Exc30, entry.Ca30])
            setR2040_2degre([entry.TCo40, entry.Co40, entry.Exc40, entry.Ca40])
            setR2050_2degre([entry.TCo50, entry.Co50, entry.Exc50, entry.Ca50])
            setRMoy_2degre([entry.Moy20, entry.Moy30, entry.Moy40, entry.Moy50])
          }
        }
        for (const key in data4d) {
          const entry4d = data4d[key];
          if (
            entry4d.idCommune === idCommune 
          ) {
            setR2020_4degre([entry4d.TCo20, entry4d.Co20, entry4d.Exc20, entry4d.Ca20])
            setR2030_4degre([entry4d.TCo30, entry4d.Co30, entry4d.Exc30, entry4d.Ca30])
            setR2040_4degre([entry4d.TCo40, entry4d.Co40, entry4d.Exc40, entry4d.Ca40])
            setR2050_4degre([entry4d.TCo50, entry4d.Co50, entry4d.Exc50, entry4d.Ca50])
            setRMoy_4degre([entry4d.Moy20, entry4d.Moy30, entry4d.Moy40, entry4d.Moy50])
          }
        }
        return null; // Retourne null si aucun objet correspondant n'est trouvé
      };

      function configDataSurfPB(idCommune, expType){
        for (const key in expType) {
          const entry = expType[key];
          if (entry.idCommune === idCommune) {
            setPB(entry.PB)
            setSurf(entry.Surface)
            setDataSurf(entry.dataSurf)
            setDataPb(entry.dataPB)
            
          }
        }
      }
    const calculateSum = (dataSurface) => {
      if (dataSurface) {
        return dataSurface.reduce((sum, item) => sum + item.value, 0);
      }
      return 0;
    };

    function handleSimuler1cult(){
        if(City!=="" && culture!=="" && semis!==""){
           var production = findObject(City,culture,semis,data2050)
           var production2 = findObject(City,culture,semis,data2050_4degre)
           processData(production)
           processData4d(production2)
           /*fetch('https://api2050.diagorisk.com/resultatExploitation/?resultatCulture/idCommune=' + City + '&Culture=' + Culture '&Semis=' + semis)
            .then( (response) => {
              return response.json()
            })
            .then(response =>{
                setDataSurf(response.dataSurface)
                setDataSurf(prev => {
                  setSurf(calculateSum(prev))
                  return prev;
                })
                setDataPb(response.dataPB)
                setDataPb(prev => {
                  setPB(calculateSum(prev))
                  return prev;
                })
                setR2020_2degre(response.R2020_2degre)
                setR2020_2degre(prev => {
                  return prev;
                })
                setR2030_2degre(response.R2030_2degre)
                setR2030_2degre(prev => {
                  return prev;
                })
                setR2040_2degre(response.R2040_2degre)
                setR2040_2degre(prev => {
                  return prev;
                })
                setR2050_2degre(response.R2050_2degre)
                setR2050_2degre(prev => {
                  return prev;
                })
                setRMoy_2degre(response.RMoy_2degre)
                setRMoy_2degre(prev => {
                  return prev;
                })
                setR2020_4degre(response.R2020_4degre)
                setR2020_4degre(prev => {
                  return prev;
                })
                setR2030_4degre(response.R2030_4degre)
                setR2030_4degre(prev => {
                  return prev;
                })
                setR2040_4degre(response.R2040_4degre)
                setR2040_4degre(prev => {
                  return prev;
                })
                setR2050_4degre(response.R2050_4degre)
                setR2050_4degre(prev => {
                  return prev;
                })
                setRMoy_4degre(response.RMoy_4degre)
                setRMoy_4degre(prev => {
                  return prev;
                })
            }) */
           setGoToResultatsCult(true) 
        }
        else {
            alert("Remplissez tous les champs de la partie Culture et sélectionnez votre ville")
        }
        
    }
    function handleSimulerAssolExistant(){
      if(SelectedSimulation!==""){
        fetch('https://api2050.diagorisk.com/resultatExploitation/?idExploit=' + SelectedSimulation)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
            setDataSurf(response.dataSurface)
            setDataSurf(prev => {
              setSurf(calculateSum(prev))
              return prev;
            })
            setDataPb(response.dataPB)
            setDataPb(prev => {
              setPB(calculateSum(prev))
              return prev;
            })
            setR2020_2degre(response.R2020_2degre)
            setR2020_2degre(prev => {
              return prev;
            })
            setR2030_2degre(response.R2030_2degre)
            setR2030_2degre(prev => {
              return prev;
            })
            setR2040_2degre(response.R2040_2degre)
            setR2040_2degre(prev => {
              return prev;
            })
            setR2050_2degre(response.R2050_2degre)
            setR2050_2degre(prev => {
              return prev;
            })
            setRMoy_2degre(response.RMoy_2degre)
            setRMoy_2degre(prev => {
              return prev;
            })
            setR2020_4degre(response.R2020_4degre)
            setR2020_4degre(prev => {
              return prev;
            })
            setR2030_4degre(response.R2030_4degre)
            setR2030_4degre(prev => {
              return prev;
            })
            setR2040_4degre(response.R2040_4degre)
            setR2040_4degre(prev => {
              return prev;
            })
            setR2050_4degre(response.R2050_4degre)
            setR2050_4degre(prev => {
              return prev;
            })
            setRMoy_4degre(response.RMoy_4degre)
            setRMoy_4degre(prev => {
              return prev;
            })
        })
        setGoToResultatsAssol(true)
     }
     else {
         alert("Sélectionnez une simulation à reprendre")
     }
    }
    function handleSimulerAssol(){
      if(City!==""){
        findExpTypeData(City, dataExpType, dataExpType_4degre)
        configDataSurfPB(City, expType)
        /* fetch('https://api2050.diagorisk.com/resultatsExploitationType/?idCommune=' + city)
          .then( (response) => {
            return response.json()
          })
          .then(response =>{
              setDataSurf(response.dataSurface)
              setDataSurf(prev => {
                setSurf(calculateSum(prev))
                return prev;
              })
              setDataPb(response.dataPB)
              setDataPb(prev => {
                setPB(calculateSum(prev))
                return prev;
              })
              setR2020_2degre(response.R2020_2degre)
              setR2020_2degre(prev => {
                return prev;
              })
              setR2030_2degre(response.R2030_2degre)
              setR2030_2degre(prev => {
                return prev;
              })
              setR2040_2degre(response.R2040_2degre)
              setR2040_2degre(prev => {
                return prev;
              })
              setR2050_2degre(response.R2050_2degre)
              setR2050_2degre(prev => {
                return prev;
              })
              setRMoy_2degre(response.RMoy_2degre)
              setRMoy_2degre(prev => {
                return prev;
              })
              setR2020_4degre(response.R2020_4degre)
              setR2020_4degre(prev => {
                return prev;
              })
              setR2030_4degre(response.R2030_4degre)
              setR2030_4degre(prev => {
                return prev;
              })
              setR2040_4degre(response.R2040_4degre)
              setR2040_4degre(prev => {
                return prev;
              })
              setR2050_4degre(response.R2050_4degre)
              setR2050_4degre(prev => {
                return prev;
              })
              setRMoy_4degre(response.RMoy_4degre)
              setRMoy_4degre(prev => {
                return prev;
              })
          }) */
        setGoToResultatsAssol(true)
     }
     else {
         alert("sélectionnez votre ville")
     }
    }

    if(GoToResultatsAssol) {
        return <Navigate push to={`/resultats2050assolement`} state={{dataSurf, dataPB, R2020_2degre, R2030_2degre, R2040_2degre, R2050_2degre, Moy_2degre, R2020_4degre, R2030_4degre, R2040_4degre, R2050_4degre, Moy_4degre, PB, surf, CityName, libellPRA}}/>
      }
      if(GoToResultatsCult) {
        return <Navigate push to={`/resultats2050culture`} state={{dataSurf, dataPB, R2020_2degre, R2030_2degre, R2040_2degre, R2050_2degre, Moy_2degre, R2020_4degre, R2030_4degre, R2040_4degre, R2050_4degre, Moy_4degre, PB, surf, CityName, libellPRA}}/>
      }

  return (
    <div>
      <Entete titre="2050"/>
      <div className='container'>
        <div className='row rowCompSimul'> 
          <button onClick={handleReprendre} className={BReprendre ? 'validation validationCompSimul BCompSimulActive' : 'validation validationCompSimul BCompSimulDesActive'}> Reprendre une simulation </button>
          <button onClick={handleNewAssol}  className={BNewSimul ? 'validation validationCompSimul BCompSimulActive' : 'validation validationCompSimul BCompSimulDesActive'}> Nouvel assolement </button>
        </div>

        {/* PAGE REPRENDRE UNE SIMULATION */}
        <div className={BReprendre? 'ShowResult' : 'HideResult'}>
          <div className='titre' >Reprendre une simulation  <button className='ButtonHome' onClick={showhideSimul} style={{height:'35px', marginLeft:'0px'}}><img src={BSimul ? fleche_verte : fleche_grise} className="LogoRond"  alt='' style={{marginRight:'0px'}}/></button></div>
          <div className={BSimul? 'ShowResultFlex' : 'HideResult'}>
            <div className='bottom bottom2' style={{marginBottom:'5px',marginTop:'0px',justifyContent:'flex-start'}}> 
              <div className='titreMoins' style={{marginTop:'5px'}}> Sélectionnez la simulation </div>
              <select  value={SelectedSimulation} onChange={handleChangeSimulation} style={{ marginBottom:'10px'}}>
                <option value="">Sélectionnez</option>
                {Simulation.map((item)=>( <option key={item.idExploitation} value={item.idExploitation} > {shapeDataSimul(item)} </option> ) )}
              </select>
            </div>
            <div className='bottom bottom2' style={{marginBottom:'5px',marginTop:'0px'}}>
              <button className='validation' onClick={handleSimulerAssolExistant}> Simuler </button>
            </div>
          </div>

          <div className='titre'>Exploitation type <button className='ButtonHome' onClick={showhideExpType} style={{height:'35px', marginLeft:'0px'}}><img src={BExpType ? fleche_verte : fleche_grise} className="LogoRond"  alt='' style={{marginRight:'0px'}}/></button> </div>
          <div className={BExpType? 'ShowResultFlex' : 'HideResult'}>
            <div className='bottom bottom2' style={{marginBottom:'5px',marginTop:'0px',justifyContent:'flex-start'}}> 
              <div className='titreMoins' style={{marginTop:'5px'}}> Sélectionnez la commune de l'exploitation type souhaitée </div>
              <select value={City} onChange={handleChangeCity}  style={{ marginBottom:'10px'}}>
                <option value="">Sélectionnez</option>
                {dataCommune.map((item)=>( <option key={item.idCommune} value={item.idCommune} data-label={item.City} > {item.City} </option> ) )}
              </select>
            </div>
            <div className='bottom bottom2' style={{marginBottom:'5px',marginTop:'0px'}}>
              <button onClick={handleSimulerAssol} className='validation'> Simuler </button>
            </div>
          </div>

          <div className='titre'>Simuler une culture <button className='ButtonHome' onClick={showhideCult} style={{height:'35px', marginLeft:'0px'}}><img src={BCult ? fleche_verte : fleche_grise} className="LogoRond"  alt='' style={{marginRight:'0px'}}/></button> </div>
          <div className={BCult? 'ShowResultFlex' : 'HideResult'}>
              <div className='bottom bottom2' style={{marginBottom:'5px',marginTop:'0px',justifyContent:'flex-start'}}> 
                <div className='titreMoins' style={{marginTop:'5px'}}>Sélectionnez votre commune : </div>
                <select value={City} onChange={handleChangeCity}  style={{ marginBottom:'10px'}}>
                    <option value="">Sélectionnez</option>
                    {dataCommune.map((item)=>( <option key={item.idCommune} value={item.idCommune} data-label={item.City} > {item.City} </option> ) )}
                </select>
                <div className='titreMoins' style={{marginTop:'5px'}}>Culture :</div>
                <select value={culture} onChange={handleChangeCUlture}  style={{ marginBottom:'10px', width:'50%'}}>
                    <option value="">Sélectionnez</option>
                    <option value="Bletendre_de_printemps">Blé tendre de printemps</option>
                    <option value="Bletendre_de_hiver">Blé tendre d'hiver</option>
                    <option value="Bledur_de_printemps">Blé dur de printemps</option>
                    <option value="Bledur_de_hiver">Blé dur d'hiver</option>
                    <option value="Orge_de_printemps">Orge de printemps</option>
                    <option value="Orge_de_hiver">Orge d'hiver</option>
                    <option value="Mais">Maïs</option>
                    {/* <option value="Colza">Colza</option> */}
                </select>
                <div className='titreMoins' style={{marginTop:'5px'}}>Précocite :</div>
                <select value={Precocite} onChange={handleChangePrecocite}  style={{ marginBottom:'10px', width:'50%'}}>
                    <option value="">Sélectionnez</option>
                    <option value="5">Tardive</option>
                    <option value="5.5">1/2 Tardive</option>
                    <option value="6">1/2 tardive à 1/2 précoce</option>
                    <option value="6.5">1/2 précoce</option>
                    <option value="7">Précoce</option>
                    <option value="7.5">Très précoce</option>
                </select>
                <div className='titreMoins' style={{marginTop:'5px'}}>Date de semis :</div>
                <select value={semis} onChange={handleChangeSemis}  style={{ marginBottom:'10px', width:'50%'}}>
                    <option value="">Sélectionnez</option>
                    {dataListeSemis.map((item)=>( <option key={item.value} value={item.value} > {item.libelle} </option> ) )}
                </select>
              </div>
              <div className='bottom bottom2' style={{marginBottom:'5px',marginTop:'0px'}}>
                <button onClick={handleSimuler1cult} className='validation'> Simuler </button>
              </div>
          </div>

          {/* <div className='row'>
              <img src={SH_ble1120} alt="" className="" style={{width:'50%'}}/>
              <img src={SH_ble4150} alt="" className="" style={{width:'50%'}}/>
          </div>
          <div className='row'>
              <img src={SH_mais1120} alt="" className="" style={{width:'50%'}}/>
              <img src={SH_mais4150} alt="" className="" style={{width:'50%'}}/>
          </div> */}
        </div>

        {/* PAGE REPRENDRE NOUVEL SIMULATION */}
        <div className={BNewSimul? 'ShowResult' : 'HideResult'}> 
          <OngletNewAssol/>
        </div>


      </div>

    </div>
  )
}

export default PageParams