import React, {useState, useEffect} from 'react'
import Select from 'react-select';

const Culture = ({keys,data, MAJ_surf_rdt_prix_spe, ClickedGC, ClickedMixte, ClickedViti, libelle, typeCulture}) => {
    const dataSpe = data[keys]['Specification']

    const testShow = (ClickedGC, ClickedViti) => {
        var show = true 
        if (ClickedGC) {
            if (keys ==="630" || keys ==="670" || keys ==="675") {
                show = false
            }
        }
        else if(ClickedViti) {
            if (keys !=="630" && keys !=="670" && keys !=="675") {
                show = false
            }
        }
        return show
    }
    
    function handleChangeSurface(event) {
        var surf = event.currentTarget.value;
         if (surf<0){
            MAJ_surf_rdt_prix_spe(keys, "surface", Math.abs(surf));
        }
        else {
            MAJ_surf_rdt_prix_spe(keys, "surface", surf);
        }
    }

    function handleChangeRdt(event) {
        var rdt = event.currentTarget.value;
        if (rdt<0){
            MAJ_surf_rdt_prix_spe(keys, "rendement", Math.abs(rdt));
        }
        else {
            MAJ_surf_rdt_prix_spe(keys, "rendement", rdt)
        }
    }

    function handleChangePrix(event) {
        var prix = event.currentTarget.value;
        if (prix<0){
            MAJ_surf_rdt_prix_spe(keys, "prix", Math.abs(prix));
        }
        else {
            MAJ_surf_rdt_prix_spe(keys, "prix", prix);
        }
        
    }

    function handleChangeSpe(event){
        var spe = event.value
        var idTC_rdt 
        var idTC_prix
        var idTCNone_rdt
        var idTCNone_prix
        if(typeCulture==="Conventionnelle") {
            idTC_rdt = 'rendement'
            idTC_prix = 'prix'
            idTCNone_rdt = 'rendementConv'
            idTCNone_prix = "prixConv"
        }
        else {
            idTC_rdt = 'rendementBio'
            idTC_prix = 'prixBio'
            idTCNone_rdt = 'rendementBio'
            idTCNone_prix = 'prixBio'
        }
        if (spe !== "none"){
            MAJ_surf_rdt_prix_spe(keys, "rendement", data[keys]['Specification'][spe][idTC_rdt]);
            MAJ_surf_rdt_prix_spe(keys, "prix",data[keys]['Specification'][spe][idTC_prix]);
            MAJ_surf_rdt_prix_spe(keys, "SelectedSpe", data[keys]['Specification'][spe]['key']);
        }
        else {
            MAJ_surf_rdt_prix_spe(keys, "rendement", data[keys][idTCNone_rdt]);
            MAJ_surf_rdt_prix_spe(keys, "prix", data[keys][idTCNone_prix]);
            MAJ_surf_rdt_prix_spe(keys, "SelectedSpe", "none");
        }
        
    }
    const options = Object.keys(dataSpe).map((item)=>(
        {"value": dataSpe[item].key, "label":dataSpe[item].libelle}
    ))

    const optionsNull = {"value":"none", "label":""}
    options.unshift(optionsNull)

    const styles = {
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isSelected ? "bold" : "normal",
          fontSize: "16px",
          width:'95%',
          minHeight: '30px',
          height: '30px',
        }),
        container:(provided) => ({
            ...provided,
          width:'95%',
          minHeight: '30px',
          height: '30px',
        }),
        control:(provided) => ({
            ...provided,
          width:'95%',
          minHeight: '35px',
          height: '35px',
          border: '1px solid grey',
          boxShadow: 'none',
          '&:hover': {
              border: '1px solid #adadad',
          }
        }),
        indicators:(provided) => ({
            ...provided,
          width:'95%',
        }),
        valueContainer: (provided) => ({
          ...provided,
          marginTop:'-25px',
          minHeight: '50px',
          height: '50px',
          color:'black'
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          minHeight: '35px',
          height: '35px',
        }),
        placeholder:(provided) => ({
            ...provided,
            height:"20px",
            color: "black",
        }),
      };

    return (
        <div>
            {testShow(ClickedGC, ClickedViti) ? (
                <div className='row ligne_assol'>
                <div className='col_assol col_libelle'>
                {libelle}
                </div>
                <div className='col_assol col_spe'> 
                    <Select 
                    options={options} 
                    value={options.find(option => option.value === data[keys]['SelectedSpe'])}
                    onChange={handleChangeSpe}  
                    styles={styles}/>
                    {/* <select onChange={handleChangeSpe} className='select_assol'>
                        <option key='standard' value="standard">Sélectionnez </option>
                        {Object.keys(dataSpe).map((item)=>( <option key={dataSpe[item].key} value={dataSpe[item].key} > {dataSpe[item].libelle} </option> ) )}
                    </select> */}
                </div>
                <div className='col_assol col_surface'>
                <div className='videBlocAssol'>
                    <input 
                    className='input_assol'
                    onChange={handleChangeSurface}
                    value= {data[keys]["surface"]}
                    placeholder='0'
                    type='number'
                    step='0.01'
                    disabled={(keys === "630" || keys === "670" || keys === "675")  && data[keys]["SelectedSpe"] === "none" }/>
                    <div className='pleinBlocAssol' style={{backgroundColor: data[keys]["surface"] >0 ? 'rgb(179, 231, 148)' : '#C5C5CC', color: data[keys]["surface"] >0 ? '#117900' : 'black' }}>
                ha
                </div>
                </div>
                </div>
                <div className='col_assol col_rdt'>
                <div className='videBlocAssol'>
                <input 
                    className='input_assol'
                    onChange={handleChangeRdt}
                    value= {data[keys]["rendement"]}
                    placeholder='0'
                    type='number'
                    step='0.01'
                    disabled={(keys === "630" || keys === "670" || keys === "675")  && data[keys]["SelectedSpe"] === "none" }/>
                    <div className='pleinBlocAssol'>
                    {keys==='630' || keys==='670' || keys==='675' ?'hl/ha' : 't/ha'}
                    </div>
                </div>
                </div>
                <div className='col_assol col_prix'>
                <div className='videBlocAssol'>
                    <input 
                    className='input_assol'
                    onChange={handleChangePrix}
                    value= {data[keys]["prix"]}
                    placeholder='0'
                    type='number'
                    step='0.01'
                    disabled={(keys === "630" || keys === "670" || keys === "675")  && data[keys]["SelectedSpe"] === "none" }/>
                    <div className='pleinBlocAssol'>
                    {keys==='630' || keys==='670' || keys==='675' ?'€/hl' : '€/t'}
                    </div>
                </div>
                </div>
                <div className='col_assol col_PB_unitaire'>
                    {Math.round(data[keys]["rendement"]*data[keys]["prix"]).toLocaleString()} €
                </div>
                <div className='col_assol col_PB_tit_Prod'>
                    {Math.round(data[keys]["surface"]*data[keys]["rendement"]*data[keys]["prix"]).toLocaleString()} €
                </div>
            </div>
            ) : ""}
        </div>
    )
}
export default Culture;