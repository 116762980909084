import React, {useState} from 'react'
import {Navigate} from 'react-router-dom'

function Nav() {

    const [showLinks, setShowLinks] = useState(false)
    const [goToHome, setGoToHome] = useState(false)
    const [goToSol, setGoToSol] = useState(false)
    const [goToBlog, setGoToBlog] = useState(false)
    const [goToAb, setGoToAb] = useState(false)
    const [goToQSN, setGoToQSN] = useState(false)
    const [goToNR, setGoToNR] = useState(false)

    const handleShowLinks = () => {
        setShowLinks(!showLinks)
    }

    const url = window.location.href
    const root = window.location.origin

    function handleClickHome(event) {
        event.preventDefault()
        setGoToHome(true)
    }
    function handleClickSol(event) {
        event.preventDefault()
        setGoToSol(true)
    }
    function handleClickAb(event) {
        event.preventDefault()
        setGoToAb(true)
    }
    function handleClickQSN(event) {
        event.preventDefault()
        setGoToQSN(true)
    }
    function handleClickBlog(event) {
        event.preventDefault()
        setGoToBlog(true)
    }
    function handleClickNR(event) {
        event.preventDefault()
        setGoToNR(true)
    }

    if(goToHome) {
        return <Navigate push to={`/`} />
    }
    if(goToSol) {
        return <Navigate push to={`/nossolutions`} />
    }
    if(goToAb) {
        return <Navigate push to={`/abonnements`} />
    }
    if(goToBlog) {
        return <Navigate push to={`/blog`} />
    }
    if(goToQSN) {
        return <Navigate push to={`/quisommesnous`} />
    }
    if(goToNR) {
        return <Navigate push to={`/nousrejoindre`} />
    }

  return (
    <nav className={showLinks? 'navbarSW show-nav' : 'navbarSW hide-nav'}>
        <ul className='navbarSW_links'>
            <li className='navbarSW_item'>
                <button className='ButtonHome navbarSW_lien' onClick={handleClickHome} disabled={url===root+'/' ? true : false} > Accueil </button>
            </li>
            <li className='navbarSW_item'>
                <button className='ButtonHome navbarSW_lien' onClick={handleClickSol} disabled={url.indexOf("nossolutions") > -1 ? true : false} > Solutions </button>
            </li>
            <li className='navbarSW_item'>
                <button className='ButtonHome navbarSW_lien' onClick={handleClickAb} disabled={url.indexOf("abonnements") > -1 ? true : false} > Tarifs </button>
            </li>
            <li className='navbarSW_item'>
                <button className='ButtonHome navbarSW_lien' onClick={handleClickBlog} disabled={url.indexOf("blog") > -1 ? true : false} > Blog </button>
            </li>
            <li className='navbarSW_item'>
                <button className='ButtonHome navbarSW_lien' onClick={handleClickQSN} disabled={url.indexOf("quisommesnous") > -1 ? true : false}  > Qui sommes-nous ? </button>
            </li>
           {/*  <li className='navbarSW_item'>
                <button className='ButtonHome navbarSW_lien' onClick={handleClickNR} disabled={url.indexOf("/nousrejoindre") > -1 ? true : false} > Nous rejoindre </button>
            </li> */}
        </ul>
        <button className='navbarSW_burger' onClick={handleShowLinks}>
            <span className='burger-bar'></span>
        </button>

    </nav>
  )
}

export default Nav