import React, {useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom'

import logo from '../../assets/DiagoRisk-logoRVB.png'
import mans from '../../assets/le_mans.png'
import RA from '../../assets/cartes_RA.png'
import star1 from '../../assets/scintillait.png'
import star2 from '../../assets/scintillait_bleue.png'
import star3 from '../../assets/scintillait_vert.png'
import mascotte from '../../assets/Mascotte/Neutre-YC.png'

import Nav from '../MEP/SWNav'
import FooterSW from '../MEP/footer_SW';


function Accueil() {

  const [idAgent, setidAgent] = useState();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('idAgent'));
    if (items) {
      setidAgent(items);
    }
  }, []);

  const [goToLocalisation, setGoToLocalisation] = useState(false)
  const [goToConnexion, setGoToConnexion] = useState(false)
  const [goToSimulation, setGoToSimulation] = useState(false)
  const [goToInscription, setGoToInscription] = useState(false)


  function handleSubmitLoca(event) {
    event.preventDefault()
    setGoToLocalisation(true)
  }

  function handleSubmitAccesCompte(event) {
    event.preventDefault()
    if(idAgent !== undefined) {
      setGoToSimulation(true)
    }
    else {
      setGoToConnexion(true)
    }
  }
  function handleSubmitInscription(event) {
    event.preventDefault()
    setGoToInscription(true)
  }

  if(goToLocalisation) {
      return <Navigate push to={`/ma_localisation`} />
  }
  if(goToConnexion) {
    return <Navigate push to={`/connexion`} />
  }
  if(goToSimulation) {
    return <Navigate push to={`/simulations`}/>
  }
  if(goToInscription) {
    return <Navigate push to={`/abonnements`}/>
  }

  return (
    <div>
      <div className="accueil" >
            <Nav/>
          <div className="principal" >
            <div className='top'>
              <div className="top1">
                <img src={logo} alt="logo_diagorisk" className="App-logo"/>
              </div>
              <div className="top2">
              <h2>Avec le concours scientifique de </h2>	
                <img src={mans} alt="logo_le_mans" className="Mans-logo"/>
              </div>
            </div>

            <div className="bottom">
                <button onClick={handleSubmitInscription} className='buttonAccueil buttonActive'> Souscrire </button>
                <button onClick={handleSubmitAccesCompte} className='buttonAccueil buttonActive'> Accéder à mon compte </button>
              <button onClick={handleSubmitLoca} className= 'buttonAccueil buttonActive'> Découvrir DiagoRisk </button>
            </div>
          </div>
      </div>

      <div className='column'>
        <div className='cardAccueil cardAccueil2' style={{backgroundColor:'rgb(123, 195, 97)'}}>
          <div className='textCardAccueil textCardAccueil2'>
          <b>Dans un contexte d'instabilité et de vulnérabilité croissante, DiagoRisk aide chaque agriculteur à répondre à 3 questions clés :</b>
            <ul>
              <li style={{color:'black'}}> <div className='lila'>  Combien peut me coûter la prochaine crise ou accident climatique ? </div> </li>
              <li style={{color:'black'}}> <div className='lila'>  Suis-je suffisamment couvert ? </div> </li>
              <li style={{color:'black'}}> <div className='lila'>  Comment faire mieux ou moins cher ? </div> </li>
            </ul>
          </div>
          <img src={RA} alt="" className="imageAccueil"/>
        </div>

      
        <div className='cardAccueil cardAccueil2 accueil cardAccueilImage1' style={{padding:'0px'}}>
          <iframe
            style={{ marginTop: '20px', marginBottom: '20px' }}
            width="30%"
            height="315"
            src={"https://www.youtube.com/embed/mwkac7NX5II?si=-FB76rG0mje1kvUL"}
            title="YouTube Video"
            frameBorder="0"
            allowFullScreen
          />
          <div className='textCardAccueil textCardAccueil2' style={{marginLeft:"20px", width:"40%"}}>
            <div className='titreImage1Accueil' style={{color:'white', fontWeight:'bold'}}>Avec cet OAD nous avons pour ambition de :</div>
            <ul >
              <li style={{color:'white'}}> Mieux évaluer le besoin de couverture pour apporter des réponses plus efficaces </li>
              <li style={{color:'white'}}> Aider les agriculteurs à mieux résister aux crises</li>
              <li style={{color:'white'}}> Sécuriser la transition agroécologique et l'adoption de nouvelles pratiques </li>
            </ul>
          </div>
        </div>



        <div className='cardAccueil cardAccueil2' style={{backgroundColor:'rgb(206, 234, 175)'}}>
          <div className='textCardAccueil textCardAccueil2'>
            <div className='titreBleuAccueil'>Vous souhaitez renforcer la résilience des agriculteurs et les accompagner face aux risques ! Nous vous aidons pour :</div>
            <ul >
              <li style={{color:'black'}}> <div className='lila'>  Prototyper des solutions et en mesurer l'efficacité </div> </li>
              <li style={{color:'black'}}> <div className='lila'>  Evaluer le risque sur un portefeuille, une filière et/ou un territoire </div> </li>
              <li style={{color:'black'}}> <div className='lila'>  Ajuster l'accompagnement selon la situation de chaque agriculteur et sa capacité à adopter de nouvelles pratiques </div> </li>
            </ul>
          </div>
          <img src={mascotte} alt="" className="imageAccueil"/>
        </div>

        <div className='cardAccueil cardAccueil2 accueil cardAccueilImage2'>
          <div className='textCardAccueil textCardAccueil2 titreImage2Accueil'>
          Le moteur de calcul a été développé en partenariat avec l’Institut du Risque et de l’Assurance de l’Université du Mans et valorise :
          </div>
          <img src={star3} alt="" className="imageAccueil starAccueil"/>
          <img src={star1} alt="" className="imageAccueil starAccueil"/>
          <img src={star2} alt="" className="imageAccueil starAccueil"/>
        </div>

        <div className='titreAccueil' style={{color:'#92D050', marginBottom:'20px', marginTop:'20px'}}>
          Notre objectif est de répondre aux questions de 80% des agriculteurs et viticulteurs.
        </div>

      </div>
      <FooterSW/>
    </div>
  );
}

export default Accueil;

