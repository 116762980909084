import React, {useState} from 'react'
import mascotte from './assets/Mascotte/Etonné-YD.png'
import {Navigate} from 'react-router-dom'

function NotFound() {
    const [goToHome, setGoToHome] = useState(false)

    function handleClick(event) {
        event.preventDefault()
        setGoToHome(true)
    }

    if(goToHome) {
        return <Navigate push to={`/`} />
    }

  return (
    <div className='row containerNotFound' >
        <div style={{width:'50%'}}> <img src={mascotte} alt="mascotte étonnée" className="mascotte_notfound"/> </div>
        <div style={{width:'50%'}}>
            <div className='titreNotFound'> Oups </div>
            <div className='paragrapheNotFound'> Il semblerait que la page que vous cherchiez soit introuvable </div>
            <button className='buttonNotFound' onClick={handleClick} > <div className='paragrapheNotFound'> Retour à l'acceuil </div></button>
        </div>
    </div>
  )
}

export default NotFound