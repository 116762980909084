import React, {useState} from 'react';
import {Navigate} from 'react-router-dom'
import Nav from '../MEP/SWNav';
import EnteteSW from '../MEP/enteteSW';
import FooterSW from '../MEP/footer_SW';
import logoFlash from '../../assets/2_picto_pro.png'
import logoEssentielle from '../../assets/3_picto_premium.png'
import logoPremium from '../../assets/4_picto_surMesure.png'
import OUI from '../../assets/Oui.png'
import NON from '../../assets/Non.png'

function Abonnement() {
  const [TarifsPremium, setTPrem] = React.useState(520)
  const [goToInscription, setGoToInscription] = useState(false)
  const [goToContact, setGoToContact] = useState(false)
  const [offre, setOffre] = useState()


  function handleCompteF(event){
    event.preventDefault()
    setOffre('Flash')
    setGoToInscription(true)
  }

  function handleCompteE(event){
    event.preventDefault()
    setOffre('Essentielle')
    setGoToInscription(true)
  }
  function handleCompteP(event){
    event.preventDefault()
    setOffre('Premium')
    setGoToInscription(true)
  }

  function handleContacter(event){
    event.preventDefault()
    setGoToContact(true)
  }

  if(goToInscription) {
    return <Navigate push to={`/inscription` }  state={{offre}}/>
  }
  if(goToContact) {
    return <Navigate push to={`/nouscontacter`} />
  }

  return (
    <div>
      <Nav/>
      <EnteteSW titre="Tarifs"/>
      <div className="containerSW" >

        <div className='tableauAbo'>
          <div className='row'> 
            <div className='enteteLigneAbo'>

            </div>
            <div className='celluleInfoAbo'>
              <img src={logoFlash} alt="" className="LogoOffres"/>
            </div>
            <div className='celluleInfoAbo'>
              <img src={logoEssentielle} alt="" className="LogoOffres"/>
            </div>
            <div className='celluleInfoAbo'>
              <img src={logoPremium} alt="" className="LogoOffres"/>
            </div>
          </div>
          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe'>
            
            </div>
            <div className='celluleInfoAbo paragraphe celluleTitreAbo' style={{backgroundColor:'#92D050'}}>
              Flash
            </div>
            <div className='celluleInfoAbo paragraphe celluleTitreAbo' style={{backgroundColor:'#75B532'}}>
              Essentielle
            </div>
            <div className='celluleInfoAbo paragraphe celluleTitreAbo' style={{backgroundColor:'#4F7D1D'}}>
              Premium
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Chiffrer mes risques en grandes cultures et en viticulture
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Me comparer au sein de mon territoire
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Estimer le coût des options d’assurance Récolte
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Optimiser mon ratio Coût / Sécurité
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Personnaliser mon assolement
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Comparer mes simulations
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Optimiser mon assolement
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={NON} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Evaluer mes futures marges
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={NON} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={NON} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={NON} alt="" className="LogoOuiNon"/>
            </div>
          </div>
          
          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
             Support par mail
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={NON} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
            </div>
          </div>

          <div className='row enleveMarge cellBCPTextAbo'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo cellBCPTextAbo'>
              Formation
            </div>
            <div className='celluleInfoAbo ligneCorpAbo cellBCPTextAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
              <div className='paragraphe'>1h30 groupe</div>
              <div className='paragraphe'>4 à 8 pers</div>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo cellBCPTextAbo'>
              <img src={NON} alt="" className="LogoOuiNon"/>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo cellBCPTextAbo'>
              <img src={OUI} alt="" className="LogoOuiNon"/>
              <div className='paragraphe'>1h30</div>
              <div className='paragraphe'>individuelle</div>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Durée d’accès aux services
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <div className='paragraphe'>1 mois</div>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <div className='paragraphe'>12 mois</div>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <div className='paragraphe'>12 mois</div>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe ligneCorpAbo'>
              Tarif par compte
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <div className='paragraphe'>280€ HT</div>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <div className='paragraphe'>490€ HT</div>
            </div>
            <div className='celluleInfoAbo ligneCorpAbo'>
              <div className='paragraphe'>690€ HT</div>
            </div>
          </div>

          <div className='row enleveMarge'> 
            <div className='enteteLigneAbo paragraphe'>
              
            </div>
            <div className='celluleInfoAbo'>
              <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#92D050', width:'110%'}} onClick={handleCompteF}>Souscrire</button></div>
            </div>
            <div className='celluleInfoAbo'>
              <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#75B532', width:'110%'}} onClick={handleCompteE}>Souscrire</button></div>
            </div>
            <div className='celluleInfoAbo'>
              <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#4F7D1D', width:'110%'}} onClick={handleCompteP}>Souscrire</button></div>  
            </div>
          </div>
        </div>

        {/* <div className='tableauAbo row' style={{marginTop:'0px'}}>
          <img src={logoFlash} alt="" className="LogoOffres" style={{width:'50px', marginRight:'20px'}}/>
          <div className='paragraphe'>
            <div style={{fontWeight:'bold'}}>Offre Flash du 16 octobre 2023 au 28 février 2024 - Réforme de la Gestion des Risques : </div>
            <div>Souscrire une Assurance Récolte ou pas ? Si oui, quelles options ? Sinon, quelles alternatives ?</div>
          </div>
        </div> */}

        <div className='row' >
          <div className='card cardAbo cardAboOF'>
            <div className='titrePlus'  style={{marginTop:'0px', marginBottom:'10px'}}>
              OF
            </div>
            <div className='paragraphe paraCardAbo'> Vous souhaitez proposer des formations en présentiel ou distanciel, éligibles VIVEA ? <span style={{color:'white'}}>______ _____________ ____________ ____</span></div>
            <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#4B4BB5'}} onClick={handleContacter}>Nous contacter</button></div>
          </div>
          <div className='card cardAbo cardAboAssureur'>
            <div className='titrePlus'  style={{marginTop:'0px', marginBottom:'10px'}}>
              Assureur
            </div>
            <div className='paragraphe paraCardAbo'>Vous souhaitez comparer votre portefeuille à notre modèle actuariel ? <span style={{color:'white'}}>______________ __________ _____________ __________________</span></div>
            <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#37376A'}} onClick={handleContacter}>Nous contacter</button></div>
          </div>
          <div className='card cardAbo cardAboOPA'>
            <div className='titrePlus'  style={{marginTop:'0px', marginBottom:'10px'}}>
              OPA
            </div>
            <div className='paragraphe paraCardAbo'>Vous souhaitez mieux évaluer vos risques climatiques aujourd'hui et en 2050 ? Proposer des stratégies d’adaptation ?</div>
            <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#24243E'}} onClick={handleContacter}>Nous contacter</button></div>
          </div>
        </div>

      </div>
      <FooterSW/>
    </div>
  );
}

export default Abonnement;

