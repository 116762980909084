import React, {useEffect} from 'react'
import apiLink from '../../config.js'
import TitreRapport from './titre_rapport'
import Graph3Vagues from '../OAD/Graphiques/Troisvagues'

function PageRiskRDT({idExploit}) {
  const [dataPerso, setDP]= React.useState();
  const [dataVagues7, setDV7]= React.useState();
  const [dataVagues20, setDV20]= React.useState();
  const [dataVagues50, setDV50]= React.useState();
  const [Risques7, setR7] = React.useState(0)
  const [Risques20, setR20] = React.useState(0)
  const [Risques50, setR50] = React.useState(0)
  const [maximum, setmax]= React.useState();
  const [dataQ1, setDQ1]= React.useState();
  const [dataQ2, setDQ2]= React.useState();
  const [dataQ3, setDQ3]= React.useState();
  const [data25Co, setData25Co]= React.useState();
  const [data25E, setData25E]= React.useState();
  const [data25Ca, setData25Ca]= React.useState();
  const [data50Co, setData50Co]= React.useState();
  const [data50E, setData50E]= React.useState();
  const [data50Ca, setData50Ca]= React.useState();
  const [data75Co, setData75Co]= React.useState();
  const [data75E, setData75E]= React.useState();
  const [data75Ca, setData75Ca]= React.useState();

  const [Taille, setTaille]= React.useState();
  
  useEffect ( () => {
    fetch(apiLink + 'page/optimisation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setR7(response.risques7)
      setR7(prev => {
      return prev;
      })
      setR20(response.risques20)
      setR20(prev => {
      return prev;
      })
      setR50(response.risques50)
      setR50(prev => {
      return prev;
      })
    }) 
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      let data = response
      var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
        fetch(apiLink + 'page/risquerendement/' + idExploit +"/2")
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          setDP(response.dataPerso)
          setDP(prev => {
            return prev;
          })
          setDV7(response.dataVagues7)
          setDV7(prev => {
            setData25Co(prev[Math.round(prev.length/4)].y)
            setData50Co(prev[Math.round(prev.length/2)].y)
            setData75Co(prev[Math.round(prev.length*3/4)].y)
            return prev;
          })
          setDV20(response.dataVagues20)
          setDV20(prev => {
            setData25E(prev[Math.round(prev.length/4)].y)
            setData50E(prev[Math.round(prev.length/2)].y)
            setData75E(prev[Math.round(prev.length*3/4)].y)
            return prev;
          })
          setDV50(response.dataVagues50)
          setDV50(prev => {
            setData25Ca(prev[Math.round(prev.length/4)].y)
            setData50Ca(prev[Math.round(prev.length/2)].y)
            setData75Ca(prev[Math.round(prev.length*3/4+50)].y)
            setmax(prev[prev.length-2].y+20)
            setDQ1([{x:prev.length/4, y:0}, {x:prev.length/4, y:80}])
            setDQ2([{x:prev.length/2, y:0}, {x:prev.length/2, y:80}])
            setDQ3([{x:prev.length*3/4, y:0}, {x:prev.length*3/4, y:80}])
            setTaille(prev.length)
            return prev;
          })
        })
      }
      else {
        fetch(apiLink + 'page/risquerendement/' + idExploit)
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          setDP(response.dataPerso)
          setDP(prev => {
            return prev;
          })
          setDV7(response.dataVagues7)
          setDV7(prev => {
            setData25Co(prev[Math.round(prev.length/4)].y)
            setData50Co(prev[Math.round(prev.length/2)].y)
            setData75Co(prev[Math.round(prev.length*3/4)].y)
            return prev;
          })
          setDV20(response.dataVagues20)
          setDV20(prev => {
            setData25E(prev[Math.round(prev.length/4)].y)
            setData50E(prev[Math.round(prev.length/2)].y)
            setData75E(prev[Math.round(prev.length*3/4)].y)
            return prev;
          })
          setDV50(response.dataVagues50)
          setDV50(prev => {
            setData25Ca(prev[Math.round(prev.length/4)].y)
            setData50Ca(prev[Math.round(prev.length/2)].y)
            setData75Ca(prev[Math.round(prev.length*3/4)].y)
            setmax(prev[prev.length-1].y+20)
            setDQ1([{x:prev.length/4, y:0}, {x:prev.length/4, y:80}])
            setDQ2([{x:prev.length/2, y:0}, {x:prev.length/2, y:80}])
            setDQ3([{x:prev.length*3/4, y:0}, {x:prev.length*3/4, y:80}])
            setTaille(prev.length)
            return prev;
          })
        }) 
      }
    }) 
    
  }, [idExploit])
  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Vulnérabilité '} Sous_Titre={'Risques liés au rendement'} Page={'2'}/> 
      <div style={{fontSize:"20px"}}> Une comparaison aux {Taille} exploitations spécialisées en grandes cultures, représentatives des régions agricoles françaises. Résultats sont représentés en % de pertes par rapport au produit brut.</div>
      <div className='paragraphe' style={{marginBottom:'10px'}} > <span style={{fontWeight:'1000px', fontSize:'30px', color:'red'}}>---</span> Le risque de votre exploitation rapport aux exploitations moyennes </div>
      <div style={{width:'1050px'}}><div><Graph3Vagues dataPerso={dataPerso} dataVagues7={dataVagues7} dataVagues20={dataVagues20} dataVagues50={dataVagues50} maximum={maximum} dataQ1={dataQ1} dataQ2={dataQ2} dataQ3={dataQ3} /></div></div>

      <div className='tableau_rendement_rapport'> 
        <div className='row_tableau_prix_rapport'>
          <div className='ordonnée_tableau_rdt_rapport titre_col_rdt_rapport' style={{borderColor:'transparent'}}> </div>
          <div className='data_tableau_rdt_rapport titre_col_rdt_rapport' style={{fontWeight:'bold',textAlign:'center'}}> 25% les moins exposées </div>
          <div className='data_tableau_rdt_rapport titre_col_rdt_rapport' style={{fontWeight:'bold',textAlign:'center'}} > 50% </div>
          <div className='data_tableau_rdt_rapport titre_col_rdt_rapport' style={{fontWeight:'bold',textAlign:'center'}}> 25% les moins exposées </div>
          <div className='data_tableau_rdt_rapport vous_tableau_rdt_rapport titre_col_rdt_rapport' style={{fontWeight:'bold'}}> Vous </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='ordonnée_tableau_rdt_rapport' style={{backgroundColor:'#DB04B9'}}> Catastrophique </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data25Ca)}% </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data50Ca)}% </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data75Ca)}% </div>
          <div className='data_tableau_rdt_rapport vous_tableau_rdt_rapport'> {Math.round(Risques50)}% </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='ordonnée_tableau_rdt_rapport' style={{backgroundColor:'#0027CC'}}> Exceptionnel </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data25E)}% </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data50E)}% </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data75E)}% </div>
          <div className='data_tableau_rdt_rapport vous_tableau_rdt_rapport'> {Math.round(Risques20)}%  </div>
          
        </div>
        <div className='row_tableau_prix_rapport'>
        <div className='ordonnée_tableau_rdt_rapport' style={{backgroundColor:'#FFC000'}}> Courant </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data25Co)}% </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data50Co)}% </div>
          <div className='data_tableau_rdt_rapport'> {Math.round(data75Co)}% </div>
          <div className='data_tableau_rdt_rapport vous_tableau_rdt_rapport'> {Math.round(Risques7)}%  </div>
          
        </div>
      </div>
      
      
      <div className='commentaireRapport'>
        <div style={{fontWeight:'bold', marginBottom:'5px'}}>Commentaire : </div>
        <div> {Math.round(Risques7) <= Math.round(data25Co) ? 
              "Pour le risque courant vous faites partie du quart des exploitations les moins exposées" : 
              Math.round(Risques7) < Math.round(data50Co) ? 
              "Pour le risque courant vous faites partie des exploitations entre les 25% les moins exposées et la moyenne " :
              Math.round(Risques7) === Math.round(data50Co) ? 
              "Pour le risque courant vous êtes proche de la moyenne des exploitations " :
              Math.round(Risques7) <= Math.round(data75Co) ? 
              "Pour le risque courant vous faites partie des exploitations entre la moyenne et les 25% les plus exposées " : 
              "Pour le risque courant vous faites partie du quart des exploitations les plus exposées"}</div>
        <div> {Math.round(Risques20) <= Math.round(data25E) ? 
              "Pour le risque exceptionnel vous faites partie du quart des exploitations les moins exposées" : 
              Math.round(Risques20) < Math.round(data50E) ? 
              "Pour le risque exceptionnel vous faites partie des exploitations entre les 25% les moins exposées et la moyenne " :
              Math.round(Risques20) === Math.round(data50E) ? 
              "Pour le risque exceptionnel vous êtes proche de la moyenne des exploitations " :
              Math.round(Risques20) <= Math.round(data75E) ? 
              "Pour le risque exceptionnel vous faites partie des exploitations entre la moyenne et les 25% les plus exposées " : 
              "Pour le risque exceptionnel vous faites partie du quart des exploitations les plus exposées"}</div>
        <div> {Math.round(Risques50) <= Math.round(data25Ca) ? 
              "Pour le risque catastrophique vous faites partie du quart des exploitations les moins exposées" : 
              Math.round(Risques50) < Math.round(data50Ca) ? 
              "Pour le risque catastrophique vous faites partie des exploitations entre les 25% les moins exposées et la moyenne " :
              Math.round(Risques50) === Math.round(data50Ca) ? 
              "Pour le risque catastrophique vous êtes proche de la moyenne des exploitations " :
              Math.round(Risques50) <= Math.round(data75Ca) ? 
              "Pour le risque catastrophique vous faites partie des exploitations entre la moyenne et les 25% les plus exposées " : 
              "Pour le risque catastrophique vous faites partie du quart des exploitations les plus exposées"}</div>
      </div>
      
    </div>
  )
}

export default PageRiskRDT