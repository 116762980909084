import React from 'react'
import {
    XAxis,
    VerticalGridLines,
    HorizontalBarSeries,
    FlexibleXYPlot, 
    LabelSeries
  } from "react-vis";

function GrapheIR1({dataIR1}) {
    const labelData = dataIR1.map((d) => ({
        x: d.x ,
        y: d.y
      }));
    return (
        <div style={{marginLeft:"-50px"}}> 
            <FlexibleXYPlot  width={600} height={300}  colorType="literal" >
                <VerticalGridLines  style={{stroke: '#C6C6C6'}}  />
                <XAxis />
                <HorizontalBarSeries stroke={'transparent'}  data={dataIR1} barWidth={0.9} />
                <LabelSeries data={labelData} getLabel={d => d.x + ' €'} />
            </FlexibleXYPlot>
        </div>
    )
}

export default GrapheIR1