import React, {useState} from 'react'
import QST from '../../assets/question.png'

function ButtonQuestions() {
    const handleMail = () => {
        const email = 'contact@diagorisk.com';
        window.location.href = `mailto:${email}`;
    }
    const [texteVisible, setTexteVisible] = useState(false);

    const afficherTexte = () => {
        setTexteVisible(true);
    };

    const cacherTexte = () => {
        setTexteVisible(false);
    }
    const styleTexte = {
        width:'250%',
        position: 'absolute',
        top: '-60px', // Ajustez cette valeur pour la position verticale
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#fff',
        border:'solid',
        borderWidth:'1px',
        borderColor:'#A7A7A7',
        color:'#8B8B8B',
        padding: '5px',
        display: texteVisible ? 'block' : 'none',
      };

    return (
    <div className='ButtonQuestions '>
        <div style={styleTexte}>Une question ? Contactez-nous</div>
        <button className='ButtonQuestions ' onClick={handleMail} onMouseEnter={afficherTexte} onMouseLeave={cacherTexte}>
            <img src={QST} className="IconHome" alt=''/>
        </button>
    </div>
    )
}

export default ButtonQuestions