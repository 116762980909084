import React from "react";
import ReactEcharts from "echarts-for-react";

  function GraphOptiFinanciere({data, print, minX, minY, maxX, maxY, cout}) { 
    const getOptions = () => {
      let options = {
        legend: {
          left: '12%', // Place la légende au centre horizontalement
          bottom: '0%', // Place la légende en dessous du graphique
          data: ['Sans assurance', 'Sans option', 'Rachat prix 60%', 'Rachat prix 110%', 'Rachat prix 120%', 'Rachat rendement 90%', 'Rachat rendement 105%', 'Rachat rendement 110%', 'Rachat rendement 110%, rachat prix 120%'],
          textStyle: {
            fontSize: 18 // Ajustez la taille de police selon vos préférences
          },
          selected: {
            // Définissez ici les séries que vous souhaitez masquer par défaut
            'Sans assurance':true,
            'Sans option':true,
            'Rachat prix 60%': print,
            'Rachat prix 110%': print,
            'Rachat prix 120%': print,
            'Rachat rendement 90%': print,
            'Rachat rendement 105%': print,
            'Rachat rendement 110%': print,
            'Rachat rendement 110%, rachat prix 120%':print
          },
          
        },
        graphic: [
          {
            type: "text",
            left: "70%",
            top: "10%",
            z: 100,
            style: {
              fill:"black",
              fontSize: 17,
              text: "L'assurance coûte",
            },
          },
          // Ajouter du texte dans le coin inférieur gauche
          {
            type: "text",
            left: "13%",
            bottom: "22%",
            z: 100,
            style: {
              fill:"black",
              fontSize: 17,
              text: "L'assurance 'rapporte' ",
            },
          },
        ],
        color : ['#7BC361','#8CFFFD','#00FEF9','#11AFAC','#FF86FD','#FF0BFB','#BC00A0','#870BFC'],
        grid: {
          left: '12%',
          right:'15%',
          top: '5%',
          bottom: '20%' 
        },
        xAxis: {
          name: cout==='CN' ? 'Coût net' : 'Coût brut',
          nameLocation: 'middle',
          nameGap: 30,
          nameTextStyle: {
            fontSize: 17 
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            fontSize: 17 
          },
          type: 'value',
          min : minX,
          max : maxX,
          interval: 2,
        },
        yAxis: {
          name: 'Reste à charge moyen en cas de pertes',
          nameLocation: 'middle',
          nameGap: 30,
          nameTextStyle: {
            fontSize: 17 
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            fontSize: 17 
          },
          type: 'value',
          min : minY,
          max : maxY,
          interval: 2,
        },
        series: [
          {name: 'Sans assurance',
            data: data[0],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " +' C : ' + param.data[0] + '%' + " /" +' RAC : ' + param.data[1] + '%'  ;
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,
              color: 'red'
              
            }
          },
          {name: 'Sans option',
            data: data[1],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " +' C : ' + param.data[0] + '%' + " /" +' RAC : ' + param.data[1] + '%' ;
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
              
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(120, 36, 50, 0.5)',
              shadowOffsetY: 5,
              color: function (params) {
                // Vérifier si la quatrième valeur est 'MRC 20%'
                if (params.data[3] === 'MRC 20%') {
                  // Définir la couleur du contour pour les cercles 'MRC 20%'
                  return {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: '#FFFFFF' },
                      { offset: 1, color: '#7BC361' },
                    ],
                  };
                } else {
                  // Utiliser la couleur normale pour les autres cercles
                  return '#7BC361';
                }
              },
            }
          },
          {name: 'Rachat prix 60%',
            data: data[2],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " +' C : ' + param.data[0] + '%' + " /" +' RAC : ' + param.data[1] + '%' ;
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,
              color: function (params) {
                // Vérifier si la quatrième valeur est 'MRC 20%'
                if (params.data[3] === 'MRC 20%') {
                  // Définir la couleur du contour pour les cercles 'MRC 20%'
                  return {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: '#FFFFFF' },
                      { offset: 1, color: '#8CFFFD' }, 
                    ],
                  };
                } else {
                  // Utiliser la couleur normale pour les autres cercles
                  return '#8CFFFD';
                }
              },
            }
          },
          {name: 'Rachat prix 110%',
            data: data[3],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " +' C : ' + param.data[0] + '%' + " /" +' RAC : ' + param.data[1] + '%' ;
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,
              color: function (params) {
                // Vérifier si la quatrième valeur est 'MRC 20%'
                if (params.data[3] === 'MRC 20%') {
                  // Définir la couleur du contour pour les cercles 'MRC 20%'
                  return {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: '#FFFFFF' },
                      { offset: 1, color: '#00FEF9' }, 
                    ],
                  };
                } else {
                  // Utiliser la couleur normale pour les autres cercles
                  return '#00FEF9';
                }
              },
            }
          },
          {name: 'Rachat prix 120%',
            data: data[4],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " +' C : ' + param.data[0] + '%' + " /" +' RAC : ' + param.data[1] + '%' ;
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,
              color: function (params) {
                // Vérifier si la quatrième valeur est 'MRC 20%'
                if (params.data[3] === 'MRC 20%') {
                  // Définir la couleur du contour pour les cercles 'MRC 20%'
                  return {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: '#FFFFFF' },
                      { offset: 1, color: '#11AFAC' }, 
                    ],
                  };
                } else {
                  // Utiliser la couleur normale pour les autres cercles
                  return '#11AFAC';
                }
              },
            }
          },
          {name: 'Rachat rendement 90%',
            data: data[5],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " +' C : ' + param.data[0] + '%' + " /" +' RAC : ' + param.data[1] + '%' ;
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,
              color: function (params) {
                // Vérifier si la quatrième valeur est 'MRC 20%'
                if (params.data[3] === 'MRC 20%') {
                  // Définir la couleur du contour pour les cercles 'MRC 20%'
                  return {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: '#FFFFFF' },
                      { offset: 1, color: '#FF86FD' }, 
                    ],
                  };
                } else {
                  // Utiliser la couleur normale pour les autres cercles
                  return '#FF86FD'; 
                }
              },
              
            }
          },
          {name: 'Rachat rendement 105%',
            data: data[6],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " +' C : ' + param.data[0] + '%' + " /" +' RAC : ' + param.data[1] + '%' ;
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,
              color: function (params) {
                // Vérifier si la quatrième valeur est 'MRC 20%'
                if (params.data[3] === 'MRC 20%') {
                  // Définir la couleur du contour pour les cercles 'MRC 20%'
                  return {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: '#FFFFFF' },
                      { offset: 1, color: '#FF0BFB' },  
                    ],
                  };
                } else {
                  // Utiliser la couleur normale pour les autres cercles
                  return '#FF0BFB'; 
                }
              },
              
            }
          },
          {name: 'Rachat rendement 110%',
            data: data[7],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " +' C : ' + param.data[0] + '%' + " /" +' RAC : ' + param.data[1] + '%' ;
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,
              color: function (params) {
                // Vérifier si la quatrième valeur est 'MRC 20%'
                if (params.data[3] === 'MRC 20%') {
                  // Définir la couleur du contour pour les cercles 'MRC 20%'
                  return {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: '#FFFFFF' },
                      { offset: 1, color: '#BC00A0' }, 
                    ],
                  };
                } else {
                  // Utiliser la couleur normale pour les autres cercles
                  return '#BC00A0'; 
                }
              },
              
            }
          },
          {name: 'Rachat rendement 110%, rachat prix 120%',
            data: data[8],
            type: 'scatter',
            symbolSize: function (data) {
              return data[2]
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3] + " : " + `\n` +' C : ' + param.data[0] + '%' + `\n` +' RAC : ' + param.data[1] + '%' + `\n` +' R : ' + param.data[2] + '%';
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,
              color: function (params) {
                // Vérifier si la quatrième valeur est 'MRC 20%'
                if (params.data[3] === 'MRC 20%') {
                  // Définir la couleur du contour pour les cercles 'MRC 20%'
                  return {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: '#FFFFFF' },
                      { offset: 1, color: '#870BFC' }, 
                    ],
                  };
                } else {
                  // Utiliser la couleur normale pour les autres cercles
                  return '#870BFC'; 
                }
              },
            }
          },
          
          

          
          {name: 'Droite de rentabilité',
            type: 'line', // Utilisez le type "line" pour une série de type droite
            data: [[0, data[0][0][1]], [data[0][0][1], 0]], // Remplacez x1, y1, x2, y2 par les coordonnées de vos deux points
            lineStyle: {
              color: 'black', // Couleur de la droite
              width: 2, // Épaisseur de la ligne
              type: 'solid', // Type de ligne ('solid', 'dashed', 'dotted', etc.)
            },
            symbol: ['none', 'none'], // Désactive les symboles aux extrémités de la droite
          },
        ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom:"20px", width: print ? '100%': '100%', height: print ? '650px': '700px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphOptiFinanciere;
  
