import React from 'react';
import Nav from '../MEP/SWNav';
import EnteteSW from '../MEP/enteteSW';
import FooterSW from '../MEP/footer_SW';
import BlogContainer from './blogContainer';
import blog1 from '../../assets/blog_1.jpg'
import blog2 from '../../assets/blog_2.jpg'
import blog3 from '../../assets/blog_3.jpg'
import blog4 from '../../assets/blog_4.jpg'
import blog5 from '../../assets/Lancement.png'

function Blog() {
  return (
    <div>
      <Nav/>
      <EnteteSW titre="Les dernières nouvelles"/>
      <div className="containerSW">
      <BlogContainer 
            titre={"Nouvelles formation en ligne !"} 
            texte1={"Jour J du lancement des nouvelles formations de DiagoRisk !"} 
            texte2={"Comment profiter au mieux de la réforme de l’assurance récolte ? Comment mieux gérer ses risques climatiques ? Les 2 atouts clés de cette formation : un chiffrage personnalisé du risque, la possibilité de se comparer et du coût de l'assurance Récolte."} 
            texte3={"Grâce à notre OAD DiagoRisk, un code postal et un assolement suffisent pour avoir les 1ers résultats ! Inscrivez-vous !"} 
            image={blog5} 
            date={"16/10/2023"}/>
      <BlogContainer 
            titre={"DiagoRisk aux LFDays"} 
            texte1={"Rendez-vous le 13 juin au #LFDay2023 avec La Ferme Digitale"} 
            texte2={""} 
            texte3={""} 
            image={blog4} 
            date={"12/05/2023"}/>
        <BlogContainer 
            titre={"« Se préparer à un #réchauffement à +4°C », telle est l’injonction du Ministre de la Transition Ecologique, Christophe Béchu."} 
            texte1={"Loin d’être un simple exercice, ces simulations changent radicalement les perspectives. Pour la viticulture, à +2°C, le #Bordeaux et le #Cognac se sentent épargnés ou largement en capacité de faire face à l'augmentation du coût des aléas climatiques (cf. page2). La #Champagne voit de son exposition aux risques climatiques diminuer. 🍾"} 
            texte2={"A +4°C, tous les vignobles sont impactés, à des niveaux qui remettent en cause leur rentabilité, voir leur viabilité. Certaines zones peuvent être victimes de véritables surprises avec des inversions brutales de perspectives (exemple dans le #valdeloire). L'enjeu est donc de définir comment s'adapter pour faire face au #réchauffement qu'il soit de +2°C ou à 4°C."} 
            texte3={"Nos simulations, prudentes, portent sur 80% des cépages et des communes, avec les mêmes réserves que dans celles situées dans le post précédent. Olivier Zebic"} 
            image={blog3} 
            date={"27/04/2023"}/>
      <BlogContainer 
            titre={"Quels coûts du réchauffement climatique en 2050 pour la #viticulture ?"} 
            texte1={"Avec Olivier Zebic, nous développons une approche originale pour chiffrer la perte de rendement par cépage et par commune (« toutes choses égales par ailleurs », hors bioagresseurs et effet qualitatif).A 🌡 + 2°C, nous identifions 4 France viticoles selon les pertes : 1. #adaptation urgente :  les rendements moyens sont amputés de +10% voir +15%. Pourtour méditerranéen et Nord-Est de l’Occitanie 2. Stable à élevé (autour de 5%):  l'augmentation s'ajoute à un niveau déjà élevé; la viticulture se rapproche de l’arboriculture aujourd’hui. Beaujolais – Bourgogne - Alsace 3. Bigarrés : Bordeaux - Cognac - Val de Loire Aval - à affiner selon les terroirs 4. Favorable :  Champagne – Nord-Est du Val de Loire"} 
            texte2={"Ce chiffrage aide à mesurer les efforts d’adaptation à envisager et à étaler les investissements dans le temps. Il conduit aussi à évaluer l’efficacité des solutions disponibles, par exemple, bien choisir ses nouveaux cépages."} 
            texte3={"Ce que cache cette carte : • La substitution entre aléas : moins de gel, mais des coups de chaleur chroniques par exemple • des rendements qui deviennent plus fluctuants • Les impacts qualitatifs et sanitaires • Une dégradation qui n’est pas régulière dans le temps • 😨 AU DELA DE +2°C, beaucoup de observationss sont à revoir ..."} 
            image={blog2} 
            date={"22/02/2023"}/>
        <BlogContainer 
            titre={"Risques et coûts liés à la précocité de la vigne"} 
            texte1={"Exemple à Chablis (89) : la précocité augmente le nombre de jour pendant lesquels les bourgeons et les inflorescences en formation sont exposés. Comme ils sont gorgés d'eau, ils sont plus aussi vulnérables.A ce jour et vu la période de froid annoncée, difficile de prévoir où en sera la végétation dans 2 mois. Plus le froid (modéré) durera, mieux se sera."} 
            texte2={"Avec Olivier Zebic, nous avons développé une méthodologie et un outil pour chiffrer l’impact du changement climatique sur les cultures, notamment en termes de coûts et de risques associés (rendement, « qualité » etc.). Sur vigne, nous avons pris en compte les spécificités des cépages. Nous nous sommes appuyés sur les modélisations du GIEC (DRIAS-MétéoFrance), de nombreuses références INRAE et IFV et des approches assurantielles."} 
            texte3={""} 
            image={blog1} 
            date={"17/01/2023"}/>
      </div>
      <FooterSW/>
    </div>
  );
}

export default Blog;

