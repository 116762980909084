import React, {useEffect} from 'react';
import apiLink from '../../config.js'
import {useLocation, Navigate} from 'react-router-dom'
import img from '../../assets/opti_img.jpg'

import ButtonVidéos from '../MEP/buttonVidéo.js';
import Entete from '../MEP/entete'
import OADMenu from '../MEP/oadMenu'
import ButtonQuestions from '../MEP/button question.js';

import GraphOptiFinanciere from './Graphiques/Graphe_opti_financiere.js';

function Optimisation2() {
  const { state } = useLocation();
  const { idExploit } = state;
  const idAgent = JSON.parse(localStorage.getItem('idAgent'));
  const role = JSON.parse(localStorage.getItem('role'));
  const formule = JSON.parse(localStorage.getItem('formule'));
  const [goToHome, setGoToHome] = React.useState(false)

  const [dataGraphCN, setDataGraphCN] = React.useState([
    [
      [0, 35, 17, 'Sans ass', 'Sans assurance']
    ],
    [
      [1, 14, 61, 'MRC 20%','Sans option' ],
      [0.4, 19, 49,'MRC 25%' ,'Sans option' ],
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 60%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 60%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 110%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 110%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 120%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 120%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 90%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 90%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 105%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 105%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 110%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 110%']
    ],
    [
      [1.25, 11, 68, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
      [0.65, 14, 55, 'MRC 25%', 'Rachat rendement 110%, rachat prix 120%'],
      
    ],    
  ])
  const [dataGraphCB, setDataGraphCB] = React.useState([
    [
      [0, 35, 17, 'Sans ass', 'Sans assurance']
    ],
    [
      [1, 14, 61, 'MRC 20%','Sans option' ],
      [0.4, 19, 49,'MRC 25%' ,'Sans option' ],
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 60%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 60%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 110%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 110%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 120%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 120%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 90%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 90%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 105%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 105%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 110%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 110%']
    ],
    [
      [1.25, 11, 68, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
      [0.65, 14, 55, 'MRC 25%', 'Rachat rendement 110%, rachat prix 120%'],
      
    ],    
  ])
  const [cout, setCout] = React.useState('CN')
  const [PBTot, setPBTot] = React.useState(0)
  const [GoToTarif,setGoToTarif] = React.useState(false)

  function shapeData(dataGraph){
    return [
      [
        [100*Math.round(dataGraph['Sans Assurance'][0]*1000)/1000, 100*Math.round(dataGraph['Sans Assurance'][1]*1000)/1000, 100*Math.round(dataGraph['Sans Assurance'][2]*1000)/1000, 'Sans ass', 'Sans assurance']
      ],
      [
        [100*Math.round(dataGraph['MRC 20%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20%'][2]*1000)/1000, 'MRC 20%','Sans option' ],
        [100*Math.round(dataGraph['MRC 25%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25%'][2]*1000)/1000,'MRC 25%' ,'Sans option' ],
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 60% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 60% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 60% '][2]*1000)/1000, 'MRC 20%','Rachat prix 60% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 60% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 60% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 60% '][2]*1000)/1000, 'MRC 25%','Rachat prix 60% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 110% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 110% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 110% '][2]*1000)/1000, 'MRC 20%','Rachat prix 110% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 110% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 110% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 110% '][2]*1000)/1000, 'MRC 25%','Rachat prix 110% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 120% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 120% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 120% '][2]*1000)/1000, 'MRC 20%','Rachat prix 120% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 120% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 120% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 120% '][2]*1000)/1000, 'MRC 25%','Rachat prix 120% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 90%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 90%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 90%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 90%'],
        [100*Math.round(dataGraph['MRC 25% + Rendement 90%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 90%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 90%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 90%']
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 105%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 105%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 105%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 105%'],
        [100*Math.round(dataGraph['MRC 25% + Rendement 105%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 105%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 105%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 105%']
      ], 
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 110%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 110% '],
        [100*Math.round(dataGraph['MRC 25% + Rendement 110%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110%'][2]*1000)/1000, 'MRC 25% ','Rachat rendement 110% ']
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
        [100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 110%, rachat prix 120%'],
        
      ],
    ]
  }

 useEffect ( () => {
  fetch(apiLink + 'page/optimisation/' + idExploit)
  .then( (response) => {
    return response.json()
  })
  .then(response =>{
    if (response !== "Exploitaiton " + idExploit + " non trouvée") {
      setDataGraphCN(shapeData(response.dataGraphCN))
      setDataGraphCN(prev => {
      return prev;
      })
      setDataGraphCB(shapeData(response.dataGraphCB))
      setDataGraphCB(prev => {
      return prev;
      })
    } else {
      localStorage.clear()
      setGoToHome(true)
    }
  }) 
  fetch(apiLink + 'page/exploitation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        setPBTot(response.PBTotal)
        setPBTot(prev => {
          return prev;
        })
      } else {
                localStorage.clear()
        setGoToHome(true)
      }
    }) 
  }, [idExploit])

  function handleSubmitCN(){
    setCout('CN')
  }
  function handleSubmitCB(){
    setCout('CB')
  }

  function handleGoToTarifs(){
    setGoToTarif(true)
  }

  const minX= 0
  const minY = 0
  const maxX = Math.round(dataGraphCB[8][0][0])%2 === 0 ? Math.round(dataGraphCB[8][0][0]) + 2 : Math.round(dataGraphCB[8][0][0]) +1 
  const maxY = Math.round(dataGraphCB[0][0][1])%2 === 0 ? Math.round(dataGraphCB[0][0][1]) + 2 : Math.round(dataGraphCB[0][0][1]) +1 

  if(GoToTarif) {
    return <Navigate push to={`/abonnements`} />
  }
  if(goToHome) {
    return <Navigate push to={`/`} />
  }

  return (
    <div >
      <Entete titre="Optimisation"/>
      {role!==null ? ( 
        <div className='container'>
        <div className='titrePlusVideo'>
          <div className='titre' style={{marginTop:('15px')}}> Comment choisir mon assurance ?  </div>
          <ButtonVidéos link={'https://www.youtube.com/watch?v=Wr-Hh0mxGlU&ab_channel=DiagoRisk'}/>
        </div>
        <div className='rowResiScalePrincipal' style={{alignItems:'flex-start'}}>
            <div className='optiLeft'>
                <div className='paragraphe' style={{marginBottom:'10px'}}> Sur le graphique, données sont calculées pour un risque moyen :  </div>
                <ul>
                  <li> <div className='lila paragraphe'>  À l’horizontal, le coût de l’assurance en % du produit brut </div> </li>
                  <ul>
                    <li> <div className='lila'>  <span style={{fontWeight:'bold'}}> Coût brut = </span> montant annuel de la prime d'assurance </div> </li>
                    <li> <div className='lila'>  <span style={{fontWeight:'bold'}}>Coût net = </span> montant annuel de la prime  - les subventions </div> </li>
                  </ul>
                  <li> <div className='lila paragraphe'>  A la verticale, le <span style={{fontWeight:'bold'}}>reste à charge</span> après indemnisation, calculé en moyenne et en % du produit brut </div> </li>
                  
                </ul>

                <div className='paragraphe' style={{marginBottom:'20px'}}> Les rond avec un <span style={{fontWeight:'bold'}}> intérieur blanc </span>correspondent à l'assurance <span style={{fontWeight:'bold'}}>MRC 20%</span>, ceux <span style={{fontWeight:'bold'}}> sans</span> correspondent à l'assurance <span style={{fontWeight:'bold'}}>MRC 25%</span>. </div>

                <div className='paragraphe' style={{marginBottom:'10px'}}> Pour lire les détails, passer sur le point avec la souris : </div>
                <div className='paragraphe' > <span style={{fontWeight:'bold'}}>RAC = </span> reste à charge moyen après indemnisation = <b> risque moyen - indemnisations</b></div>
                <div className='paragraphe' > <span style={{fontWeight:'bold'}}>C = </span>coût net ou coût brut  </div>
                

                <div className='row' >
                    <div style={{width:'30%', marginRight:'10px'}}>
                        <button onClick={handleSubmitCN} className={cout==='CN'? 'validationCout validationDefaut' : 'validationCout validationClicked'}> Coût net </button> 
                    </div>
                    <div style={{width:'30%', marginLeft:'10px'}}>
                        <button onClick={handleSubmitCB} className={cout==='CB'? 'validationCout validationDefaut' : 'validationCout validationClicked'}> Coût brut</button> 
                    </div>
                </div>
            </div>
            <div className='optiRight'>
              <div className='titreMoins row' style={{marginTop:'0px', marginBottom:'0px'}}> Ratio coût-sécurité (en % de produit brut) </div>
              <div className='titreMoins row' style={{marginTop:'0px', marginBottom:'0px', fontSize:"21px", fontWeight:'normal'}}> Plus le reste à charge est faible plus je suis sécurisé </div>
              <div className='paragraphe row' style={{marginTop:'5px', marginBottom:'0px'}}> Produit brut de référence : {PBTot.toLocaleString()}€ / Risque moyen : {dataGraphCN[0][0][1]} % </div> 
                <GraphOptiFinanciere data={cout === 'CN' ? dataGraphCN : dataGraphCB} print={false} minX={minX} minY={minY} maxX={maxX} maxY={maxY} cout={cout}/>
            </div>
        </div>
      </div>
      )
      :(
        <div className='container'>
          <div className='row'>
            <div style={{width:'50%'}}>
              <div className='titrePlus'> Page réservée aux formules Premium et Essentielle </div>
              <div className='paragraphe' > Pour consulter cette page et pleins d'autres éléments, souscrivez ! </div>
              <div className='row' >
                  <button className='validation' onClick={handleGoToTarifs} > Voir les offres  </button>
              </div> 
            </div>
            <div style={{width:'45%'}}>
            <img src={img} alt="" style={{width:'100%'}}/>
            </div>
          </div>
        </div>
      )}
      <ButtonQuestions/>
      <OADMenu idExploit={idExploit}/>
    </div>
  );
}

export default Optimisation2;
