import React from 'react';
import Entete from '../../MEP/entete'
import {Navigate} from 'react-router-dom'

function InscriptionValidation() {
    const [Code, setCode]= React.useState('');
    const [goToConnexion, setGoToConnexion] = React.useState(false)
    const [goToContact, setGoToContact] = React.useState(false)

    function handleChange(event) {
        var Code = event.target.value
        setCode(Code)
    }

    function handleSubmit(event) {
        event.preventDefault()
        setGoToConnexion(true)
    }
    function handleContacter(event){
        event.preventDefault()
        setGoToContact(true)
      }
    
    if(goToConnexion) {
        return <Navigate push to={`/connexion`} />
    }
    if(goToContact) {
        return <Navigate push to={`/nouscontacter`} />
      }

    return (
        <div>
            <Entete titre="Inscription"/>
            <div className='container inscriptionVal'>
                <div className='titreMoins'>Votre compte a été créé avec succès. </div>
                <button className='buttonNotFound' onClick={handleSubmit} > <div className='paragrapheNotFound'>Connectez-vous ! </div></button>
                 
                {/* <div>Un mail vous a été envoyé. </div>
                <div style={{marginBottom:'10px'}}>Veuillez saisir le code reçu pour valider votre compte </div>
                <form onSubmit={handleSubmit}>
                    <input 
                    className='inputCodeVal'
                    value={Code}
                    onChange={handleChange}
                    placeholder='Code'
                    type='text'
                    style={{marginBottom:'10px', }}
                    required/>
                    <div className='row'>
                        <button type='submit' className='validation'> Valider </button>
                    </div>
                </form> */}
                
            </div>
        </div>
    );
}

export default InscriptionValidation;