import React from 'react'

function TitreRapport({Titre, Sous_Titre, Page}) {
  return (
    <div className='titre_scetion_rapport'>
      <div className='row_entete_page_rapport'>
        <div>
          {Titre}
          <div style={{color:'#767171', fontSize:'24px'}}>{Sous_Titre}</div>
        </div>
          <div className='titrePDG_rapport num_page_rapport' style={{fontSize:'25px', marginRight:'30px'}}>{Page}</div>
      </div>
    </div>
  )
}

export default TitreRapport