import React, { useState, useEffect} from 'react';
import apiLink from '../../../config.js'
import axios from "axios";
import {Navigate} from 'react-router-dom'
import Culture from './culture_assol'
import CultureAjoutSpe from './culture_assol_ajout_spe';

function Assolement({city}) {
  const [idAgent, setidAgent] = useState();
  const [idExploit, setidExploit] = useState();
  const [goToExploit, setGoToExploit] = useState(false)
  const [TypeCulture, setTypeCulture] = useState("Conventionnelle")
  const [valueSpeaddOtherSpe, setValueSpeAdddOtherSpe]= useState()
  const [dataPlusCult, setdataPlusCult]= useState({})
  const [Name, setName] = useState('Principale')
  const mesDonnees = new FormData(); 
  const [dataDefaut, setDataDefaut] = useState({})
  const [data,setData]= useState({})

  const mergedata = (dataDefaut)=> {
    var data = {};

    if(dataDefaut !== undefined) {
      for (const key in dataDefaut) {
        if (dataDefaut.hasOwnProperty(key)) {
          const defautEntry = dataDefaut[key];
          data[key] = {
            libelle: defautEntry.libelle,
            SelectedSpe: "none",
            surface: defautEntry.surface,
            rendement: defautEntry.rendement,
            rendementConv: defautEntry.rendement,
            rendementBio: defautEntry.rendementBio,
            rendementIrrig: defautEntry.rendementIrrig,
            prix: defautEntry.prix === null ? "0.00" : defautEntry.prix,
            prixConv: defautEntry.prix,
            prixBio: defautEntry.prixBio,
            Specification: defautEntry.specifications,
          };
        }
      }
    }
  
    return data;
  }

  const EnvoiResultats = (data, typeCulture, dataPlusCult)=> { //permet de confirgurer les valeurs pour quelles ressortent de manière simplifiées
    var dataOutput = {};
    if(data !== undefined && dataPlusCult !== undefined) { //on vérifie que les données ont bien été récupérées par l'API
      for (const key in data) { //on fait le tour des données de l'exploitation
        if (data.hasOwnProperty(key)) {
          const dataEntry = data[key];
          const speInThisCultre = []
          for (const keyDPC in dataPlusCult) { //on fait le tour des données des culrures duppliquée
            if (dataPlusCult.hasOwnProperty(keyDPC)) {
              const selectedSpeDataPlusCult = dataPlusCult[keyDPC]['key'];
              const selectedSpeData = key;
  
              if ( // on fait une liste s'il existe d'autre spé pour chaque culture 
                selectedSpeDataPlusCult &&
                selectedSpeData &&
                selectedSpeDataPlusCult.toLowerCase() === selectedSpeData.toLowerCase()
              ) {
                speInThisCultre.push(keyDPC);
              }
            }
          }
          dataOutput[key] = {};
          dataOutput[key][dataEntry['SelectedSpe'].toString()] = {
            libelle: dataEntry.libelle,
            SelectedSpe: dataEntry.SelectedSpe,
            surface: dataEntry.surface,
            rendement: dataEntry.rendement,
            prix: dataEntry.prix,
            typeCulture: typeCulture,
          };
          if (speInThisCultre.length !== 0) {
            for (const indice in speInThisCultre) {
              const index = speInThisCultre[indice]
              const speSelectionnee = dataPlusCult[index]['SelectedSpe'].toString()
              dataOutput[key][speSelectionnee] = {
                libelle: dataPlusCult[index]['libelle'],
                SelectedSpe: dataPlusCult[index]['SelectedSpe'],
                surface: dataPlusCult[index]['surface'],
                rendement: dataPlusCult[index]['rendement'],
                prix: dataPlusCult[index]['prix'],
                typeCulture: typeCulture,
              };
            }
          }          
        }
      }
    }
    return dataOutput;
  } 

  //Fonction qui prend en entrée la même chose que la précédente et utilise la précédente pour initialiser les données puis prend aussi dataDefaut pour compléter les spé que l'on a pas 

  const EnvoiCompletResultats = (data, typeCulture, dataPlusCult, dataDefault) => {
    const dataOutPut = EnvoiResultats(data, typeCulture, dataPlusCult)
    if (dataOutPut !== undefined && dataDefault !== undefined) {
      // On vérifie que les données ont bien été récupérées par l'API
      for (const key in dataOutPut) {
        if (dataOutPut.hasOwnProperty(key)) {
          const specifications = dataDefault[key]['specifications'];
  
          if (!dataOutPut[key].hasOwnProperty('none')) {
            dataOutPut[key]['none'] = {
              libelle: dataDefault[key]['libelle'],
              SelectedSpe: 'none',
              surface: '0.00',
              rendement: '0.00',
              prix: '0.00',
              typeCulture: 'Conventionnelle'
            };
          }
  
          for (const specKey in specifications) {
            if (specifications.hasOwnProperty(specKey)) {
              if (!dataOutPut[key].hasOwnProperty(specKey)) {
                dataOutPut[key][specKey] = {
                  libelle: dataDefault[key]['libelle'],
                  SelectedSpe: specKey,
                  surface: '0.00',
                  rendement: specifications[specKey]['rendement'],
                  prix: specifications[specKey]['prix'],
                  typeCulture: 'Conventionnelle'
                };
              }
            }
          }
        }
      }
    }
    return dataOutPut;
  } 

  //Liste avec code 3 et libelle des cultures disponible à la duplication
  const culturesDispo = (ClickedGC, ClickedViti) => {
    var obj 
    if(ClickedGC){
      obj = Object.keys(data).map((item)=>( item !=="630" && item !=="670" && item !=="675"?
        {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length} : null
      ))
    }
    else if (ClickedViti){
      obj = Object.keys(data).map((item)=>( item ==="630" || item ==="670" || item ==="675"?
        {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length} : null
      ))
    }
    else {
      obj = Object.keys(data).map((item)=>( 
        {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length}
      ))
    }
    return obj
  }

  const [ShowDupliquerProd, setShowDupliquerProd]= useState(false);
  const [ShowAjouterProd, setShowAjouterProd]= useState(false);
  const [ClickedGC, setClickedGC]= useState(false);
  const [ClickedViti, setClickedViti]= useState(false);
  const [ClickedMixte, setClickedMixte]= useState(true);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('idAgent')); 
    if (items) {
    setidAgent(items);
    }

    fetch(apiLink + 'getExploitationDefault/' + city)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      var DDef=response
      setData(mergedata(DDef))
      setDataDefaut(response)
      setDataDefaut(prev => {
        return prev;
      })
    })
  }, [city]);

  //permet de stocker si bio, conventionnel ou irrigué
  function handleChangeTypeCulture(event) {
    var TC = event.target.value;
    setTypeCulture(TC);
    Object.keys(data).map(key => {
      var newData = { ...data };
      if (TC === "Conventionnelle") {
        if (data[key]['SelectedSpe'] !== "none") {
          newData[key]["rendement"] = data[key]['Specification'][data[key]['SelectedSpe']]['rendement'];
          newData[key]["prix"] = data[key]['Specification'][data[key]['SelectedSpe']]['prix'];
        } else {
          newData[key]["rendement"] = data[key]['rendementConv'];
          newData[key]["prix"] = data[key]['prixConv'];
        }
      } else {
        if (data[key]['SelectedSpe'] !== "none") {
          newData[key]["rendement"] = data[key]['Specification'][data[key]['SelectedSpe']]['rendementBio'];
          newData[key]["prix"] = data[key]['Specification'][data[key]['SelectedSpe']]['prixBio'];
        } else {
          newData[key]["rendement"] = data[key]['rendementBio'];
          newData[key]["prix"] = data[key]['prixBio'];
        }
      }
      setData(newData);
    });
    Object.keys(dataPlusCult).map(keyDC => {
      var newDataPlusCult = { ...dataPlusCult };
      if (TC === "Conventionnelle") {
        if (dataPlusCult[keyDC]['SelectedSpe'] !== "none") {
          newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['rendement'];
          newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['prix'];
        } else {
          newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['rendementConv'];
          newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['prixConv'];
        }
      } else {
        if (dataPlusCult[keyDC]['SelectedSpe'] !== "none") {
          newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['rendementBio'];
          newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['prixBio'];
        } else {
          newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['rendementBio'];
          newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['prixBio'];
        }
      }
      setdataPlusCult(newDataPlusCult);
    });
    
  }

  // changer le nom de la simulation
  function handleChangeName(event) {
    var Name = event.target.value
    setName(Name) 
  }

  //tableau des cultures de base
  const table_assol=  Object.keys(data).map(key => (
    <Culture 
    keys={key}
    data={data}
    MAJ_surf_rdt_prix_spe={MAJ_surf_rdt_prix_spe}
    ClickedGC={ClickedGC}
    ClickedMixte= {ClickedMixte}
    ClickedViti={ClickedViti}
    libelle={data[key]["libelle"]}
    typeCulture={TypeCulture}
    />
  ))
  //tableau des cultures dupliqué pour ajouter des spés
  const table_assol_spe_sup = Object.keys(dataPlusCult).map(clef => (
    <CultureAjoutSpe 
    keys={clef}
    data={dataPlusCult}
    MAJ_surf_rdt_prix_spe={MAJ_surf_rdt_prix_spe_Cult_Plus}
    SuppressionSpe={SuppressionSpe}
    libelle={dataPlusCult[clef]["libelle"]}
    typeCulture={TypeCulture}
    />
  ))

  //choix de la spé à dupliquer
  function handlechangeOtherSpe(event) {
    setValueSpeAdddOtherSpe(event.target.value)
  }

  //Ajout d'une nouvelle culture dans les liste de celles dupliquées
  function addOtherSpe(){
    var lengthSpe = Object.keys(data[valueSpeaddOtherSpe]["Specification"]).length
    var Nbligne = 0
    for (var item in dataPlusCult) {
      if (dataPlusCult[item]['key'] === valueSpeaddOtherSpe) {
        Nbligne += 1;
      }
    }
    if(Nbligne < lengthSpe) {
      var sumDPC = Object.keys(dataPlusCult).pop() //récupère la dernière clef de l'objet
      var clef = 0
      if(sumDPC === undefined){
        clef = 1
      }
      else {
        clef = parseInt(sumDPC) + 1
      }
      var newLigne = {
        [clef]:{
          "key": valueSpeaddOtherSpe,
          "libelle":data[valueSpeaddOtherSpe]["libelle"],
          "SelectedSpe":"none",
          "surface":0.00,
          "rendement":data[valueSpeaddOtherSpe]["rendement"],
          "rendementConv" :data[valueSpeaddOtherSpe]["rendementConv"],
          "rendementBio":data[valueSpeaddOtherSpe]["rendementBio"] ,
          "prix":data[valueSpeaddOtherSpe]["prix"],
          "prixConv":data[valueSpeaddOtherSpe]["prixConv"],
          "prixBio":data[valueSpeaddOtherSpe]["prixBio"],
          "Specification": data[valueSpeaddOtherSpe]["Specification"]
        }  
      }
      setdataPlusCult({...dataPlusCult, ...newLigne});
      //setdataPlusCult(Object.assign(dataPlusCult,newLigne))
    }
    else {
      alert("Vous ne pouvez pas ajouter plus de ligne de culture qu'il n'y a de spécification")
    }
  }

  //fonction de suppression d'une culture dupliquée
  function SuppressionSpe(key){
    const updateddata = { ...dataPlusCult};
    delete updateddata[key];
    setdataPlusCult(updateddata);
    setdataPlusCult(prev => {
      return prev
    })
  }

  // Mise à jour des données de surfaces, rdt, prix et spécification de chaque culture
  function MAJ_surf_rdt_prix_spe(key,type, valeur) {
    var newData = {...data};
    if (typeof valeur === "string") {
      newData[key][type] = valeur
    }
    else { 
      newData[key][type] = parseFloat(valeur)
    }
    setData(newData)
    setData(prev => {
      return prev;
    }) 
  }
  function MAJ_surf_rdt_prix_spe_Cult_Plus(key,type, valeur) {
    var newData = {...dataPlusCult};
    if (typeof valeur === "string") {
      newData[key][type] = valeur
    }
    else { 
      newData[key][type] = parseFloat(valeur)
    }
    setdataPlusCult(newData)
    setdataPlusCult(prev => {
      return prev;
    }) 
  }

  // calcul du total des surfaces
  const total_surf1 = (data) => {
    var surf_tot1 = 0
    for (var key in data ) {
      surf_tot1 +=  parseFloat(data[key]["surface"])
    }
    return surf_tot1
  }
  const total_surf2 = (dataPlusCult) => {
    var surf_tot2 = 0
    if (dataPlusCult === undefined) {
      surf_tot2 = 0
    }
    else {
      for (var key in dataPlusCult ) {
        surf_tot2 +=  parseFloat(dataPlusCult[key]["surface"])
      }
    }
    return surf_tot2
  }
  const total_surf = (data, dataPlusCult) => { 
    return total_surf1(data) + total_surf2(dataPlusCult) 
  }

  // calcul du total des PB
  const total_PB1 = (data) => {
    var PB_tot1 = 0
    for (var key in data ) {
      if(parseFloat(data[key]["surface"]) !== 0){
        console.log('coucou')
        PB_tot1 += Math.round( parseFloat(data[key]["surface"])* parseFloat(data[key]["rendement"])* parseFloat(data[key]["prix"]))
      } else {
        PB_tot1 +=0
      }

      
    }
    return PB_tot1
  }
  const total_PB2 = (dataPlusCult) => {
    var PB_tot2 = 0
    if (dataPlusCult === undefined) {
      PB_tot2 = 0
    }
    else {
      for (var key in dataPlusCult ) {
        if (parseFloat(dataPlusCult[key]["surface"]) !== 0){
          PB_tot2 += Math.round( parseFloat(dataPlusCult[key]["surface"])* parseFloat(dataPlusCult[key]["rendement"])* parseFloat(dataPlusCult[key]["prix"]))
        } else {
          PB_tot2 += 0
        }
        
      }
    }
    return PB_tot2
   
  }
  const total_PB = (data, dataPlusCult) => {
    return (total_PB1(data) + total_PB2(dataPlusCult))
  }

  // cherche si une culture dupliqué n'a pas de surface 
  function Spe_dupliquee_sans_surf(dataPlusCult){
    var surfNull= false 
    for (var key in dataPlusCult ) {
      if (dataPlusCult[key]["surface"] === 0) {
        surfNull = true
      }
    }
    return surfNull
  }

  // cherche si une production à deux fois la même spé 
  function prod_spe_en_double(data, dataPlusCult){
    var PED = false 
    for (var keyD in data ) { // on fait le tour des productions de base 
      var SpeList = data[keyD]["Specification"] // on récupère la liste des spécifications
      var SpeSelected = {"none": 0} //initialisation de liste avec le sans spé
      for (var keySL in SpeList){
        Object.assign(SpeSelected,{[keySL]:0}) 
      }
      var firstSpe = data[keyD]["SelectedSpe"]
      SpeSelected[firstSpe] += 1// on ajoute 1 pour la spé sélectionné par la première culture 
      for (var keyDC in dataPlusCult ) { // a chaque fois on fait le tour des productions duppliquées
        if(dataPlusCult[keyDC]["key"] === keyD) {
          var SS = dataPlusCult[keyDC]["SelectedSpe"]
          SpeSelected[SS] += 1  //si la key est égale à keyD alors on ajoute 1 à la spé
        }
      }
      for (var keySS in SpeSelected){
        if(SpeSelected[keySS] > 1){
          PED = true //si une spé à plus de 1 itération on arrête la fonction et on return true
          return PED
        }
      }
    }
    return PED
  }

  // foinction d'envoi des données au serveur
  function handleSubmit(event) {
    var surfaceNull = Spe_dupliquee_sans_surf(dataPlusCult)
    var productionEnDouble = prod_spe_en_double(data, dataPlusCult)
    event.preventDefault()
    if (data["630"] !== undefined && ((data["630"]["surface"]===0 && data["630"]["SelectedSpe"]!=="none") || (data["670"]["surface"]===0 && data["670"]["SelectedSpe"]!=="none") || (data["675"]["surface"]===0 && data["675"]["SelectedSpe"]!=="none"))){
      alert('Vous devez choisir un type de vin pour le surface en viticulture saisie')
    }
    else if (surfaceNull === true) {
      alert("L'une des productions duppliquées ne possède pas de surface, veuillez soit : \n- Supprimer cette production \n- Ajouter une surface à cette production ")
    }
    else if (productionEnDouble === true){
      alert("L'une de vos production possède deux (ou plus) fois la même spécification, veuillez soit \n - Supprimer le production avec la spécification en double \n - Changer la spécification ")
    }
    else{
      var prod = JSON.stringify(EnvoiCompletResultats(data, TypeCulture, dataPlusCult, dataDefaut)) 
      mesDonnees.set("productions", prod);
      mesDonnees.set("nom", Name);
      mesDonnees.set("idCommune", city)

      axios({
          method: 'post',
          url: apiLink + 'setExploitation/' + idAgent,
          data: mesDonnees
      })
      .then(function (reponse) {
        if(reponse.status === 200){
        var role = localStorage.getItem('role')
        var timestamp = localStorage.getItem('connexion_timestamp')
        var formule = JSON.parse(localStorage.getItem('formule'));
        localStorage.clear();
        localStorage.setItem('idAgent', JSON.stringify(idAgent));
        localStorage.setItem('idExploitationPrinicpale', JSON.stringify(reponse.data.idExploitationPrincipale));
        localStorage.setItem('role', role);
        localStorage.setItem('formule', JSON.stringify(formule));
        localStorage.setItem('connexion_timestamp', JSON.stringify(timestamp));
        setGoToExploit(true)
        setidExploit(reponse.data.idExploitation)
      }
        else {
          alert("Une erreur c'est produite, votre assolement n'a pas pû être enregistré")
        }
      })
      .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
      });
    }
  }

  function handleShowDupliProd() {
    setShowDupliquerProd(true)
  }
  function handleHideDupliProd() {
      setShowDupliquerProd(false)
  }
  function handleShowAjouProd() {
      setShowAjouterProd(true)
  }
  function handleHideAjouProd() {
      setShowAjouterProd(false)
  }
  function handleClickedGC() {
    setClickedGC(true)
    setClickedViti(false)
    setClickedMixte(false)
  }
  function handleClickedViti() {
    setClickedGC(false)
    setClickedViti(true)
    setClickedMixte(false)
  }
  function handleClickedMixte() {
    setClickedGC(false)
    setClickedViti(false)
    setClickedMixte(true)
  }

  if(goToExploit) {
    return <Navigate push to={`/exploitation`} state={{idExploit}}/> 
  }

  return (
    <div>
      <div className='container'>
        <div onSubmit={handleSubmit}> 
          <div className='titre'> Nom de la simulation : 
            <input 
            className='inputNomSimul'
            value={Name}
            onChange={handleChangeName}
            placeholder='Nom'
            type='text'
            required/></div>

            <div className='dispLigne'>
              <button className= {ClickedGC ? 'validation validationCompGCVITIMI valCompGCC' : 'validation validationCompGCVITIMI valCompGCUnC'} onClick={handleClickedGC} >
                Grandes cultures
              </button>
              <button className= {ClickedViti ?'validation validationCompGCVITIMI valCompVitiC' : 'validation validationCompGCVITIMI valCompVitiUnC'} onClick={handleClickedViti}>
                Viticulture
              </button>
              <button className= {ClickedMixte ?'validation validationCompGCVITIMI valCompMixteC' : 'validation validationCompGCVITIMI valCompMixteUnC'} onClick={handleClickedMixte}>
                Mixte
              </button>
            </div>

          {TypeCulture !== "" ? (
            <div className='row' onChange={handleChangeTypeCulture} style={{justifyContent:'left', marginBottom:'0px'}}> 
              <input type="radio" value="Conventionnelle" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "Conventionnelle" }/>  <label className='paragraphe'> Conventionnelle </label>
              <input type="radio" value="Biologique" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "Biologique"}/> <label className='paragraphe'> Biologique </label>
              {/* <input type="radio" value="Irriguee" name="CultureType" className='input_radio'defaultChecked={TypeCulture === "Irriguee"}/> <label className='paragraphe'> Irriguée </label> */}
            </div> 
          ):""}
          <div className='table_assol'>
            <div className='row ligne_assol'>
              <div className='col_assol col_libelle' style={{display:'flex', justifyContent:'center'}}> 
                <b>Libellé </b>
              </div>
              <div className='col_assol col_spe' style={{display:'flex', justifyContent:'center'}}>
                <b>Spécification </b>
              </div>
              <div className='col_assol col_surface'>
                <b>Surface </b>
              </div>
              <div className='col_assol col_rdt'>
                <b>Rendement </b>
              </div>
              <div className='col_assol col_prix'>
                <b>Prix </b>
              </div>
              <div className='col_assol col_PB_unitaire'>
                <b>Produit brut par ha </b>
              </div>
              <div className='col_assol col_PB_tit_Prod'>
                <b>Produit brut total </b>
              </div>
            </div>
            {Object.keys(data).length === 0 ?"":table_assol}
            {Object.keys(dataPlusCult).length === 0 ?"": table_assol_spe_sup}
            <div className='row ligne_assol'>
              <div className='col_assol col_TotFinal' style={{fontWeight:'bold'}}>
                Total 
              </div>
              <div className='col_assol col_surface' style={{fontWeight:'bold'}}>
                {Math.round(total_surf(data, dataPlusCult)*100)/100} ha
              </div>
              <div className='col_assol col_PB_tot' style={{fontWeight:'bold'}}>
                {total_PB(data, dataPlusCult).toLocaleString()} €
              </div>
            </div>
          </div>
          <div className='masquer_en_portrait' style={{marginTop:'20px', width:'100%'}}> 
            <div className='dispLigne' style={{alignItems:'center', width:'100%'}}>
              <button 
                className={ShowDupliquerProd ? 'validation validationCompAssol valCompOrange' : 'validation validationCompAssol valCompVert'} 
                onClick={ShowDupliquerProd ? handleHideDupliProd : handleShowDupliProd}>
                Ajouter une autre spécification à une culture existante
              </button>

              <div className={ShowDupliquerProd ? 'ShowResult ' : 'HideResult'} style={{width:"65%"}}>
                <select  style={{height:'50px', margin:'0px', width:'35%', marginLeft:"40px", marginRight:"20px"}} onChange={handlechangeOtherSpe}>
                  <option value="">Sélectionnez la production à dupliquer</option>
                  {culturesDispo(ClickedGC, ClickedViti).map((item)=>( item && <option key={item.key} value={item.key} > {item && item.label} </option> ) ) }
                </select>
                <button className='validation' onClick={addOtherSpe} style={{backgroundColor:'#d5d5d5', marginTop:'0px', height:'80%', margin:'0px', width:'25%'}}> Dupliquer la production </button>
              </div>
            </div>


          </div>
          
          <div className='row'>
            <button onClick={handleSubmit} className='validation'> Enregistrer </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Assolement;