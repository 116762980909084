import React from 'react';
import Nav from '../MEP/SWNav';
import EnteteSW from '../MEP/enteteSW';
import FooterSW from '../MEP/footer_SW';

import mail from '../../assets/wired-flat-177-envelope-mail-send.gif'

function NousContacter() {
  return (
    <div>
      <Nav/>
      <EnteteSW titre="Nous contacter"/>
      <div className="containerSW" >
        <div className='row'>
         <img src={mail} alt="logo_diagorisk" />
         <div className='card' style={{backgroundColor:'#D5F5B2', width:'30%', padding:'50px'}}>
          <div>
            <div className='paragraphe'><b>Téléphone : </b></div>
            <a className='paragraphe' href='tel:+33(0)6.85.48.01.87'> +33(0)6.85.48.01.87 </a>
          </div>

          <div>
            <div className='paragraphe' style={{marginTop:'50px'}}><b>Email : </b></div>
            <a className='paragraphe' href='mailto:contact@diagorisk.com'> contact@diagorisk.com </a>
          </div>
         </div>
        </div>
      </div>
      <FooterSW/>
    </div>
  );
}

export default NousContacter;

