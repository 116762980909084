import React from "react";
import {
    XYPlot,
    YAxis,
    XAxis,
    HorizontalGridLines,
    LineSeries,
    makeVisFlexible,
  } from "react-vis";

function GraphMarges({dataPrix, dataCharges, maximum, minimum,print}) {
  const FlexXYPlot=makeVisFlexible(XYPlot)
  const yDomain= [minimum,maximum]
  const yAxisOn0 = true
  const widthLigne = print ? 3 : 5
  const fontSizeLegend = print ? 16 : 18

  return (
    <div style={{marginTop:'0px'}}>
      <FlexXYPlot  height={print? 300 : 400} {...{ yDomain}}>
          <YAxis 
              tickFormat={v => v/1000}
              style={{
                line: {stroke:'grey'},
                text: {stroke: 'none', fill: 'black', fontWeight: 500, fontSize: fontSizeLegend}
              }}
              on0={yAxisOn0} 
          />
          <XAxis
              style={{
              text: {stroke: 'none', fill: 'black', fontWeight: 500, fontSize: fontSizeLegend}
              }}
          />
          <HorizontalGridLines  style={{stroke: '#AFAFAF'}}  />
          <LineSeries
              className="prix"
              curve={'curveMonotoneX'}
              style={{stroke: '#3075FF', strokeWidth: widthLigne, fill:'transparent' }}
              data={dataPrix}
          />
          <LineSeries
              className="charges"
              curve={'curveMonotoneX'}
              style={{stroke: '#FFA366', strokeWidth: widthLigne, fill:'transparent' }}
              data={dataCharges}
          />
      </FlexXYPlot>
    </div>

  );
}

export default GraphMarges;
  