import React, {useState} from 'react';
import {Navigate} from 'react-router-dom'

import logoBV from '../../assets/DiagoRisk-logoallongé(fondfoncé).png'
import mail from '../../assets/mail.png'
import tel from '../../assets/tel.png'
import LI from '../../assets/LinkedIn.png'
import Pdf_CGU from '../../assets/PDF/2023-07-10_CGU.pdf'
import Pdf_CGV from '../../assets/PDF/2023-07-10_CGV.pdf'

function FooterSW() {
    const [goToML, setGoToML] = useState(false)
    const [goToPDD, setGoToPDD] = useState(false)
  
    function handleClickML(event) {
      event.preventDefault()
      setGoToML(true)
    }
  
    function handleClickPDD(event) {
      event.preventDefault()
      setGoToPDD(true)
    }
    
    if(goToML) {
        return <Navigate push to={`/mentionslegales`} />
    }
    
    if(goToPDD) {
    return <Navigate push to={`/protectiondesdonnees`} />
    }
  
    return (
      <div>  
        <div className='footer' style={{color:'rgb(146, 208, 80)'}}>
          <div className='footer_gauche'>
            <img src={logoBV} alt="logo_diagorisk" className="logo_BV_Footer"/>
          </div>
          <div className='footer_centre'>
            <div className='titreMoins' style={{marginBottom:'10px', marginTop:'0px'}} > Contact</div>
            
            <div className='row contactLeft '>
              <img src={mail} alt="" className="logo_contact"/>
              <a href='mailto:contact@diagorisk.com'> contact@diagorisk.com </a>
            </div>
            <div className='row contactLeft'>
              <img src={tel} alt="" className="logo_contact"/>
              <a href='tel:+33(0)6.85.48.01.87'> +33(0)6.85.48.01.87 </a>
            </div>
          </div>
          <div className='footer_droit'>
            <div className='titreMoins' style={{marginBottom:'10px', marginTop:'0px'}} > Rejoignez-nous </div>
            <div className='row'>
              LinkedIn
              <a href='https://www.linkedin.com/company/diagorisk/'  target='_blank'><img src={LI} alt="" className="logo_LI"/></a>
            </div>
          </div>
        </div>
        <div style={{color:'rgb(146, 208, 80)', marginBottom:'0px', backgroundColor: '#1A213F'}}>
          <div className='row' style={{ marginBottom:'0px', paddingBottom:'15px'}} >
            <button className='ButtonHome buttonFooter' onClick={handleClickML} > Mentions légales </button>
            <button className='ButtonHome buttonFooter' onClick={handleClickPDD} > Protection des données </button>
            <a href = {Pdf_CGU} className='ButtonHome buttonFooter' target='_blank'>Conditions génrales d'utilisation</a>
            <a href = {Pdf_CGV} className='ButtonHome buttonFooter' target='_blank'>Conditions génrales de vente</a>
          </div>
        </div>
  
        <div className='copyright'>
          <div>Copyright © 2024 RisquesAgricoles SAS</div>
        </div>
      </div>
    );
  }
  
  export default FooterSW;
  
  