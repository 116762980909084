import React from 'react'
import Couv4 from '../../assets/couv4_rapport.jpg'


function Couv4eme() {
  return (
    <div className='couv4eme'>
      <img src={Couv4} style={{width:'100%', height:'100%'}} alt="logo_diagorisk"/>
    </div>
  )
}

export default Couv4eme