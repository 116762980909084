import React, { useState} from 'react'
import apiLink from '../../../config.js'
import {Navigate} from 'react-router-dom'
import checked from '../../../assets/checked.jpg'
import checked_none from '../../../assets/checked_none.jpg'
const Simul = ({keys,data, idExploit, libelle, date, assol, simulAcomparer, admin}) => {
    const [goToExploit, setGoToExploit] = useState(false)
    const [goToRapport, setGoToRapport] = useState(false)
    const [CheckB, setChekB] = useState(false)
    
    function handleClickConsult() {
        setGoToExploit(true)
    }
    function handleClickRapport() {
        setGoToRapport(true)
    }
    function handleClickSupp() {
        fetch(apiLink + 'deleteExploitation/' + idExploit)
        .then( (response) => {
            return response.json()
        })
        window.location.reload();
    }

    function handleSelect(){
        if (CheckB) {
            simulAcomparer(idExploit, 'unchecked')
            setChekB(false)
        }
        else {
            simulAcomparer(idExploit, 'checked') 
            setChekB(true)
        }
       
    };

    if(goToExploit) {
        return <Navigate push to={`/exploitation`} state={{idExploit}}/>
    }
    if(goToRapport) {
        return <Navigate push to={`/previsualisationrapport`} state={{idExploit}}/>
    }

    return (
        <div className='row ligne_assol'>
                <div className={admin ===false ? 'col_assol col_check ShowResult': 'col_assol col_check HideResult'}>
                    <button onClick={handleSelect} className='buttonCheckedSimul' ><img src={CheckB? checked : checked_none} alt="" className="IMGbuttonCheckedSimul"/></button>
                </div>
                <div className='col_assol col_date'>
                    {date}
                </div>
                <div className='col_assol col_libelle_simul'>
                    {libelle}
                </div>
                <div className='col_assol col_assol_culture'>
                    {assol}
                </div>
                <div className='col_assol col_button'>
                    <div className='row'>
                        <button className='validation consult_simul' onClick={handleClickConsult}> Consulter </button>
                        <button className='validation impr_simul' onClick={handleClickRapport}> Visualiser le rapport </button>
                        <button className='validation consult_simul supp_simul' onClick={handleClickSupp} style={{backgroundColor:'#FE5B5B'}}> Supprimer </button>
                    </div>
                </div>
        </div>
    )
}

export default Simul