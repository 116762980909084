import React, {useEffect} from 'react';
import apiLink from '../../config.js'
import {useLocation, Navigate} from 'react-router-dom'

import Entete from '../MEP/entete'
import ButtonQuestions from '../MEP/button question.js';
import ButtonVidéos from '../MEP/buttonVidéo.js';
import OADMenu from '../MEP/oadMenu'
import GraphMarges from './Graphiques/marges'


function RisquesPrix() {
  const { state } = useLocation()
  const { idExploit } = state
  const [goToHome, setGoToHome] = React.useState(false)

  const [PBTot, setPBTot]= React.useState();
  const [Mount, setMount] = React.useState(true);
  const [Mount2, setMount2] = React.useState(true);
  const [ChargesCoef, setChargesCoef]= React.useState();

  useEffect ( () => {
    fetch(apiLink + 'page/risqueprix/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        setPBTot(response.PBTotal)
        setPBTot(prev => {
          return prev;
        })
        setChargesCoef(response.charge)
        setChargesCoef(prev => {
          return prev;
        })
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
    }) 
  }, [idExploit])

  const ChargesTot = PBTot*ChargesCoef

  const ventes2Moins = [{x:-6,y:PBTot*0.99},{x:-3,y:PBTot*0.995},{x:0,y:PBTot},{x:3,y:PBTot*0.98},{x:6,y:PBTot*0.96},{x:9,y:PBTot*0.94},{x:12,y:PBTot*0.91},{x:18,y:PBTot*0.89}] 
  const ventesMoins = [{x:-6,y:PBTot*0.99},{x:-3,y:PBTot*0.995},{x:0,y:PBTot},{x:3,y:PBTot*0.99},{x:6,y:PBTot*0.985},{x:9,y:PBTot*0.975},{x:12,y:PBTot*0.97},{x:18,y:PBTot*0.955}]
  const ventesMoy = [{x:-6,y:PBTot*0.99},{x:-3,y:PBTot*0.995},{x:0,y:PBTot},{x:3,y:PBTot*1.01},{x:6,y:PBTot*1.02},{x:9,y:PBTot*1.04},{x:12,y:PBTot*1.05},{x:18,y:PBTot*1.07}]
  const ventesPlus = [{x:-6,y:PBTot*0.99},{x:-3,y:PBTot*0.995},{x:0,y:PBTot},{x:3,y:PBTot*1.02},{x:6,y:PBTot*1.05},{x:9,y:PBTot*1.07},{x:12,y:PBTot*1.08},{x:18,y:PBTot*1.12}]
  const ventes2Plus = [{x:-6,y:PBTot*0.99},{x:-3,y:PBTot*0.995},{x:0,y:PBTot},{x:3,y:PBTot*1.04},{x:6,y:PBTot*1.09},{x:9,y:PBTot*1.10},{x:12,y:PBTot*1.13},{x:18,y:PBTot*1.17}]
  
  const charges2Moins = [{x:-6,y:ChargesTot*0.95},{x:-3,y:ChargesTot*0.98},{x:0,y:ChargesTot},{x:3,y:ChargesTot*0.96},{x:6,y:ChargesTot*0.94},{x:9,y:ChargesTot*0.92},{x:12,y:ChargesTot*0.89},{x:18,y:ChargesTot*0.87}]
  const chargesMoins = [{x:-6,y:ChargesTot*0.95},{x:-3,y:ChargesTot*0.98},{x:0,y:ChargesTot},{x:3,y:ChargesTot*0.99},{x:6,y:ChargesTot*0.985},{x:9,y:ChargesTot*0.97},{x:12,y:ChargesTot*0.965},{x:18,y:ChargesTot*0.95}]
  const chargesMoy = [{x:-6,y:ChargesTot*0.95},{x:-3,y:ChargesTot*0.98},{x:0,y:ChargesTot},{x:3,y:ChargesTot*1.01},{x:6,y:ChargesTot*1.02},{x:9,y:ChargesTot*1.04},{x:12,y:ChargesTot*1.05},{x:18,y:ChargesTot*1.07}]
  const chargesPlus = [{x:-6,y:ChargesTot*0.95},{x:-3,y:ChargesTot*0.98},{x:0,y:ChargesTot},{x:3,y:ChargesTot*1.05},{x:6,y:ChargesTot*1.08},{x:9,y:ChargesTot*1.10},{x:12,y:ChargesTot*1.11},{x:18,y:ChargesTot*1.15}]
  const charges2Plus = [{x:-6,y:ChargesTot*0.95},{x:-3,y:ChargesTot*0.98},{x:0,y:ChargesTot},{x:3,y:ChargesTot*1.08},{x:6,y:ChargesTot*1.13},{x:9,y:ChargesTot*1.15},{x:12,y:ChargesTot*1.17},{x:18,y:ChargesTot*1.19}]

  const [Pvente, setPV]= React.useState(ventesMoy)
  const [ChargesF, setC]= React.useState(chargesMoy)
  const Pvente2 = ventesMoy
  const ChargesF2 = chargesMoy

  const [BV2M, setBV2M]= React.useState('UnChecked')
  const [BVM, setBVM]= React.useState('UnChecked')
  const [BVMoy, setBVMoy]= React.useState('Checked')
  const [BVP, setBVP]= React.useState('UnChecked')
  const [BV2P, setBV2P]= React.useState('UnChecked')

  const [BC2M, setBC2M]= React.useState('UnChecked')
  const [BCM, setBCM]= React.useState('UnChecked')
  const [BCMoy, setBCMoy]= React.useState('Checked')
  const [BCP, setBCP]= React.useState('UnChecked')
  const [BC2P, setBC2P]= React.useState('UnChecked')

  const maximum= ventes2Plus[7].y
  const minimum= charges2Moins[7].y  
  
  function handlePressV2M() {
    setPV(ventes2Moins)
    setBV2M('Checked')

    setBVM('UnChecked')
    setBVMoy('UnChecked')
    setBVP('UnChecked')
    setBV2P('UnChecked')

    setMount(false)
  }
  function handlePressVM() {
    setPV(ventesMoins)
    setBVM('Checked')

    setBV2M('UnChecked')
    setBVMoy('UnChecked')
    setBVP('UnChecked')
    setBV2P('UnChecked')

    setMount(false)
  }

  function handlePressVMoy () {
    setPV(ventesMoy)
    setBVMoy('Checked')

    setBV2M('UnChecked')
    setBVM('UnChecked')
    setBVP('UnChecked')
    setBV2P('UnChecked')

    setMount(false)
  }
    

  function handlePressVP() {
    setPV(ventesPlus)
    setBVP('Checked')

    setBV2M('UnChecked')
    setBVM('UnChecked')
    setBVMoy('UnChecked')
    setBV2P('UnChecked')

    setMount(false)
  }

  function handlePressV2P() {
    setPV(ventes2Plus)
    setBV2P('Checked')

    setBV2M('UnChecked')
    setBVM('UnChecked')
    setBVMoy('UnChecked')
    setBVP('UnChecked')

    setMount(false)
  }

  function handlePressC2M() {
    setC(charges2Moins)
    setBC2M('Checked')

    setBCM('UnChecked')
    setBCMoy('UnChecked')
    setBCP('UnChecked')
    setBC2P('UnChecked')

    setMount2(false)
  }
  function handlePressCM() {
    setC(chargesMoins)
    setBCM('Checked')

    setBC2M('UnChecked')
    setBCMoy('UnChecked')
    setBCP('UnChecked')
    setBC2P('UnChecked')

    setMount2(false)
  }

  function handlePressCMoy() {
    setC(chargesMoy)
    setBCMoy('Checked')

    setBC2M('UnChecked')
    setBCM('UnChecked')
    setBCP('UnChecked')
    setBC2P('UnChecked')

    setMount2(false)
  }

  function handlePressCP() {
    setC(chargesPlus)
    setBCP('Checked')

    setBC2M('UnChecked')
    setBCM('UnChecked')
    setBCMoy('UnChecked')
    setBC2P('UnChecked')

    setMount2(false)
  }

  function handlePressC2P() {
    setC(charges2Plus)
    setBC2P('Checked')

    setBC2M('UnChecked')
    setBCM('UnChecked')
    setBCMoy('UnChecked')
    setBCP('UnChecked')

    setMount2(false)
  }

  if(goToHome) {
    return <Navigate push to={`/`} />
  }

  return (
    <div >
      <Entete titre="Risques prix"/>
        <div className='container'>
          <div className='titrePlusVideo'>
            <div className='titre' style={{marginTop:('15px')}}> Quel est l’impact de l’évolution du prix sur ma marge ? </div>
            <ButtonVidéos link={'http://diagorisk.com'}/>
        </div>

          <div className='dispLigne'>
            <div className='dispLeftOpt'>
              <div className='paragraphe'>Les prix de vente et les charges connaissent de fortes variations. Estimez l'impact sur votre marge.  </div>
              
              <div className='card cardPrix'>
                  <div className='titreCard'>Sélectionnez des variations de charges et de prix de vente </div>

                  <div className='row flexLigne row flexLigneButtonPrix'>
                    <div className='titrePrix'>Prix de vente</div>
                    <button className={BV2M==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressV2M}  >
                      <div className='active'>--</div>
                    </button>
                    <button className={BVM==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressVM}  >
                      <div className='active'>-</div>
                    </button>
                    <button className={BVMoy==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressVMoy}  >
                      <div className='active2'>Moy</div>
                    </button>
                    <button className={BVP==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressVP}  >
                      <div className='active'>+</div>
                    </button>
                    <button className={BV2P==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressV2P}  >
                      <div className='active'>++</div>
                    </button>
                  </div>

                  <div className='row flexLigne flexLigneButtonPrix'>
                    <div className='titrePrix'>Charges</div>
                    <button className={BC2M==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressC2M}  >
                      <div className='active'>--</div>
                    </button>
                    <button className={BCM==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressCM}  >
                      <div className='active'>-</div>
                    </button>
                    <button className={BCMoy==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressCMoy}  >
                      <div className='active2'>Moy</div>
                    </button>
                    <button className={BCP==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressCP}  >
                      <div className='active'>+</div>
                    </button>
                    <button className={BC2P==='UnChecked' ? 'buttonPrix buttonPrixDefault' : 'buttonPrix buttonPrixChecked'} onClick={handlePressC2P}  >
                      <div className='active'>++</div>
                    </button>
                  </div>
              </div>

              <div className='titreMoins'>Résultats</div>
              <div className='paragraphe'>Les résultats sont exprimés en  milliers d'euros. "12" corresponds à une échéance dans 12 mois (dans 1 an) et "-3", celle d'il y a 3 mois. </div>
              <GraphMarges dataPrix={Mount?Pvente2: Pvente} dataCharges={Mount2?ChargesF2:ChargesF} maximum={maximum} minimum={minimum}/>
              <div className='row legend'>
                <div className='row legendBloc'>
                  <div className='legendGraphiquePrix legendcharges'>|</div>
                  <div className='paragraphe'>Charges</div>
                </div>
                <div className='row legendBloc'>
                  <div className='legendGraphiquePrix legendventes'>|</div>
                  <div className='paragraphe'>Prix de vente</div>
                </div>
              </div>
            </div>

            <div className='dispRightOpt'>
              <div className='titreMoins'>Projections de la marge </div>

              <div className='flexLignePrix'>
                <div className='blocPrix'>
                  <div className='videBlocPrix videBloc3'>
                    <div className='pleinBlocPrix pleinBloc3'>
                      <div className='titreBloc'>3 mois</div>
                    </div>
                    <div style={{marginBottom:13}}>
                      <div className='paragrapheBlocPrix paragrapheBloc3'> {Math.round((Mount?Pvente2[3].y: Pvente[3].y)-(Mount2?ChargesF2[3].y:ChargesF[3].y)).toLocaleString()}€ </div>
                    </div>
                  </div>
                </div>
                <div className='blocPrix'>
                  <div className='videBlocPrix videBloc6'>
                    <div className='pleinBlocPrix pleinBloc6'>
                      <div className='titreBloc'>6 mois</div>
                    </div>
                    <div style={{marginBottom:13}}>
                      <div className='paragrapheBlocPrix paragrapheBloc6'>{Math.round((Mount?Pvente2[4].y: Pvente[4].y)-(Mount2?ChargesF2[4].y:ChargesF[4].y)).toLocaleString()}€</div>
                    </div>
                  </div>
                </div>
                <div className='blocPrix'>
                  <div className='videBlocPrix videBloc9'>
                    <div className='pleinBlocPrix pleinBloc9'>
                      <div className='titreBloc'>9 mois</div>
                    </div>
                    <div style={{marginBottom:13}}>
                      <div className='paragrapheBlocPrix paragrapheBloc9'>{Math.round((Mount?Pvente2[5].y: Pvente[5].y)-(Mount2?ChargesF2[5].y:ChargesF[5].y)).toLocaleString()}€</div>
                    </div>
                  </div>
                </div>
              <div className='blocPrix'>
                  <div className='videBlocPrix videBloc12'>
                    <div className='pleinBlocPrix pleinBloc12'>
                      <div className='titreBloc'>12 mois</div>
                    </div>
                    <div style={{marginBottom:13}}>
                      <div className='paragrapheBlocPrix paragrapheBloc12'>{Math.round((Mount?Pvente2[6].y: Pvente[6].y)-(Mount2?ChargesF2[6].y:ChargesF[6].y)).toLocaleString()}€</div>
                    </div>
                  </div>
                </div>
                <div className='blocPrix'>
                  <div className='videBlocPrix videBloc18'>
                    <div className='pleinBlocPrix pleinBloc18'>
                      <div className='titreBloc'>18 mois</div>
                    </div>
                    <div style={{marginBottom:13}}>
                      <div className='paragrapheBlocPrix paragrapheBloc18'>{Math.round((Mount?Pvente2[7].y: Pvente[7].y)-(Mount2?ChargesF2[7].y:ChargesF[7].y)).toLocaleString()}€</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='paragraphe'>Ces calculs sont réaslisés pour des charges représentant <span style={{fontWeight:'bold'}}> {ChargesCoef*100} %</span> du produit brut au mois 0. Ces charges calculées sans charges sociales, sans charges financières, sans assurances et sans amortissement.</div>
            </div>
          </div>
        </div>
      <ButtonQuestions/>
      <OADMenu idExploit={idExploit}/>
    </div>
  );
}

export default RisquesPrix;
