import React, {useState, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import rond from '../../assets/logo_rond2.png'
import rond2 from '../../assets/logo_rond.png'
import { useReactToPrint } from 'react-to-print'
import {Navigate} from 'react-router-dom'

import PRINT from '../../assets/imprimante.png'
import Entete from '../MEP/entete'
import PageDeGarde from './1_page_de_garde';
import PageHow from './2_how';
import PageProduction from './3_production';
import PageRiskRDT from './4_risk_rdt';
import PageRiskRDTCarte from './5_risk_rdt_carte';
import PageRiskPrix from './6_risk_prix';
import PageResistance1 from './7_resistance1';
import PageRistance2 from './8_resistance2';
import PageOpti from './9_opti';
import Couv4eme from './10_4eme_couv';

function RapportPDF() {
    const { state } = useLocation();
    const { idExploit } = state;

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'rapport_DiagoRisk',
    })
    
    return (
        <>
        <button className='buttonPrint' onClick={handlePrint} > <img src={PRINT} className="IconHome" alt=''/></button>
        <div ref={componentRef}>
            <Entete titre="Prévisualisation du rapport" rapport={true}/>

            <div className="container containerButtonPrint" style={{marginBottom:'0px'}}>
                <PageDeGarde/>
                <PageProduction idExploit={idExploit}/>
                <PageRiskRDT idExploit={idExploit}/>
                <PageRiskRDTCarte idExploit={idExploit}/>
                {/* <PageRiskPrix idExploit={idExploit}/> */}
                <PageResistance1 idExploit={idExploit}/>
                <PageRistance2 idExploit={idExploit}/>
                <PageOpti idExploit={idExploit}/>
                <PageHow/>
                <Couv4eme/>
            </div>
        </div> 
        <button className='validation' onClick={handlePrint} style={{marginTop:'10px',marginLeft:'1%', width:'98%', marginBottom:"10px"}}> Imprimer ce rapport </button>
        </>
    )
}

export default RapportPDF