import React from "react";
import Supprimer from "../../../../../assets/Gestion_utilisateur_supprimer.png"

class BtnSupprimer extends React.Component {
    constructor(props) {
      super(props);
      this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
     this.props.clicked(this.props.value);
    }
    render() {
      return (
        <button className='ButtonGestionUtilisateur BGURed' onClick={this.btnClickedHandler}> <img src={Supprimer} className="IconHome" alt='' style={{marginLeft:'-10px'}}/> </button>
      )
    }
  }
export default BtnSupprimer