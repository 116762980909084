import React from 'react'
import {
    XYPlot,
    VerticalGridLines,
    HorizontalBarSeries,
    LabelSeries,
    makeVisFlexible,
  } from "react-vis";

function GrapheIR2({dataIR2_actuel, dataIR2_nouveau}) {
    const labelDataActuel = dataIR2_actuel.map((d) => ({
        x: d.x ,
        y: d.y
    }));
    const labelDataNouveau = dataIR2_nouveau.map((d) => ({
        x: d.x+1 ,
        y: d.y
    }));
    const FlexXYPlot=makeVisFlexible(XYPlot)

    return (
        <div >
            <FlexXYPlot   height={350}  colorType="literal" >
                <VerticalGridLines  style={{stroke: '#C6C6C6'}} />
                <HorizontalBarSeries style={{stroke: '#20BF3B', strokeWidth:3}}  data={dataIR2_actuel} barWidth={0.95} />
                <HorizontalBarSeries style={{stroke: 'black', strokeWidth:3, strokeDasharray:"8, 4"}}  data={dataIR2_nouveau} barWidth={0.95} />
                <LabelSeries data={labelDataActuel} getLabel={d => Math.abs(d.x).toLocaleString() + ' €'} style={{fill:'#20BF3B', fontWeight:'bold'}}/>
                <LabelSeries data={labelDataNouveau} getLabel={d => Math.abs(d.x).toLocaleString() + ' €'} style={{fill:'black', fontWeight:'bold'}}/>
            </FlexXYPlot>
        </div>
    )
}

export default GrapheIR2