import React from 'react'
import GraphOptiFinanciere from '../../../OAD/Graphiques/Graphe_opti_financiere'

function BlocCompOpti({data, minX, minY, maxX, maxY, PBTot}) {
  return (
    <div>
      <div className='titreMoins row' style={{marginTop:'0px', marginBottom:'0px'}}> Ratio coût-sécurité (en % de produit brut)  </div>
      <div className='paragraphe row' style={{marginTop:'5px', marginBottom:'0px'}}> Produit brut de référence : {PBTot.toLocaleString()}€ / Risque moyen : {data[0][0][1]} % </div> 
      <GraphOptiFinanciere data={data} print={true} minX={minX} minY={minY} maxX={maxX} maxY={maxY} cout={'CN'}/>
    </div>
  )
}

export default BlocCompOpti