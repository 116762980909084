import React, {useEffect} from 'react';

import {useLocation} from 'react-router-dom'

import ButtonQuestions from '../../MEP/button question';
import Entete from '../../MEP/entete'
import oeilShow from '../../../assets/oeilShow.png'
import oeilHide from '../../../assets/oeilHide.png'
import Consentement from './consentement';

function Inscription() {
    const { state } = useLocation();
    const { offre } = state
    const role = JSON.parse(localStorage.getItem('role')); 

    const [offreInscription, setOffreInscription]= React.useState(offre);
    const [tarif, setTarif]= React.useState();
    const [nom, setnom]= React.useState('');
    const [prenom, setprenom]= React.useState('');
    const [societe, setsociete]= React.useState('');
    const [tel, settel]= React.useState('');
    const [email, setemail]= React.useState('');
    const [MDP, setMDP]= React.useState('');
    const [MDP2, setMDP2]= React.useState('');
    const [CA, setCA]= React.useState(role==="ROLE_ADMINISTRATEUR" ? "RA23" : '');
    const [Show, setShow]= React.useState(false);
    const [admin, setAdmin]= React.useState(role==="ROLE_ADMINISTRATEUR" ? true : false);
    const [PopUpConsent, setPopUpConsent] = React.useState(false);

    useEffect ( () => {
        if (offre==="Flash"){
            setTarif(150)
        }
        else if (offre==="Essentielle"){
            setTarif(250)
        }
        else {
            setTarif(450)
        }
    
      }, [offre])

    function handleSubmit(event) {
        event.preventDefault()
        if (MDP===MDP2  && (MDP!=="" || MDP2!=="") && document.getElementById("autorisation").checked===true){
            if(CA==="RA23") {
                setAdmin(true)
            }
            else{
               setAdmin(false) 
            }
            
            setPopUpConsent(true)
        }
        else if (MDP!==MDP2  || MDP==="" || MDP2==="") {
            alert('Mot de passe incorrect')
        }
        else {
            alert("Veuillez autoriser l'utilisation de votre numéro de téléphone")
        }
    }


    function handleChangenom(event) {
        var nom = event.target.value
        setnom(nom)   
    }

    function handleChangeprenom(event) {
        var prenom = event.target.value
        setprenom(prenom)   
    }

    function handleChangesociete(event) {
        var societe = event.target.value
        setsociete(societe)   
    }

    function handleChangetel(event) {
        var tel = event.target.value
        settel(tel)   
    }

    function handleChangeEMail(event) {
        var email = event.target.value
        setemail(email)   
    }

    function handleChangeMDP(event) {
        var MDP = event.target.value
        setMDP(MDP)   
    }
    function handleChangeMDP2(event) {
        var MDP = event.target.value
        setMDP2(MDP)   
    }

    function handleChangeCA(event) {
        var CA = event.target.value
        setCA(CA)   
        if(CA==="RA23"){
            setAdmin(true)
        }
        else{
            setAdmin(false)
        }
    }

    function ShowHide () {
        setShow(!Show)
    }

    function handleChangeOffre(event) {
        var offre = event.target.value
        setOffreInscription(offre)
        if (offre==="Flash"){
            setTarif(150)
        }
        else if (offre==="Essentielle"){
            setTarif(250)
        }
        else {
            setTarif(450)
        }
    }


    return (
        <div>
            <Entete titre="Création de mon compte"/> {console.log(admin)} {console.log(CA)}
            <div className='container'>
                <form onSubmit={handleSubmit} name="inscription">
                    <div className='titre'>Offre: </div>
                    <select value={offreInscription} onChange={handleChangeOffre}  style={{width:'25%', marginBottom:'10px'}}>
                    <option value="Flash">Flash</option>
                    <option value="Essentielle">Essentielle</option>
                    <option value="Premium">Premium</option>
                    </select>
                    <div className='principal principalBis'>
                        <div className='bottom bottom2' style={{marginTop:'0px'}}> 
                            <div className='titre' style={{marginTop:"0px"}}> Informations personnelles  <span style={{color:'red', fontSize:'smaller', fontWeight:'normal'}}>(* Obligatoire)</span> : </div>
                            <div className='titreMoins'> Nom : <span style={{color:'red'}}>*</span></div>
                            <input 
                            className='input'
                            value={nom}
                            onChange={handleChangenom}
                            placeholder='Nom'
                            type='text'
                            style={{marginBottom:'10px', }}
                            required/>
                            <div className='titreMoins'> Prénom : <span style={{color:'red'}}>*</span> </div>
                            <input 
                            className='input'
                            value={prenom}
                            onChange={handleChangeprenom}
                            placeholder='Prénom'
                            type='text'
                            style={{marginBottom:'10px', }}
                            required/>
                            <div className='titreMoins'> Société : </div>
                            <input 
                            className='input'
                            value={societe}
                            onChange={handleChangesociete}
                            placeholder='Société'
                            type='tel'
                            style={{marginBottom:'10px', }}/>
                            <div className='titreMoins'> Téléphone : <span style={{color:'red'}}>*</span> </div>
                            <input 
                            className='input'
                            value={tel}
                            onChange={handleChangetel}
                            placeholder='Téléphone'
                            type='tel'
                            pattern="[0]{1}[6-7]{1}[0-9]{8}"
                            style={{marginBottom:'10px', }}
                            required/>
                            <div className='textInscription' style={{color:'black'}}>Format : 06XXXXXXXX ou 07XXXXXXXX</div>
                        </div>
                        <div className='bottom bottom2' style={{marginTop:'0px'}}>
                            <div className='titre' style={{marginTop:"0px"}} > Informations de connexion </div>
                            <div className='titreMoins'> Email : <span style={{color:'red'}}>*</span> </div>
                            <input 
                            className='input'
                            value={email}
                            onChange={handleChangeEMail}
                            placeholder='Email'
                            type='email'
                            style={{marginBottom:'10px', }}
                            required/>
                            <div className='titreMoins' > Mot de passe : <span style={{color:'red'}}>*</span> </div>
                            <div className='PasseWord'>
                                <input 
                                className='input'
                                value={MDP}
                                onChange={handleChangeMDP}
                                placeholder='Mot de passe'
                                type={Show? 'text' : 'password'}
                                style={{marginBottom:'10px', }}
                                required/>
                                <button className='ButtonShowHide' onClick={ShowHide}> <img src={Show? oeilShow : oeilHide} className="IconHome" alt=''/> </button>
                            </div>
                            <div className='titreMoins' > Confirmation du mot de passe : <span style={{color:'red'}}>*</span></div>
                            <div className='PasseWord'>
                                <input 
                                className='input'
                                value={MDP2}
                                onChange={handleChangeMDP2}
                                placeholder='Mot de passe'
                                type={Show? 'text' : 'password'}
                                style={{marginBottom:'10px', }}
                                required/>
                                <button className='ButtonShowHide' onClick={ShowHide}> <img src={Show? oeilShow : oeilHide} className="IconHome" alt=''/> </button>
                            </div>
                            <div className='textInscription' style={{color:MDP===MDP2  && (MDP!=="" || MDP2!=="")? 'green' :'red'}}>{MDP===MDP2 && (MDP!=="" || MDP2!=="")? 'Mot de passe confirmé' : 'Mot de passe incorrect et/ou différent'}</div>
                            <div className='titreMoins'> Code promo : </div>
                            <input 
                            className='input'
                            value={CA}
                            onChange={handleChangeCA}
                            placeholder='Code'
                            type='text'
                            style={{marginBottom:'10px', }} />
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <input type="checkbox" id="autorisation" name='autorisation' value="ok" className='checkInscription'  />
                        <div className='textInscription' style={{marginTop:'10px'}}> J'autorise DiagoRisk à utiliser mon numéro de téléphone pour gérer mon compte</div>
                    </div>
                    <div className='row'>
                       <button type='submit' className='validation' > Créer mon compte  </button> 
                    </div>
                </form>
                <ButtonQuestions/>
                <Consentement trigger={PopUpConsent} setTrigger={setPopUpConsent} Nom={nom} Prenom={prenom} Societe={societe} Tel={tel} Email={email} MDP={MDP} CA={CA} telConsent={document.getElementById("autorisation")} tarif={tarif} offre={offreInscription} admin={admin}/>
            </div>
        </div>
    );
}

export default Inscription;