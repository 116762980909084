import React, {useEffect, useState} from 'react';
import apiLink from '../../../../config.js'
import {useLocation, Navigate} from 'react-router-dom'
import axios from "axios";

import Entete from '../../../MEP/entete';

function ModifierAgent() {
    const [data, setData]= React.useState({'nom': '', 'prenom': '', 'societe': '', 'telephone': ''});
    const [goToUtilisateur, setGoToUtilisateur] = React.useState(false)
    const mesDonnees = new FormData(); 
    const { state } = useLocation();
    const { idAgentAModifier } = state;

    useEffect(() => {

    fetch(apiLink + 'getUtilisateur/' + idAgentAModifier)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
        setData(response)
        setData(prev => {
            return prev;
        })
    })
        
    }, [idAgentAModifier]);
    
    function handleSubmit(event) {
        event.preventDefault()
        var nom = data['nom']
        var prenom = data['prenom']
        var telephone = data['telephone']
        var societe= data['societe']
        mesDonnees.set("nom", nom)
        mesDonnees.set("prenom", prenom)
        mesDonnees.set("telephone", telephone)
        mesDonnees.set("societe", societe)
        
        axios({
            method: 'post',
            url: apiLink + 'updateUtilisateur/' + idAgentAModifier, //changer
            data: mesDonnees
        })
        .then(function (reponse) {
            if(reponse.status === 200){
            }
            else {
            alert("Une erreur c'est produite, vos modifications n'ont pas pu être enregistrées")
            }
        })
        .catch(function (erreur) {
            //On traite ici les erreurs éventuellement survenues
            console.log(erreur);
        });
        setGoToUtilisateur(true)
    }

    if(goToUtilisateur){
        return <Navigate push to={`/gestionutilisateur`} />
    }


    function handleChangenom(event) {
    var newData = {...data};
    var nom = event.currentTarget.value;
    newData['nom'] = nom//execute the manipulations
    setData(newData)
    setData(prev => {
        return prev;
    })    
    }
    function handleChangeprenom(event) {
    var newData = {...data};
    var prenom = event.currentTarget.value;
    newData['prenom'] = prenom//execute the manipulations
    setData(newData)
    setData(prev => {
        return prev;
    })  
    }
    function handleChangesociete(event) {
    var newData = {...data};
    var societe = event.currentTarget.value;
    newData['societe'] = societe//execute the manipulations
    setData(newData)
    setData(prev => {
        return prev;
    })  
    }
    function handleChangetel(event) {
    var newData = {...data};
    var tel = event.currentTarget.value;
    newData['telephone'] = tel//execute the manipulations
    setData(newData)
    setData(prev => {
        return prev;
    })    
    }
    return (
        <div>
            <Entete titre="Modifier les informations de l'agent"/>
            <div className='container'> 
                <form onSubmit={handleSubmit} name="inscription" style={{width:'100%'}}>
                <div className='titreMoins'> Nom : <span style={{color:'red'}}>*</span> </div>
                <input 
                className='input'
                value={data['nom']}
                onChange={handleChangenom}
                placeholder='Nom'
                type='text'
                style={{marginBottom:'10px', }}
                required/>
                <div className='titreMoins'> Prénom :  <span style={{color:'red'}}>*</span> </div>
                <input 
                className='input'
                value={data['prenom']}
                onChange={handleChangeprenom}
                placeholder='Prénom'
                type='text'
                style={{marginBottom:'10px', }}
                required/>
                <div className='titreMoins'> Société : </div>
                <input 
                className='input'
                value={data['societe']}
                onChange={handleChangesociete}
                placeholder='Société'
                type='tel'
                style={{marginBottom:'10px', }}/>
                <div className='titreMoins'> Téléphone : <span style={{color:'red'}}>*</span> </div> 
                <input 
                className='input'
                value={data['telephone']}
                onChange={handleChangetel}
                placeholder='Téléphone'
                type='tel'
                pattern="[0]{1}[6-7]{1}[0-9]{8}"
                style={{marginBottom:'10px', }}
                required/>
                <div className='textInscription' style={{color:'black'}}>Format : 06XXXXXXXX ou 07XXXXXXXX</div>
                <div className='row'>
                    <button type='submit' className='validation'> Enregistrer </button>
                </div>
                </form>
            </div>
        </div>
    )
}

export default ModifierAgent