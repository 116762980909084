import React, {useEffect}  from 'react';
import Nav from '../MEP/SWNav';
import {Navigate} from 'react-router-dom'
import EnteteSW from '../MEP/enteteSW';
import FooterSW from '../MEP/footer_SW';

import logo from '../../assets/DiagoRisk-logoRVB.png'
import mans from '../../assets/le_mans.png'
import RiAg from '../../assets/risques-agricoles.png'
import devices from '../../assets/devices.jpg'
import rdt_ipad from '../../assets/solutions_rdt_ipad.png'
import graphe from '../../assets/solutions_graphe.png'
import chimie from '../../assets/solutions_chimie.png'
import ampoule from '../../assets/solutions_ampoules.png'
function Solutions() {
  const [goToContact, setGoToContact] = React.useState(false)
  function handleContacter(event){
    event.preventDefault()
    setGoToContact(true)
  }
  if(goToContact) {
    return <Navigate push to={`/nouscontacter`} />
  }
  return (
    <div>
      <Nav/>
      <EnteteSW titre="Solutions"/>
      <div className="containerSW" >
        <div className='row'>
          <img src={logo} alt="logo_diagorisk" className="App-logo_solution"/>
        </div>
        <div className='row titre' style={{textAlign:'center'}}>
          Une autre manière de gérer vos risques climatiques
        </div>

        <div className='row rowSolutions' style={{marginTop:'30px'}}>
          <img src={devices} alt="devices" className="firstLigneSolG"/>
          <div className='firstLigneSolD'>
            <div  style={{marginBottom:'20px'}}> DiagoRisk est un outil d’aide à la décision vous donnant les clefs pour gérer au mieux vos pertes potentiel en cas d’aléas climatiques. </div>
            <div >Il vous suffit de saisir votre localisation et votre assolement, et DiagoRisk vous fournit des résultats, simple à appréhender.</div>
          </div>
        </div>

        <div className='row rowSolutions' style={{marginTop:'50px'}}>
          <div className='column_sol'>
            <img src={graphe} alt="devices" className="Sol_icon"/>
            <div className='titre'> Evaluer </div>
            <div className='Sol_p'> DiagoRisk vous permet d’évaluer votre perte potentiel en cas d’aléa climatique </div>
          </div>
          <div className='column_sol'>
            <img src={chimie} alt="devices" className="Sol_icon"/>
            <div className='titre'> Tester </div>
            <div className='Sol_p'> Le moteur de calcul de DiagoRisk vous permet de tester les différentes solution d’outils de gestion de risque sur votre perte potentiel </div>
          </div>
          <div className='column_sol'>
            <img src={ampoule} alt="devices" className="Sol_icon"/>
            <div className='titre'> Optimiser </div>
            <div className='Sol_p'> DiagoRisk vous permet d’optimiser votre assurance MRC en respectant les nouvelles mesures gouvernementales </div>
          </div>
        </div>

        <div className='row rowSolutions'>
          <div className='ligneLogoSol'>
            <div className='Sol_p'>
            DiagoRisk vous garantis : 
              <ul>
                <li> <div className='lila'> Une utilisation simple </div> </li>
                <li> <div className='lila'> Des résultats explicites et facilement compréhensible </div> </li>
                <li> <div className='lila'> La possibilité de se comparer à l’exploitation types de votre petite région agricole</div> </li>
             </ul>
            </div>
            <div className='row rowSolutions ligneLogoSol'>
              <img src={mans} alt="devices" className="Sol_logoS"/>
              <img src={logo} alt="devices" className="Sol_logoS"/>
            </div>
          </div>
          <img src={rdt_ipad} alt="devices" className="devices_rdt"/>
        </div>  

        <div className='row' >
          <div className='card cardAbo cardAboOF'>
            <div className='titrePlus'  style={{marginTop:'0px', marginBottom:'10px'}}>
              OF
            </div>
            <div className='paragraphe paraCardAbo'> Vous souhaitez proposer des formations en présentiel ou distanciel, éligibles VIVEA ?</div>
            <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#4B4BB5'}} onClick={handleContacter}>Nous contacter</button></div>
          </div>
          <div className='card cardAbo cardAboAssureur'>
            <div className='titrePlus'  style={{marginTop:'0px', marginBottom:'10px'}}>
              Assureur
            </div>
            <div className='paragraphe paraCardAbo'>Vous souhaitez comparer votre portefeuille à notre modèle actuariel ?</div>
            <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#37376A'}} onClick={handleContacter}>Nous contacter</button></div>
          </div>
          <div className='card cardAbo cardAboOPA'>
            <div className='titrePlus'  style={{marginTop:'0px', marginBottom:'10px'}}>
              OPA
            </div>
            <div className='paragraphe paraCardAbo'>Vous souhaitez mieux évaluer vos risques climatiques aujourd'hui et en 2050 ? Proposer des stratégies d’adaptation ?</div>
            <div className='row' style={{marginTop:'30px'}}><button className='validation validationAbo' style={{backgroundColor:'#24243E'}} onClick={handleContacter}>Nous contacter</button></div>
          </div>
        </div>     

      </div>
      <FooterSW/>
    </div>
  );
}

export default Solutions;

