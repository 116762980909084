import React from 'react';

function BlogContainer({titre, texte1, texte2, texte3, image, date}) {
  return (
      <div className="blogContainer">
        <div className='dateBlog'> 
            {date}
        </div>
        <div className='titreBlog'>
            {titre}
        </div>
        <div className='rowBlog'>
            <div className='texteGauche'>
                <div className='paragraphe Pgauche'> 
                    {texte1}
                </div>
                <div className='paragraphe Pgauche'> 
                    {texte2}
                </div>
                <div className='paragraphe Pgauche'> 
                    {texte3}
                </div>
            </div>
            <div className='imageDroite'>
                <img src={image} alt="" className="imageBlog"/>
            </div>
        </div>
      </div>
  );
}

export default BlogContainer;