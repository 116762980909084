import React from 'react';
import Nav from '../MEP/SWNav';
import EnteteSW from '../MEP/enteteSW';
import RiAg from '../../assets/risques-agricoles.png'
import Mains from '../../assets/equipe-diagorisk.jpg'
import FooterSW from '../MEP/footer_SW';

function QuiSommesNous() {
  return (
    <div>
      <Nav/>
      <EnteteSW titre="Qui sommes-nous ?"/>
      <div className="containerSW" >
        <div className='row'><img src={RiAg} alt="" className="RiAg"/></div>
        <div className='paragraphe'>DiagoRisk est développé par RisquesAgricoles SAS, une start-up innovante fondée par 5 associés expérimentés (agronome, actuaires, informaticiens). Nous associons une modélisation actuarielle exploitant plus de 20 ans de données, une connaissance métiers approfondie et la capacité à rendre ces résultats facilement accessible grâce au numérique.</div>
        <div className='principal' style={{marginTop:'20px'}}>
          <div className='top topBis'> 
            <img src={Mains} alt="" className="Mains"/>
          </div>
          <div className='bottom bottom2' style={{marginTop:'0px'}}>
            <div className='titre'>Equipe :</div>
            <div className='titreMoins lighter'>Luc BOUCHER</div>
            <div className='titreMoinsMoins'>Directeur</div>
            <div className='QSM_p'>Ingénieur agronome (Rennes 88), ing. Civil du Gref (AgroParisTech 1990-92) spécialisé en gestion des risques en agriculture</div>
            
            <div className='titreMoins lighter'>Martial PHELIPPE-GUINVARC’H</div>
            <div className='titreMoinsMoins'>Actuaire Sénior, conception modèle</div>
            <div className='QSM_p'>Maitre de Conférences à l’Institut du Risque et de l’Assurance, Le Mans Université.</div>
            
            <div className='titreMoins lighter'>Rémi PIGUET-LACROIX</div>
            <div className='titreMoinsMoins'>Actuaire Sénior</div>
            
            <div className='titreMoins lighter'>Charly BOURGEOIS</div>
            <div className='titreMoinsMoins'>Ingénieur informatique, développeur Back-End</div>
            <div className='QSM_p'> Ingénieur INSA </div>
            
            <div className='titreMoins lighter'>Aliénor MEYER-MASSONNET</div>
            <div className='titreMoinsMoins'>Cheffe de produit, développeuse Front-End</div>
            <div className='QSM_p'>Ingénieur agronome spécialité AgroTIC : Technologies de l’Information et de la Communication pour l’Agriculture et l’Environnement (Bordeaux Sciences Agro)</div>
          
            <div className='titreMoins lighter'>Pablo Yepes Llano</div>
            <div className='titreMoinsMoins'>Data Scientist</div>
            <div className='QSM_p'>Economiste, spécaliste en risques et assurances</div>
          
          </div>
        </div>
      </div>
      <FooterSW/>
    </div>
  );
}

export default QuiSommesNous;

