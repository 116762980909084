import React from 'react'

function BlocCompRdt({Risques7, Risques20, Risques50}) {
  return (
    <div className=''>
        <div className='column marge'>
            <div className='videBloc row videBloc7'>
            <div className='pleinBloc row pleinBloc7'>
                <div className='titreBloc'>Courant </div>
            </div>
            <div className='paragrapheBloc paragrapheBloc7'>{Math.round(Risques7*10)/10} % <span style={{fontWeight:'normal', fontSize:'70%'}}> du produit brut </span> </div>
            </div>

            <div className='videBloc row videBloc20'>
            <div className='pleinBloc row pleinBloc20'>
                <div className='titreBloc'>Exceptionnel </div>
            </div>
            <div className='paragrapheBloc paragrapheBloc20'>{Math.round(Risques20*10)/10} % <span style={{fontWeight:'normal', fontSize:'70%'}}> du produit brut </span> </div>
            </div>

            <div className='videBloc row videBloc50'>
            <div className='pleinBloc row pleinBloc50'>
                <div className='titreBloc'>Catastrophique </div>
            </div>
            <div className='paragrapheBloc paragrapheBloc50'> {Math.round(Risques50*10)/10} % <span style={{fontWeight:'normal', fontSize:'70%'}}> du produit brut </span> </div>
            </div>
        </div>
    </div>
  )
}

export default BlocCompRdt