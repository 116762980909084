import React, {useState} from 'react'
import apiLink from '../../../config.js'
import {Navigate} from 'react-router-dom'


function PopUpSupp(props) {
    const idAgent = JSON.parse(localStorage.getItem('idAgent'));
    const [goToHome, setGoToHome] = useState(false)

    function handlSupp() {
        props.setTrigger(false)
        localStorage.clear();
        setGoToHome(true)
        fetch(apiLink + 'deleteUtilisateur/' + idAgent)
        .then( (response) => {
            return response.json()
        })
    }
    if(goToHome) {
        return <Navigate push to={`/`} />
    }

    return ( props.trigger) ? (
        <div className='popupLegend popupInsc'>
            <div className='popup-inner'>
                <button className='close-btn close-btn-profil' onClick={() => props.setTrigger(false)}>X</button>
                <div className='paragraphe'> Votre compte va être supprimé.  </div>
                <div className='paragraphe'> Vos données seront encore stockées et donc disponible pour vous pendant 30 jours à la suite de celà elles seront supprimées définitivement.  </div>
                <div className='row'>
                    <button className='validation' onClick={handlSupp} style={{backgroundColor:'#FF757F'}}> Supprimer mon compte </button>
                </div>
                {props.children}
            </div>
    
        </div>
      ) : "" ;
}

export default PopUpSupp