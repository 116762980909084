import React, {useState, useEffect} from 'react'
import apiLink from '../../../../config.js'
import Entete from '../../../MEP/entete'
import {useLocation} from 'react-router-dom'
import Profil from '../profil'
import Simul from '../../Simulations/simulation'

function SimuationsAgent() {
  const { state } = useLocation();
  const { idAgentAModifier } = state;

  const role = JSON.parse(localStorage.getItem('role'));

  const [data, setData] = useState({"3603": {"idExploitation": "3603","nom": "Libelle", "date":{"date":"2023-01-17 16:24:24.000000","timezone_type":3,"timezone":"Europe\/Paris"}, "productions": ["Blé tendre"]}}) //eslint-disable-line


  
    useEffect(() => {
    fetch(apiLink + 'getExploitations/' + idAgentAModifier)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
      setData(response)
      setData(prev => {
          return prev;
      })
    })
      
    }, [idAgentAModifier]);
  
  const table_simul=  Object.keys(data).map(key => (
    <Simul 
    keys={key}
    data={data}
    idExploit={key}
    libelle={data[key]["nom"]}
    date={new Date(data[key]["date"]["date"]).toLocaleDateString("fr")}
    assol={data[key]["productions"].join(' / ')}
    admin={true}
    />
  ))

  
  return (
    <div>
      <Entete titre="Mes simulations"/>
      <div className="container" >
        {role==="ROLE_ADMINISTRATEUR" ? table_simul : <Profil />}
      </div>
    </div>
  )
}

export default SimuationsAgent