import React from 'react';
import apiLink from '../../../config.js'
import axios from "axios";
import {Navigate} from 'react-router-dom'

import ButtonQuestions from '../../MEP/button question.js';
import Entete from '../../MEP/entete'
import logo from '../../../assets/DiagoRisk-logoRVB.png'
import oeilShow from '../../../assets/oeilShow.png'
import oeilHide from '../../../assets/oeilHide.png'

function Connexion() {
    const [identifiant, setIdentifiant]= React.useState('');
    const [MDP, setMDP]= React.useState('');
    const [Show, setShow]= React.useState(false);
    const [goToLocaInscriptionn, setGoToLocaInscriptionn] = React.useState(false)
    const [goToSimulation, setGoToSimulation] = React.useState(false)

    const mesDonnees = new FormData(); 


    function handleSubmit(event) {
        event.preventDefault()
        mesDonnees.set("password", MDP);

        axios({
            method: 'post',
            url: apiLink + 'login/' + identifiant,
            data: mesDonnees
        })
        .then(response =>{
            if(response.status === 200){
                if (response.data.status === 1){
                    localStorage.clear();
                    localStorage.setItem('idAgent', JSON.stringify(response.data.idAgent));
                    localStorage.setItem('connexion_timestamp', Date.now().toString());
                    localStorage.setItem('idExploitationPrinicpale', JSON.stringify(response.data.idExploitationPrincipale));
                    localStorage.setItem('role', JSON.stringify(response.data.role));
                    localStorage.setItem('formule', JSON.stringify(response.data.formule));
                    if (response.data.idExploitationPrincipale !== null){
                        setGoToSimulation(true)
                    }
                    else {
                        setGoToLocaInscriptionn(true)
                    } 
                }
                else if (response.data.status === -1){
                    alert("E-mail incorrect")
                }
                else if (response.data.status === -2){
                    alert("Mot de passe incorrect")
                }
                else if (response.data.status === -3){
                    alert("Vous n'avez pas effectué le paiement de votre offre. Veuillez nous contacter pour retenter le paiement")
                }
                else {
                    alert("Votre abonnement à DiagoRisk a expiré, veuillez renouveller votre abonnement")
                }
            }
            else {
                alert("Une erreur c'est produite, vous n'avez pas pu vous connecter")
            }
        })
        .catch(function (erreur) {
            //On traite ici les erreurs éventuellement survenues
            console.log(erreur);
        });
    }

    if(goToLocaInscriptionn) {
        return <Navigate push to={`/localisation`}/>
    }
    if(goToSimulation) {
        return <Navigate push to={`/simulations`}/>
    }

    function handleChangeId(event) {
        var identifiant = event.target.value
        setIdentifiant(identifiant)   
    }

    function handleChangeMDP(event) {
        var MDP = event.target.value
        setMDP(MDP)   
    }

    function ShowHide () {
        setShow(!Show)
    }
    return (
        <div>
        <Entete titre="Connexion"/>
        <div className='container'>
            <div className="principal principal2" >
                <div className='top'>
                    <div className='top1'>
                    <img src={logo} alt="logo_diagorisk" className="App-logo App-logo2"/>
                    </div>
                </div>

                <div className="bottom bottom2">
                    <form onSubmit={handleSubmit} style={{width:'100%'}}>
                        <div className='titre'>  Identifiant : </div>
                        <input 
                        className='input'
                        value={identifiant}
                        onChange={handleChangeId}
                        placeholder='Identifiant'
                        type='text'
                        required/>
                        <div className='titre' style={{marginBottom:'10'}}> Mot de passe : </div>
                        <div className='row PasseWord'>
                            <input 
                            className='input'
                            value={MDP}
                            onChange={handleChangeMDP}
                            placeholder='Mot de passe'
                            type={Show? 'text' : 'password'}
                            style={{marginBottom:'10px', }}
                            required/>
                            

                        </div>
                        <div className='row'>
                            <button type='submit' className='validation'  style={{marginTop:'40px', marginLeft:'0px'}} > Connexion </button>
                        </div>
                    </form>
                    <button className='ButtonShowHide ButtonShowHideConnexion' onClick={ShowHide}> <img src={Show? oeilShow : oeilHide} className="IconHome" alt=''/> </button>
                </div>
            </div>
        </div>
        <ButtonQuestions/>
        </div>
    );
}

export default Connexion;