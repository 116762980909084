import React from 'react'

function AssolRapport({key, libelle, surface, rendement, prix, pbCulture}) {
  return (
    <div className='row ligne_assol'>
      <div className='col_assol_rapport col_libelle_rapport'>
      {libelle}
      </div>
      <div className='col_assol_rapport col_surface_rapport'>
        <div className='videBlocAssol'>
            <div style={{width:'50%', paddingLeft:'10%'}}>{surface}</div>
            <div className='pleinBlocAssol'>
              ha
            </div>
        </div>
      </div>
      <div className='col_assol_rapport col_rdt_rapport'>
        <div className='videBlocAssol'>
          <div style={{width:'50%', paddingLeft:'10%'}}>{rendement}</div>
            <div className='pleinBlocAssol'>
              t/ha
            </div>
        </div>
      </div>
      <div className='col_assol_rapport col_prix_rapport'>
        <div className='videBlocAssol'>
          <div style={{width:'50%', paddingLeft:'10%'}}>{prix}</div>
          <div className='pleinBlocAssol'>
            €/t
          </div>
        </div>
      </div>
      <div className='col_assol_rapport col_PB_unitaire_rapport'>
          {pbCulture.toLocaleString()} €
      </div>
    </div>
  )
}

export default AssolRapport