import React from 'react'
import logo from '../../assets/DiagoRisk-logoRVB.png'
import img from '../../assets/countryside-2326787_1920_maj_091122.jpg'
import PiedRapport from './pied_rapport'

function PageDeGarde() {
  return (
    <div className='pageDeGarde'>
      <img src={logo} alt="logo_diagorisk" className="App-logo"/>

      <div className='row' style={{marginLeft:'150px'}}>
        <div>
          <div className='titrePDG_rapport' style={{marginTop:'200px',marginBottom:'200px'}}>Restitution de votre simulation</div>
        </div>
      </div>

      <div className='row' style={{justifyContent:'space-around'}}>
        <img src={img} alt="logo_diagorisk" className="img_rport_PDG"/>
        <div>
          <div className='row' style={{color:'#616161', fontWeight:'bold', fontSize:'30px', marginBottom:'20px'}}> Sommaire </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> p 1  </div>
            <div className='titre_sommaire_PDG'>Facteurs de production</div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> p 2 à 4 </div>
            <div className='titre_sommaire_PDG'>Vulnérabilités</div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> p 5 à 6 </div>
            <div className='titre_sommaire_PDG'>Résistance de votre exploitation</div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> p 7 </div>
            <div className='titre_sommaire_PDG'>Optimisation Sécurité - Coût</div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> p 8 </div>
            <div className='titre_sommaire_PDG'>Méthodologie</div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default PageDeGarde