import React, { useState} from 'react';
import apiLink from '../../config.js'
import {Navigate} from 'react-router-dom'
import Entete from '../MEP/entete'
import Pdf_CGU from '../../assets/PDF/2023-07-10_CGU.pdf'

function LocalisationDec() {
  const [CP, setCP]= React.useState('');
  const [idExploit, setIdExploit]= React.useState(undefined);
  const [city, setCity]= React.useState("");
  const [ListVille, setList]= React.useState();
  const [Code, setCode]= React.useState('');
  const [goToExploit, setGoToExploit] = useState(false)
  const [GoToInscription,setGoToInscription] = React.useState(false)
  //const CValidation = {"  ": new Date("31/12/2023") ,"DR22": new Date("31/12/2023") , "TN2022": new Date("31/11/2022"), "DRDD22": new Date("31/12/2022"),"TJ2023": new Date("31/01/2023") }
  const timestamp = Date.now(); // This would be the timestamp you want to format

  const dateNow = new Intl.DateTimeFormat('en-GB').format(timestamp)

  function handleSubmit(event) {
    event.preventDefault()
    if(idExploit!==undefined && document.getElementById("ConsentementCGUInput").checked===true) {
      setGoToExploit(true)
    }
    else if (idExploit===undefined) {
      alert("Cette commune ne possède pas d'exploitation type")
    }
    else {
      alert("Veuillez accepter les conditions générales d'utilisation")
    }
    
  }
  function handleGoToInscription(){
    setGoToInscription(true)
  }

  function handleChange(event) {
    var CP = event.target.value
    setCP(CP)   
    fetch(apiLink + 'getCommunes/'+ CP)
      .then( (response) => {
        return response.json()
      })
      .then(response =>{
        setList(response.data)
        setList(prev => {
          return prev;
        })
      }) 
  }

  function handleChangeCode(event) {
    var Code = event.target.value
    setCode(Code)
  }

 function handleScrollSelect(event){
  var city = event.target.value
  setCity(city)   
  if (CP === "41310" || CP==="47300" ) {
    fetch(apiLink + 'getExploitationType/' + city )
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setIdExploit(response)
      setIdExploit(prev => {
        return prev.idExploitation;
      })
    }) 
  }
  else {
    fetch(apiLink + 'getExploitationType/' + city + "/2")
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setIdExploit(response)
      setIdExploit(prev => {
        return prev.idExploitation;
      })
    }) 
  }
 }
 if(goToExploit) {
    return <Navigate push to={`/exploitation`} state={{idExploit}}/>
  }
  if(GoToInscription) {
    return <Navigate push to={`/inscription`} state={{offre:"Essentielle"}}/>
  }

  return (
    <div>
      <Entete titre="Localisation"/>
      <div className='container'>
        <div className='paragraphe'>  Découvrez les fonctionnalités de DiagoRisk grâce ces exploitations types. </div>
        <div className='row' style={{justifyContent:'start'}}> 
          <div className='paragraphe'>  Pour personnaliser votre assolement  </div>
          <button className='paragraphe' onClick={handleGoToInscription} style={{width:'20%', color:'#92D050', backgroundColor:'transparent', border:'none', textAlign:'start'}}> Créer votre compte  </button>
        </div>
        <form onSubmit={handleSubmit}>
        {/* <div className='titre'> Code d'activation de la découverte </div>
          <input 
          className='input'
          value={Code}
          onChange={handleChangeCode}
          placeholder='Code'
          type='password'
          required/> */}
          <div className='titre'> Sélectionnez un code postal </div>
          <select value={CP} onChange={handleChange} required>
            <option value="">Choisissez</option>
            <option value="41310">41310 - Grandes Cultures</option>
            <option value="47300">47300 - Grandes Cultures</option>
            <option value="44230">44230 - Viticulture</option>
            <option value="38250">38250 - Viticulture</option>
            <option value="32000">32000 - Mixte</option>
            <option value="81440">81440 - Mixte</option>
            {/* 
            <option value="">Choisissez</option>
            <option value="41310">41310</option>
            <option value="47300">47300</option>
            <option value="57590">57590</option>
            <option value="62130">62130</option>
            <option value="72380">72380</option>
            <option value="72380">72380</option> 
            
            */}
          </select>
          {/* <input 
          className='input'
          maxLength="5"
          value={CP}
          onChange={handleChange}
          placeholder='Code Postal'
          type='text'
          required/> */}
          <div className='titre'> Sélectionnez votre ville</div>
          <select value={city} onChange={handleScrollSelect} required>
            <option value="">Choisissez</option>
            { Array.isArray(ListVille) ? ListVille.map((item)=>( <option key={item.key} value={item.key} > {item.libelle} </option> ) ) : ListVille===undefined ?  <option value={'pas de ville'}>  </option>  : <option value={ListVille.key}> {ListVille.libelle} </option> }
          </select>

          <div className='paragraphe'>Vous devez les lire les <a href = {Pdf_CGU} className='paragraphe' style={{color:'#92D050', fontWeight:'bold'}}target='_blank'>conditions génrales d'utilisation</a></div>
          <div style={{display:'flex'}}>
              <input type="checkbox" id="ConsentementCGUInput" value="ok" className='checkInscription' style={{marginBottom:'0px'}} required />
              <div className='textInscription' style={{marginTop:'10px'}}> J'ai lu et j'accepte les conditions générales d'utilisation </div>
          </div>

          <div className='row'>
            <button type='submit' className='validation'> Valider </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LocalisationDec;